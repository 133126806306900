import {
  Menu,
} from "antd";

import Styled from 'styled-components';

export const NewsWrapScroll = Styled.div`
    max-height : 400px;
    overflow-y : auto;
    margin : 10px;
    border : 1px solid #f0f0f0;
    padding : 10px;
`;

export const CustomLink = Styled.a`
        text-decoration: none;
        color: inherit;

        &:hover {
            color: #096dd9;;
        }
`;

export const TableCustomMenu = Styled(Menu)`
  display: flex;
  flex-direction: column;
  background-color: #443A59 !important;
  padding: 0;

  .ant-dropdown-menu-item{
    background-color: transparent !important;
    padding: 0  !important;
  }

  .ant-dropdown-menu-item:focus {
    /*
    background: #A68DFB !important;
    */
    color: #fff !important;
  }

  .ant-menu-title-content, .ant-menu-submenu-arrow{
    color: #fff !important;

  }

  && ul.ant-menu {
    border-right: 0px !important;
  }

  button {
    background-color: transparent !important;
    border: none;
    color: #fff !important;
    width: 100%;        
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
  }

  button:focus, button:hover {
      /*background: #A68DFB !important;*/
      color: #fff !important;
  }

  button svg {
    margin-right: 10px;
  }

  .ant-menu-item-selected, .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    /*background: #A68DFB !important;*/
    background: #423a5a !important;
    color: #fff !important;
  }
`;
