import React, { useState } from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import { StyledButton, CommonModal } from "../../../shared/commonStyles";
import { useCheckRolesPermissions } from "../../../hooks/useCheckRolesPermissions";
import { Typography } from "antd";
import { useNavigate } from "react-router-dom";
const { Title } = Typography;

export const CreateDataCollection = () => {
  const { checkPermissions, checkRolesPermission } = useCheckRolesPermissions();
  const [showCreateDataCollectModal, setShowCreateDataCollectModal] =
    useState(false);
  const navigate = useNavigate();

  const onClose = () => {
    setShowCreateDataCollectModal(false);
  };

  return (
    <>
      {showCreateDataCollectModal && (
        <CommonModal
          width={500}
          title={"Add New Data Collection"}
          visible={showCreateDataCollectModal}
          footer={null}
          onCancel={() => setShowCreateDataCollectModal(false)}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Title level={3} style={{ textAlign: "center" }}>
              {" "}
              {`What data would you like to collect?`}{" "}
            </Title>
            <StyledButton
              type="default"
              style={{
                margin: 10,
                width: "100%",
                height: "75px",
                fontSize: "20px",
              }}
              onClick={() => {
                onClose();
                navigate("/management/collect-data/create-standards-survey");
              }}
            >
              Standards Only
            </StyledButton>
            <StyledButton
              type="default"
              style={{
                margin: 10,
                width: "100%",
                height: "75px",
                fontSize: "20px",
              }}
              onClick={() => {
                onClose();
                navigate("/management/collect-data/create-datasheet-survey");
              }}
            >
              Data Sheet Survey Only
            </StyledButton>
            <StyledButton
              type="default"
              style={{
                margin: 10,
                width: "100%",
                height: "75px",
                fontSize: "20px",
              }}
              onClick={() => {
                onClose();
                navigate("/management/collect-data/create-mixed-survey");
              }}
            >
              Standards and Data Sheet Survey
            </StyledButton>
            <StyledButton
              type="default"
              style={{
                margin: 10,
                width: "100%",
                height: "75px",
                fontSize: "20px",
              }}
              onClick={() => {
                onClose();
                navigate("/management/collect-data/create-kpi-survey");
              }}
            >
              KPIs Survey
            </StyledButton>
          </div>
        </CommonModal>
      )}
      {checkPermissions(["manage-collect-data"]) && !checkRolesPermission(["role:elevateduser"]) && (
        <StyledButton
          type="primary"
          onClick={() => setShowCreateDataCollectModal(true)}
          id="createNewDataCollectionBtn"
        >
          <PlusCircleOutlined /> <span>Create New Data Collection</span>
        </StyledButton>
      )}
    </>
  );
};
