import React, { useEffect, useState } from "react";
import { Tooltip } from "antd";
import moment from "moment";
import SearchableColumn from "../../../../../../components/SearchableColumn";
import { CustomLink } from "../styles";
import { YearMonthDayFormat } from "../../../../../../shared/constants";
import { CommonTable } from "../../../../../../shared/commonStyles";

import { getEmissionsUnit } from "../../../../../Carbon/CalculationUtils";

const OverviewDataTable = ({
  pivotDataList,
  loadingTableData,
  pagination,
  columnVisibility,
  onChangePagination,
  handle_pivot_table_row_click,
  companyDetails,
}) => {
  const [namesFilter, setNamesFilter] = useState([]);
  const [tableFilters, setTableFilters] = useState({
    filteredInfo: null,
    sortedInfo: null,
  });

  useEffect(() => {
  }, [pivotDataList]);

  const handleChange = (pagination, filters, sorter) => {
    /*
    setTableFilters({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
    onChangePagination(pagination);
    */
  };


  const columns = [
    columnVisibility.level && 
    {
      title: "",
      dataIndex: "level",
      width: "5%",
      /*
      render: (record) => {
        return record.scope_1_consumption_amount;
      },
      */
    },
    columnVisibility.row_labels && 
    {
      title: "Row Labels",
      dataIndex: "row_labels",
      width: "15%",
      /*
      render: (record) => {
        return record.scope_1_consumption_amount;
      },
      */
    },
    columnVisibility.sum_of_consumption && 
    {
      title: "Sum Of Consumption",
      dataIndex: "sum_of_consumption",
      width: "5%",
      /*
      render: (record) => {
        return record.scope_1_consumption_amount;
      },
      */
    },
    columnVisibility.sum_of_total_location && 
    {
      title: `Sum Of Total (All Scopes) Location (${getEmissionsUnit(companyDetails.emissions_unit)} CO2e)`,
      dataIndex: "sum_of_total_all_scopes_location",
      width: "10%",
      /*
      render: (record) => {
        return record.scope_1_consumption_amount;
      },
      */
    },
    columnVisibility.scope_1_location && 
    {
      title: `Total Sum - Scope 1 (${getEmissionsUnit(companyDetails.emissions_unit)} CO2e) Location`,
      dataIndex: "scope_1_emission_amount_location",
      width: "10%",
      /*
      render: (record) => {
        return record.scope_1_consumption_amount;
      },
      */
    },
    columnVisibility.scope_2_location && 
    {
      title: `Scope 2 (${getEmissionsUnit(companyDetails.emissions_unit)} CO2e) Location`,
      dataIndex: "scope_2_emission_amount_location",
      width: "10%",
      /*
      render: (record) => {
        return record.scope_1_consumption_amount;
      },
      */
    },
    columnVisibility.scope_3_location && 
    {
      title: `Scope 3 (${getEmissionsUnit(companyDetails.emissions_unit)} CO2e) Location`,
      dataIndex: "scope_3_emission_amount_location",
      width: "10%",
      /*
      render: (record) => {
        return record.scope_1_consumption_amount;
      },
      */
    },
    columnVisibility.sum_of_total_market && 
    {
      title: `Sum Of Total (All Scopes) Market (${getEmissionsUnit(companyDetails.emissions_unit)} CO2e)`,
      dataIndex: "sum_of_total_all_scopes_market",
      width: "10%",
      /*
      render: (record) => {
        return record.scope_1_consumption_amount;
      },
      */
    },
    columnVisibility.scope_1_market && 
    {
      title: `Scope 1 (${getEmissionsUnit(companyDetails.emissions_unit)} CO2e) Market`,
      dataIndex: "scope_1_emission_amount_market",
      width: "10%",
      /*
      render: (record) => {
        return record.scope_1_consumption_amount;
      },
      */
    },
    columnVisibility.scope_2_market && 
    {
      title: `Scope 2 (${getEmissionsUnit(companyDetails.emissions_unit)} CO2e) Market`,
      dataIndex: "scope_2_emission_amount_market",
      width: "10%",
      /*
      render: (record) => {
        return record.scope_1_consumption_amount;
      },
      */
    },
    columnVisibility.scope_3_market && 
    {
      title: `Scope 3 (${getEmissionsUnit(companyDetails.emissions_unit)} CO2e) Market` ,
      dataIndex: "scope_3_emission_amount_market",
      width: "10%",
      /*
      render: (record) => {
        return record.scope_1_consumption_amount;
      },
      */
    },
    columnVisibility.sum_of_total_uncategorized && 
    {
      title: `Sum of Total (All Scopes) Uncategorized (${getEmissionsUnit(companyDetails.emissions_unit)} CO2e)`,
      dataIndex: "sum_of_total_all_scopes_uncategorized",
      width: "10%",
      /*
      render: (record) => {
        return record.scope_1_consumption_amount;
      },
      */
    },
    columnVisibility.scope_1_uncategorized && 
    {
      title: `Scope 1 (${getEmissionsUnit(companyDetails.emissions_unit)} CO2e) Uncategorized`,
      dataIndex: "scope_1_emission_amount_uncategorized",
      width: "10%",
      /*
      render: (record) => {
        return record.scope_1_consumption_amount;
      },
      */
    },
    columnVisibility.scope_2_uncategorized && 
    {
      title: `Scope 2 (${getEmissionsUnit(companyDetails.emissions_unit)} CO2e) Uncategorized`,
      dataIndex: "scope_2_emission_amount_uncategorized",
      width: "10%",
      /*
      render: (record) => {
        return record.scope_1_consumption_amount;
      },
      */
    },
    columnVisibility.scope_3_uncategorized && 
    {
      title: `Scope 3 (${getEmissionsUnit(companyDetails.emissions_unit)} CO2e) Uncategorized` ,
      dataIndex: "scope_3_emission_amount_uncategorized",
      width: "10%",
      /*
      render: (record) => {
        return record.scope_1_consumption_amount;
      },
      */
    },
  ].filter(Boolean);

  return (
    <>
      <CommonTable
        loading={loadingTableData}
        onRow={(record) => {
          return {
            onClick: () => handle_pivot_table_row_click(record), // Click row
          };
        }}
        rowKey={(data) => {
          return data.id;
        }}
        dataSource={pivotDataList}
        columns={columns}
        scroll={{ x: true }}
        onChange={handleChange}
        pagination={false}
        scroll={{ y: 540 }} // Set scroll on the y-axis 
      />
    </>
  );
};

export default OverviewDataTable;
