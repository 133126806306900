/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const manageUserAccess = /* GraphQL */ `
  query ManageUserAccess($request_type: String!, $parameters: AWSJSON) {
    manageUserAccess(request_type: $request_type, parameters: $parameters)
  }
`;
export const sendTaskNotification = /* GraphQL */ `
  query SendTaskNotification($request_type: TaskRequestType!, $task_id: ID!) {
    sendTaskNotification(request_type: $request_type, task_id: $task_id)
  }
`;
export const sendTaskReminder = /* GraphQL */ `
  query SendTaskReminder(
    $request_type: TaskRequestType!
    $email_list: [AWSEmail!]
    $task_deployment_id: String
    $survey_id: String
    $category: String
    $status: TaskReminderStatus
    $priority: String
    $audit_type: AuditManagementType
    $audit_id: String
    $record_id: String
    $request_payload: AWSJSON
  ) {
    sendTaskReminder(
      request_type: $request_type
      email_list: $email_list
      task_deployment_id: $task_deployment_id
      survey_id: $survey_id
      category: $category
      status: $status
      priority: $priority
      audit_type: $audit_type
      audit_id: $audit_id
      record_id: $record_id
      request_payload: $request_payload
    )
  }
`;
export const getSurveyMetrics = /* GraphQL */ `
  query GetSurveyMetrics($request_type: TaskRequestType!) {
    getSurveyMetrics(request_type: $request_type)
  }
`;
export const getDeploymentMetrics = /* GraphQL */ `
  query GetDeploymentMetrics($request_type: TaskRequestType!, $survey_id: ID!) {
    getDeploymentMetrics(request_type: $request_type, survey_id: $survey_id)
  }
`;
export const dataLakeFiles = /* GraphQL */ `
  query DataLakeFiles(
    $request_type: DataLakeFilesTypes!
    $data_id: ID!
    $version: Int
    $file_name: String
  ) {
    dataLakeFiles(
      request_type: $request_type
      data_id: $data_id
      version: $version
      file_name: $file_name
    )
  }
`;
export const dataSheetFiles = /* GraphQL */ `
  query DataSheetFiles(
    $request_type: DataLakeFilesTypes!
    $data_id: String!
    $version: Int
    $file_name: String
    $request_payload: AWSJSON
  ) {
    dataSheetFiles(
      request_type: $request_type
      data_id: $data_id
      version: $version
      file_name: $file_name
      request_payload: $request_payload
    )
  }
`;
export const dataLakeAuditFiles = /* GraphQL */ `
  query DataLakeAuditFiles(
    $request_type: DataLakeFilesTypes!
    $data_id: ID!
    $version: Int
    $file_name: String
  ) {
    dataLakeAuditFiles(
      request_type: $request_type
      data_id: $data_id
      version: $version
      file_name: $file_name
    )
  }
`;
export const dataSheetAuditFiles = /* GraphQL */ `
  query DataSheetAuditFiles(
    $request_type: DataLakeFilesTypes!
    $data_id: String!
    $version: Int
    $file_name: String
  ) {
    dataSheetAuditFiles(
      request_type: $request_type
      data_id: $data_id
      version: $version
      file_name: $file_name
    )
  }
`;
export const emissionsFiles = /* GraphQL */ `
  query EmissionsFiles(
    $request_type: DataLakeFilesTypes!
    $data_id: String!
    $version: Int
    $file_name: String
  ) {
    emissionsFiles(
      request_type: $request_type
      data_id: $data_id
      version: $version
      file_name: $file_name
    )
  }
`;
export const dataSheetImport = /* GraphQL */ `
  query DataSheetImport(
    $request_type: DataLakeFilesTypes!
    $data_id: String!
    $version: Int
    $file_name: String
  ) {
    dataSheetImport(
      request_type: $request_type
      data_id: $data_id
      version: $version
      file_name: $file_name
    )
  }
`;
export const decarbonization = /* GraphQL */ `
  query Decarbonization($request_type: String!, $request_payload: AWSJSON) {
    decarbonization(
      request_type: $request_type
      request_payload: $request_payload
    )
  }
`;
export const getUtilityBills = /* GraphQL */ `
  query GetUtilityBills($request_type: String!, $request_payload: AWSJSON) {
    getUtilityBills(
      request_type: $request_type
      request_payload: $request_payload
    )
  }
`;
export const datalake = /* GraphQL */ `
  query Datalake($request_type: String!, $request_payload: AWSJSON) {
    datalake(request_type: $request_type, request_payload: $request_payload)
  }
`;
export const datalake_job = /* GraphQL */ `
  query Datalake_job($request_type: String!, $request_payload: AWSJSON) {
    datalake_job(request_type: $request_type, request_payload: $request_payload)
  }
`;
export const datalakeVarianceDetection = /* GraphQL */ `
  query DatalakeVarianceDetection(
    $request_type: String!
    $request_payload: AWSJSON
  ) {
    datalakeVarianceDetection(
      request_type: $request_type
      request_payload: $request_payload
    )
  }
`;
export const datalakeImportData = /* GraphQL */ `
  query DatalakeImportData(
    $request_type: String!
    $sheet_id: String
    $sheet_name: String
    $file_name: String
    $filter: String
    $import_id: String
    $import_status: String
    $metadata_record: AWSJSON
    $field_mapping: AWSJSON
    $version: Int
    $trip_details: AWSJSON
    $data_mapping: AWSJSON
    $data_survey_id: String
    $taskDeploymentId: String
    $request_payload: AWSJSON
  ) {
    datalakeImportData(
      request_type: $request_type
      sheet_id: $sheet_id
      sheet_name: $sheet_name
      file_name: $file_name
      filter: $filter
      import_id: $import_id
      import_status: $import_status
      metadata_record: $metadata_record
      field_mapping: $field_mapping
      version: $version
      trip_details: $trip_details
      data_mapping: $data_mapping
      data_survey_id: $data_survey_id
      taskDeploymentId: $taskDeploymentId
      request_payload: $request_payload
    )
  }
`;
export const datalakeImportData_job = /* GraphQL */ `
  query DatalakeImportData_job(
    $request_type: String!
    $sheet_id: String
    $sheet_name: String
    $file_name: String
    $filter: String
    $import_id: String
    $import_status: String
    $metadata_record: AWSJSON
    $field_mapping: AWSJSON
    $version: Int
    $trip_details: AWSJSON
    $data_mapping: AWSJSON
    $data_survey_id: String
    $taskDeploymentId: String
    $request_payload: AWSJSON
  ) {
    datalakeImportData_job(
      request_type: $request_type
      sheet_id: $sheet_id
      sheet_name: $sheet_name
      file_name: $file_name
      filter: $filter
      import_id: $import_id
      import_status: $import_status
      metadata_record: $metadata_record
      field_mapping: $field_mapping
      version: $version
      trip_details: $trip_details
      data_mapping: $data_mapping
      data_survey_id: $data_survey_id
      taskDeploymentId: $taskDeploymentId
      request_payload: $request_payload
    )
  }
`;
export const dataSheetFileExport = /* GraphQL */ `
  query DataSheetFileExport(
    $request_type: String!
    $sheet_id: String
    $sheet_name: String
    $filter: AWSJSON
  ) {
    dataSheetFileExport(
      request_type: $request_type
      sheet_id: $sheet_id
      sheet_name: $sheet_name
      filter: $filter
    )
  }
`;
export const dataSheetFileExport_job = /* GraphQL */ `
  query DataSheetFileExport_job(
    $request_type: String!
    $sheet_id: String
    $sheet_name: String
    $filter: AWSJSON
  ) {
    dataSheetFileExport_job(
      request_type: $request_type
      sheet_id: $sheet_id
      sheet_name: $sheet_name
      filter: $filter
    )
  }
`;
export const datalakeOcr = /* GraphQL */ `
  query DatalakeOcr(
    $request_type: String!
    $sheet_id: String!
    $sheet_name: String!
    $file_name: String!
  ) {
    datalakeOcr(
      request_type: $request_type
      sheet_id: $sheet_id
      sheet_name: $sheet_name
      file_name: $file_name
    )
  }
`;
export const portfolioCrawler = /* GraphQL */ `
  query PortfolioCrawler($domain_name: String!) {
    portfolioCrawler(domain_name: $domain_name)
  }
`;
export const generateSdgReport = /* GraphQL */ `
  query GenerateSdgReport($object_id: AWSJSON!) {
    generateSdgReport(object_id: $object_id)
  }
`;
export const artificialIntelligence = /* GraphQL */ `
  query ArtificialIntelligence(
    $prompt: String!
    $model: String
    $max_tokens: Int
    $temperature: Float
  ) {
    artificialIntelligence(
      prompt: $prompt
      model: $model
      max_tokens: $max_tokens
      temperature: $temperature
    )
  }
`;
export const ckeApi = /* GraphQL */ `
  query CkeApi($email: AWSEmail!, $name: String!) {
    ckeApi(email: $email, name: $name)
  }
`;
export const aiFunction = /* GraphQL */ `
  query AiFunction($request_type: String!, $event: AWSJSON, $context: AWSJSON) {
    aiFunction(request_type: $request_type, event: $event, context: $context)
  }
`;
export const emissionsCalculation = /* GraphQL */ `
  query EmissionsCalculation(
    $request_type: String!
    $request_payload: AWSJSON
  ) {
    emissionsCalculation(
      request_type: $request_type
      request_payload: $request_payload
    )
  }
`;
export const dataIntegration = /* GraphQL */ `
  query DataIntegration($request_type: DataIntegrationRequestType!) {
    dataIntegration(request_type: $request_type)
  }
`;
export const dataIntegration_job = /* GraphQL */ `
  query DataIntegration_job($request_type: DataIntegrationRequestType!) {
    dataIntegration_job(request_type: $request_type)
  }
`;
export const aiOverlay = /* GraphQL */ `
  query AiOverlay(
    $request_type: DataIntegrationRequestType!
    $request_payload: AWSJSON
  ) {
    aiOverlay(request_type: $request_type, request_payload: $request_payload)
  }
`;
export const aiOperation = /* GraphQL */ `
  query AiOperation(
    $request_type: DataIntegrationRequestType!
    $request_payload: AWSJSON
  ) {
    aiOperation(request_type: $request_type, request_payload: $request_payload)
  }
`;
export const aiOperation_job = /* GraphQL */ `
  query AiOperation_job(
    $request_type: DataIntegrationRequestType!
    $request_payload: AWSJSON
  ) {
    aiOperation_job(
      request_type: $request_type
      request_payload: $request_payload
    )
  }
`;
export const getCompanyInfo = /* GraphQL */ `
  query GetCompanyInfo($group: String!) {
    getCompanyInfo(group: $group) {
      group
      data
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCompanyInfos = /* GraphQL */ `
  query ListCompanyInfos(
    $group: String
    $filter: ModelCompanyInfoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCompanyInfos(
      group: $group
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        group
        data
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserSettings = /* GraphQL */ `
  query GetUserSettings($owner: String!, $settingName: String!) {
    getUserSettings(owner: $owner, settingName: $settingName) {
      settingName
      settingValue
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserSettingss = /* GraphQL */ `
  query ListUserSettingss(
    $owner: String
    $settingName: ModelStringKeyConditionInput
    $filter: ModelUserSettingsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserSettingss(
      owner: $owner
      settingName: $settingName
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        settingName
        settingValue
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getClientSettings = /* GraphQL */ `
  query GetClientSettings($group: String!) {
    getClientSettings(group: $group) {
      id
      group
      modules
      db_string
      session_timeout
      sso_callback_url
      sso_signout_url
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listClientSettingss = /* GraphQL */ `
  query ListClientSettingss(
    $group: String
    $filter: ModelClientSettingsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listClientSettingss(
      group: $group
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        group
        modules
        db_string
        session_timeout
        sso_callback_url
        sso_signout_url
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGoalsAndProgress = /* GraphQL */ `
  query GetGoalsAndProgress($id: ID!) {
    getGoalsAndProgress(id: $id) {
      id
      parent_id
      group
      owner
      name
      description
      priority
      category
      status
      start_date
      end_date
      goal_type
      assigned_groups
      assigned_users
      related_items
      sdg_num
      sdg_indicators
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listGoalsAndProgresss = /* GraphQL */ `
  query ListGoalsAndProgresss(
    $id: ID
    $filter: ModelGoalsAndProgressFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGoalsAndProgresss(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        parent_id
        group
        owner
        name
        description
        priority
        category
        status
        start_date
        end_date
        goal_type
        assigned_groups
        assigned_users
        related_items
        sdg_num
        sdg_indicators
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAuditManagement = /* GraphQL */ `
  query GetAuditManagement($id: ID!) {
    getAuditManagement(id: $id) {
      id
      group
      owner
      name
      description
      priority
      status
      start_date
      end_date
      audit_type
      assigned_groups
      assigned_users
      audit_items
      audit_settings
      email_subject
      email_body
      reminder_interval
      last_contacted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAuditManagements = /* GraphQL */ `
  query ListAuditManagements(
    $id: ID
    $filter: ModelAuditManagementFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAuditManagements(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        group
        owner
        name
        description
        priority
        status
        start_date
        end_date
        audit_type
        assigned_groups
        assigned_users
        audit_items
        audit_settings
        email_subject
        email_body
        reminder_interval
        last_contacted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTaskManagement = /* GraphQL */ `
  query GetTaskManagement($id: ID!) {
    getTaskManagement(id: $id) {
      id
      group
      owner
      assignee_group
      owner_group
      description
      focus_area
      priority
      status
      name
      task_type
      start_date
      end_date
      task_ids
      reminder_interval
      surveyManagementID
      surveyManagement {
        id
        group
        owner
        survey_type
        status
        name
        survey_order
        entityIds
        description
        popup_title
        info_message
        search_field_label
        search_field_label_value
        table_intro_text
        defaultMetaDataSheetId
        email_subject
        email_body
        email_reminder_subject
        email_reminder_body
        reminder_interval
        TaskManagementID
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTaskManagements = /* GraphQL */ `
  query ListTaskManagements(
    $id: ID
    $filter: ModelTaskManagementFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTaskManagements(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        group
        owner
        assignee_group
        owner_group
        description
        focus_area
        priority
        status
        name
        task_type
        start_date
        end_date
        task_ids
        reminder_interval
        surveyManagementID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSurveyManagement = /* GraphQL */ `
  query GetSurveyManagement($id: ID!) {
    getSurveyManagement(id: $id) {
      id
      group
      owner
      survey_type
      status
      name
      survey_order
      entityIds
      description
      popup_title
      info_message
      search_field_label
      search_field_label_value
      table_intro_text
      defaultMetaDataSheetId
      email_subject
      email_body
      email_reminder_subject
      email_reminder_body
      reminder_interval
      TaskManagementID
      TaskManagement {
        id
        group
        owner
        assignee_group
        owner_group
        description
        focus_area
        priority
        status
        name
        task_type
        start_date
        end_date
        task_ids
        reminder_interval
        surveyManagementID
        createdAt
        updatedAt
        __typename
      }
      taskDeployments {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSurveyManagements = /* GraphQL */ `
  query ListSurveyManagements(
    $id: ID
    $filter: ModelSurveyManagementFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSurveyManagements(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        group
        owner
        survey_type
        status
        name
        survey_order
        entityIds
        description
        popup_title
        info_message
        search_field_label
        search_field_label_value
        table_intro_text
        defaultMetaDataSheetId
        email_subject
        email_body
        email_reminder_subject
        email_reminder_body
        reminder_interval
        TaskManagementID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTaskDeployment = /* GraphQL */ `
  query GetTaskDeployment($id: ID!) {
    getTaskDeployment(id: $id) {
      id
      group
      owner
      email
      last_contacted
      priority
      status
      description
      completion_date
      entityId
      surveyManagementID
      assignedGroup
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTaskDeployments = /* GraphQL */ `
  query ListTaskDeployments(
    $id: ID
    $filter: ModelTaskDeploymentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTaskDeployments(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        group
        owner
        email
        last_contacted
        priority
        status
        description
        completion_date
        entityId
        surveyManagementID
        assignedGroup
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getConsultantSettings = /* GraphQL */ `
  query GetConsultantSettings($id: ID!) {
    getConsultantSettings(id: $id) {
      id
      group
      client_group
      access_type
      access_start
      access_end
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listConsultantSettingss = /* GraphQL */ `
  query ListConsultantSettingss(
    $id: ID
    $filter: ModelConsultantSettingsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listConsultantSettingss(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        group
        client_group
        access_type
        access_start
        access_end
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getJobExecutionStatus = /* GraphQL */ `
  query GetJobExecutionStatus($id: ID!) {
    getJobExecutionStatus(id: $id) {
      id
      group
      owner
      status
      functionName
      resultSummary
      requestParams
      completionDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listJobExecutionStatuss = /* GraphQL */ `
  query ListJobExecutionStatuss(
    $id: ID
    $filter: ModelJobExecutionStatusFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listJobExecutionStatuss(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        group
        owner
        status
        functionName
        resultSummary
        requestParams
        completionDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAIConversations = /* GraphQL */ `
  query GetAIConversations($id: ID!) {
    getAIConversations(id: $id) {
      id
      group
      owner
      overlay_id
      prompt
      response
      error_message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAIConversationss = /* GraphQL */ `
  query ListAIConversationss(
    $id: ID
    $filter: ModelAIConversationsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAIConversationss(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        group
        owner
        overlay_id
        prompt
        response
        error_message
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byGoalsAndProgressGroup = /* GraphQL */ `
  query ByGoalsAndProgressGroup(
    $group: String
    $sortDirection: ModelSortDirection
    $filter: ModelGoalsAndProgressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byGoalsAndProgressGroup(
      group: $group
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parent_id
        group
        owner
        name
        description
        priority
        category
        status
        start_date
        end_date
        goal_type
        assigned_groups
        assigned_users
        related_items
        sdg_num
        sdg_indicators
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byGoalsAndProgressGroupStatus = /* GraphQL */ `
  query ByGoalsAndProgressGroupStatus(
    $group: String
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGoalsAndProgressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byGoalsAndProgressGroupStatus(
      group: $group
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parent_id
        group
        owner
        name
        description
        priority
        category
        status
        start_date
        end_date
        goal_type
        assigned_groups
        assigned_users
        related_items
        sdg_num
        sdg_indicators
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byGoalsAndProgressParentId = /* GraphQL */ `
  query ByGoalsAndProgressParentId(
    $parent_id: ID
    $sortDirection: ModelSortDirection
    $filter: ModelGoalsAndProgressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byGoalsAndProgressParentId(
      parent_id: $parent_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parent_id
        group
        owner
        name
        description
        priority
        category
        status
        start_date
        end_date
        goal_type
        assigned_groups
        assigned_users
        related_items
        sdg_num
        sdg_indicators
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byAuditManagementGroup = /* GraphQL */ `
  query ByAuditManagementGroup(
    $group: String
    $sortDirection: ModelSortDirection
    $filter: ModelAuditManagementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byAuditManagementGroup(
      group: $group
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        owner
        name
        description
        priority
        status
        start_date
        end_date
        audit_type
        assigned_groups
        assigned_users
        audit_items
        audit_settings
        email_subject
        email_body
        reminder_interval
        last_contacted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byAuditManagementGroupStatus = /* GraphQL */ `
  query ByAuditManagementGroupStatus(
    $group: String
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAuditManagementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byAuditManagementGroupStatus(
      group: $group
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        owner
        name
        description
        priority
        status
        start_date
        end_date
        audit_type
        assigned_groups
        assigned_users
        audit_items
        audit_settings
        email_subject
        email_body
        reminder_interval
        last_contacted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byAuditManagementAuditType = /* GraphQL */ `
  query ByAuditManagementAuditType(
    $audit_type: AuditManagementType
    $sortDirection: ModelSortDirection
    $filter: ModelAuditManagementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byAuditManagementAuditType(
      audit_type: $audit_type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        owner
        name
        description
        priority
        status
        start_date
        end_date
        audit_type
        assigned_groups
        assigned_users
        audit_items
        audit_settings
        email_subject
        email_body
        reminder_interval
        last_contacted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byTaskManagementGroup = /* GraphQL */ `
  query ByTaskManagementGroup(
    $group: String
    $sortDirection: ModelSortDirection
    $filter: ModelTaskManagementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byTaskManagementGroup(
      group: $group
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        owner
        assignee_group
        owner_group
        description
        focus_area
        priority
        status
        name
        task_type
        start_date
        end_date
        task_ids
        reminder_interval
        surveyManagementID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const bySurveyManagementGroup = /* GraphQL */ `
  query BySurveyManagementGroup(
    $group: String
    $sortDirection: ModelSortDirection
    $filter: ModelSurveyManagementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bySurveyManagementGroup(
      group: $group
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        owner
        survey_type
        status
        name
        survey_order
        entityIds
        description
        popup_title
        info_message
        search_field_label
        search_field_label_value
        table_intro_text
        defaultMetaDataSheetId
        email_subject
        email_body
        email_reminder_subject
        email_reminder_body
        reminder_interval
        TaskManagementID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const bySurveyManagementGroupAndStatus = /* GraphQL */ `
  query BySurveyManagementGroupAndStatus(
    $group: String
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSurveyManagementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bySurveyManagementGroupAndStatus(
      group: $group
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        owner
        survey_type
        status
        name
        survey_order
        entityIds
        description
        popup_title
        info_message
        search_field_label
        search_field_label_value
        table_intro_text
        defaultMetaDataSheetId
        email_subject
        email_body
        email_reminder_subject
        email_reminder_body
        reminder_interval
        TaskManagementID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const bySurveyManagementIdGroup = /* GraphQL */ `
  query BySurveyManagementIdGroup(
    $surveyManagementID: ID
    $group: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskDeploymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bySurveyManagementIdGroup(
      surveyManagementID: $surveyManagementID
      group: $group
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        owner
        email
        last_contacted
        priority
        status
        description
        completion_date
        entityId
        surveyManagementID
        assignedGroup
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const bySurveyManagementIdEmail = /* GraphQL */ `
  query BySurveyManagementIdEmail(
    $surveyManagementID: ID
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskDeploymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bySurveyManagementIdEmail(
      surveyManagementID: $surveyManagementID
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        owner
        email
        last_contacted
        priority
        status
        description
        completion_date
        entityId
        surveyManagementID
        assignedGroup
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byTaskDeploymentGroupAndStatus = /* GraphQL */ `
  query ByTaskDeploymentGroupAndStatus(
    $group: String
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskDeploymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byTaskDeploymentGroupAndStatus(
      group: $group
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        owner
        email
        last_contacted
        priority
        status
        description
        completion_date
        entityId
        surveyManagementID
        assignedGroup
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byTaskDeploymentGroup = /* GraphQL */ `
  query ByTaskDeploymentGroup(
    $group: String
    $sortDirection: ModelSortDirection
    $filter: ModelTaskDeploymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byTaskDeploymentGroup(
      group: $group
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        owner
        email
        last_contacted
        priority
        status
        description
        completion_date
        entityId
        surveyManagementID
        assignedGroup
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const bySurveyManagementIdStatus = /* GraphQL */ `
  query BySurveyManagementIdStatus(
    $surveyManagementID: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskDeploymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bySurveyManagementIdStatus(
      surveyManagementID: $surveyManagementID
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        owner
        email
        last_contacted
        priority
        status
        description
        completion_date
        entityId
        surveyManagementID
        assignedGroup
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ConsultantSettingsByClientGroup = /* GraphQL */ `
  query ConsultantSettingsByClientGroup(
    $client_group: String
    $sortDirection: ModelSortDirection
    $filter: ModelConsultantSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ConsultantSettingsByClientGroup(
      client_group: $client_group
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        client_group
        access_type
        access_start
        access_end
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byJobExecutionOwner = /* GraphQL */ `
  query ByJobExecutionOwner(
    $owner: String
    $sortDirection: ModelSortDirection
    $filter: ModelJobExecutionStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byJobExecutionOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        owner
        status
        functionName
        resultSummary
        requestParams
        completionDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byJobExecutionStatus = /* GraphQL */ `
  query ByJobExecutionStatus(
    $status: String
    $sortDirection: ModelSortDirection
    $filter: ModelJobExecutionStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byJobExecutionStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        owner
        status
        functionName
        resultSummary
        requestParams
        completionDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byJobExecutionGroupStatus = /* GraphQL */ `
  query ByJobExecutionGroupStatus(
    $group: String
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJobExecutionStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byJobExecutionGroupStatus(
      group: $group
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        owner
        status
        functionName
        resultSummary
        requestParams
        completionDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byAIConversationOwner = /* GraphQL */ `
  query ByAIConversationOwner(
    $owner: String
    $sortDirection: ModelSortDirection
    $filter: ModelAIConversationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byAIConversationOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        owner
        overlay_id
        prompt
        response
        error_message
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byAIConversationOverlayId = /* GraphQL */ `
  query ByAIConversationOverlayId(
    $overlay_id: String
    $sortDirection: ModelSortDirection
    $filter: ModelAIConversationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byAIConversationOverlayId(
      overlay_id: $overlay_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        owner
        overlay_id
        prompt
        response
        error_message
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byAIConversationOwnerGroup = /* GraphQL */ `
  query ByAIConversationOwnerGroup(
    $group: String
    $owner: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAIConversationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byAIConversationOwnerGroup(
      group: $group
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        owner
        overlay_id
        prompt
        response
        error_message
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAIOverlay = /* GraphQL */ `
  query GetAIOverlay($id: ID!) {
    getAIOverlay(id: $id) {
      id
      app_page
      app_page_subfield
      name
      description
      field
      status
      prompt_engineering
      system_prompt
      settings
      model_name
      config
      group
      roles
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAIOverlays = /* GraphQL */ `
  query ListAIOverlays(
    $id: ID
    $filter: ModelAIOverlayFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAIOverlays(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        app_page
        app_page_subfield
        name
        description
        field
        status
        prompt_engineering
        system_prompt
        settings
        model_name
        config
        group
        roles
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byAppPage = /* GraphQL */ `
  query ByAppPage(
    $app_page: String
    $sortDirection: ModelSortDirection
    $filter: ModelAIOverlayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byAppPage(
      app_page: $app_page
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        app_page
        app_page_subfield
        name
        description
        field
        status
        prompt_engineering
        system_prompt
        settings
        model_name
        config
        group
        roles
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byOverlayName = /* GraphQL */ `
  query ByOverlayName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelAIOverlayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byOverlayName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        app_page
        app_page_subfield
        name
        description
        field
        status
        prompt_engineering
        system_prompt
        settings
        model_name
        config
        group
        roles
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byAppPageSubfield = /* GraphQL */ `
  query ByAppPageSubfield(
    $app_page: String
    $app_page_subfield: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAIOverlayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byAppPageSubfield(
      app_page: $app_page
      app_page_subfield: $app_page_subfield
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        app_page
        app_page_subfield
        name
        description
        field
        status
        prompt_engineering
        system_prompt
        settings
        model_name
        config
        group
        roles
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
