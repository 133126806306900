import {
  ArrowLeftOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
  CheckCircleOutlined,
  PlusOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Collapse,
  Drawer,
  Form,
  Popover,
  Row,
  Space,
  Spin,
  Steps,
  Tag,
  Tooltip,
  Typography,
  message,
  notification,
} from "antd";
import {
  CardsWrap,
  FormWrap,
  FormWrapper,
  StyledButton,
  StyledSteps,
  StyledTabs,
  CommonModal,
} from "../../../shared/commonStyles";
import React, { useEffect, useState } from "react";
import {
  getTaskDeploymentById,
  updateTaskDeployment,
} from "../../../services/taskDeployment";
import { useLocation, useNavigate } from "react-router-dom";

import { Auth } from "aws-amplify";
import { DataSheetMultiLineForm } from "./DataSheetMultiLineForm";
import { DataSheetSingleForm } from "./DataSheetSingleForm";
import { ImportedDataModal } from "../../dataSheetsPage/dataSheetDocumentsPage/Components/ImportedDataModal.js";
import StandardsForm from "./StandardsForm";
import { WelcomePageForm } from "./WelcomePageForm";
import _ from "lodash";
import { handleLongString } from "../../../shared/commonFunctions";
import { massUpdateRecords } from "../../../services/dataSheet";
import moment from "moment";
import { observer } from "mobx-react-lite";
import styles from "./SurveyView.module.scss";
import { surveyStore } from "../../../stores/SurveyStore";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useSize } from "ahooks";
import { useUserSettings } from "../../../hooks/useUserSettings";
import { SubmitConfirmationModal } from "./SubmitConfirmationModal";
import { encodeUrlName } from "../../../shared/commonFunctions";

const { Title } = Typography;
const { Step } = Steps;

const SurveyView = observer(() => {
  const surveyId = surveyStore.surveyId;
  const { state } = useLocation();
  const navigate = useNavigate();
  const { createUserSettings, updateUserSettings, getUserSettings } =
    useUserSettings();
  const [userSettings, setUserSettings] = useState(null);
  const [resultMessage, setResultMessage] = useState(null);
  const [selectedHistoryVersion, setSelectedHistoryVersion] = useState(null);
  const [latestRecord, setLatestRecord] = useState(null);
  const [showImportDataModal, setShowImportDataModal] = useState(false);
  const [showInformationDrawer, setShowInformationDrawer] = useState(false);
  const { user } = useAuthenticator((context) => [context.user]);
  const size = useSize(document.querySelector("body"));
  const [validationIssuesId, setValidationIssuesId] = useState("");
  const [panelStates, setPanelStates] = useState({ 2: true });
  const [drawer, setDrawer] = useState("info");
  const [isSubmitModal, setSubmitModal] = useState(false);
  const [currentIdentifier, setCurrentIdentifier] = useState("");
  const [form] = Form.useForm();
  const { Panel } = Collapse;
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!surveyStore.surveySubmitting) {
      if (
        !surveyStore.currentStepInfo?.multiline_form_flag &&
        surveyStore.dataSheetRecords?.length
      ) {
        setSelectedHistoryVersion(
          surveyStore.dataToSave?.[0]?._id?.$oid
            ? surveyStore.dataToSave[0]?._id?.$oid
            : surveyStore.dataSheetRecords?.[0]?._id?.$oid
        );
        setLatestRecord(
          surveyStore.dataToSave?.[0]?._id?.$oid
            ? surveyStore.dataToSave[0]
            : surveyStore.dataSheetRecords?.[0]
        );
        const idntfTag =
          surveyStore.dataSheetRecords?.[0][
            surveyStore.dataSheetRecords?.[0]?.sheet_name
          ][
            surveyStore?.currentStepData?.dataSheet?.sheet_schema?.find(
              (v) =>
                v.metadata_entity_name ==
                surveyStore.surveyInfo.identifierData?.idnfEntityName
            )?.entity_name
          ];

        setCurrentIdentifier(idntfTag);
      } else {
        setSelectedHistoryVersion(null);
        setLatestRecord(
          surveyStore.dataSheet
            ? { sheet_name: surveyStore.dataSheet.sheet_name }
            : null
        );
      }
    }
  }, [surveyStore?.currentStep, surveyStore.surveySubmitting]);

  const onStepChange = async (current) => {
    await handleAutosaveSurvey(true, current);
  };

  const togglePanel = (panelKey) => {
    setPanelStates((prevStates) => ({
      ...prevStates,
      [panelKey]: !prevStates[panelKey],
    }));
  };

  const loadUserData = async () => {
    try {
      const data = await Auth.currentSession();
      const username = data.accessToken.payload.username;
      const payload = {
        owner: username,
        settingName: "survey_settings",
      };
      const response = await getUserSettings(payload);
      if (response) {
        setUserSettings(response);
        const settingsData = JSON.parse(response.settingValue);
        const surveyData = settingsData?.find((item) =>
          state && state.taskDeploymentId
            ? item?.taskDeploymentId === state.taskDeploymentId
            : item?.surveyId === surveyId
        );
        const current =
          surveyData?.currentStep < surveyStore.surveySteps?.length
            ? surveyData?.currentStep
            : 0;
        surveyStore.changeCurrentStep(current);
      }
    } catch (error) {
      console.log("Error while loading default metadata information!", error);
    }
  };

  const handleSaveSettings = async (currentStep) => {
    try {
      surveyStore.changeSurveySubmitting(true);
      const data = await Auth.currentSession();
      const username = data.accessToken.payload.username;
      const newValue = {
        surveyId,
        currentStep,
        ...(state &&
          state.taskDeploymentId && {
            taskDeploymentId: state.taskDeploymentId,
          }),
      };
      if (userSettings) {
        const settingValue = JSON.parse(userSettings.settingValue);

        let newSettingValue = [];

        const isSurveySetting = settingValue.find((item) =>
          state && state.taskDeploymentId
            ? item?.taskDeploymentId === state.taskDeploymentId
            : item?.surveyId === surveyId
        );
        if (isSurveySetting) {
          newSettingValue = settingValue.map((item) =>
            (
              state && state.taskDeploymentId
                ? item?.taskDeploymentId === state.taskDeploymentId
                : item?.surveyId === surveyId
            )
              ? newValue
              : item
          );
        } else {
          newSettingValue = [...settingValue, newValue];
        }
        const payload = {
          owner: username,
          settingName: "survey_settings",
          settingValue: JSON.stringify(newSettingValue),
        };
        await updateUserSettings(payload);
      } else {
        const payload = {
          owner: username,
          settingName: "survey_settings",
          settingValue: JSON.stringify([newValue]),
        };
        await createUserSettings(payload);
      }
    } catch (error) {
      console.log("Something went wrong!", error);
    } finally {
      surveyStore.changeSurveySubmitting(false);
    }
  };

  const importedDataAction = (data) => {
    data?.actionType === "data_imported" &&
      data?.messageType === "review_later" &&
      setShowImportDataModal(true);
  };

  useEffect(() => {
    if (state?.currentStep) {
      const stateCurrentStep = state?.currentStep;
      surveyStore.changeCurrentStep(stateCurrentStep);
      importedDataAction(state);
    }
  }, [state]);

  useEffect(() => {
    surveyStore.surveySteps?.length && loadUserData();
  }, [surveyStore.surveySteps?.length]);
  const createStepItem = () => {
    // create steps from flow items
    if (surveyStore.surveySteps) {
      return surveyStore.surveySteps?.map((item, index) => {
        if (
          (item?.status && item?.status !== "completed") ||
          item?.popconfirms?.filter((popconfirm) => !popconfirm?.reason?.length)
            ?.length ||
          item?.validation?.length
        ) {
          return (
            <Step
              key={item}
              status={"wait"}
              title={
                <>
                  {(item?.status === "requiredEmpty" ||
                    item?.status === "mixedrequired") && (
                    <span className={styles.exclamationIcon}>!</span>
                  )}

                  {item?.status === "actionRequired" ? (
                    <Popover
                      overlayClassName="popoverContent"
                      content="Action required"
                      placement="right"
                    >
                      <WarningOutlined
                        style={{ color: "#e9d155", marginRight: "5px" }}
                      />
                    </Popover>
                  ) : (
                    !!(
                      item.popconfirms?.filter(
                        (popconfirm) => !popconfirm?.reason?.length
                      )?.length || !!item.validation?.length
                    ) && (
                      <WarningOutlined
                        style={{ color: "#e9d155", marginRight: "5px" }}
                      />
                    )
                  )}
                  {item?.data ? item?.data?.name : item?.stepName}
                </>
              }
            />
          );
        }
        return (
          <Step
            key={item}
            status={
              item?.status === "completed" &&
              surveyStore.currentStep !== index &&
              "finish"
            }
            title={item?.data ? item?.data?.name : item?.stepName}
          />
        );
      });
    }
  };
  const checkSkippedStep = () => {
    surveyStore.surveySteps?.[surveyStore.currentStep]?.status ===
      "mixedrequired" && surveyStore.changeSurveyData("skipped", "stepStatus");
    surveyStore.surveySteps?.[surveyStore.currentStep]?.status ===
      "requiredEmpty" &&
      surveyStore.changeSurveyData("skippedRequiredEmpty", "stepStatus");
    surveyStore.surveySteps?.[surveyStore.currentStep]?.status ===
      "actionRequired" && surveyStore.changeSurveyData("", "stepStatus");
  };

  const onClickNext = (isSkipped) => {
    isSkipped && checkSkippedStep();
    const nextStepIndex = surveyStore.currentStep + 1;
    surveyStore.changeCurrentStep(nextStepIndex);
    handleSaveSettings(nextStepIndex);
  };

  const onClickPrevious = () => {
    checkSkippedStep();
    const previousStepIndex = surveyStore.currentStep - 1;
    surveyStore.changeCurrentStep(previousStepIndex);
    handleSaveSettings(previousStepIndex);
  };

  const getHistoryDataByVersionId = (recordId) => {
    return (
      surveyStore.dataSheetRecords &&
      surveyStore.dataSheetRecords.find((item) => item._id.$oid === recordId)
    );
  };
  const onChangeHistoryVersion = (recordId, identifier) => {
    setSelectedHistoryVersion(recordId);
    const historyVersionData = getHistoryDataByVersionId(recordId);
    setLatestRecord(historyVersionData);
    if (identifier) {
      setCurrentIdentifier(identifier);
    }
  };

  const handleUpdateTaskDeployment = async (taskStatus) => {
    const currentTask = await getTaskDeploymentById(state.taskDeploymentId);
    if (currentTask) {
      const newStatus =
        currentTask.status === "Pending" || currentTask.status === "Completed"
          ? "Pending"
          : taskStatus;
      const dateFormat = "YYYY-MM-DD";
      const completedDate = moment().format(dateFormat);
      const payload = {
        id: state?.taskDeploymentId,
        email: currentTask.email,
        status: newStatus,
        ...(taskStatus === "Pending" && { completion_date: completedDate }),
        ...(currentTask.assignedGroup && {
          assignedGroup: currentTask.assignedGroup,
        }),
      };
      await updateTaskDeployment(payload);
    }
  };

  const handleUpdateRecordsStatus = async () => {
    const data = await Auth.currentSession();
    const username = data.accessToken.payload.username;
    if (surveyStore.surveyInfo && surveyStore.surveyInfo.survey_order) {
      const filteredSurveyOrder = surveyStore.surveyInfo.survey_order
        .filter((item) => item?.survey_type === "data_sheets")
        .filter((item) =>
          surveyStore.surveySteps.find(
            (v) => v.dataSheet?._id?.$oid === item.id
          )
        );
      await Promise.all(
        filteredSurveyOrder.map(async (item) => {
          const payload = {
            sheet_id: item.id,
            filters: {
              review_status: "IN_PROGRESS",
              ...(state && state.taskDeploymentId
                ? {
                    taskDeploymentId: state.taskDeploymentId,
                  }
                : { username: username, data_survey_id: surveyId }),
            },
            column: "review_status",
            value: "PENDING",
          };
          await massUpdateRecords(payload);
        })
      );
    }
  };
  const handleSaveForSubmit = async () => {
    const isDataToSave = checkDataToSave();
    if (
      surveyStore.currentStepInfo?.survey_type === "standards" &&
      isDataToSave
    ) {
      await surveyStore.updateStandardData();
    } else if (
      surveyStore.currentStepInfo?.survey_type === "data_sheets"
      // && isDataToSave // comment this out since we just want to save survey even if no field values changed
    ) {
      // actual saving of surrvey
      //console.log('current step info')
      //console.log(surveyStore.currentStepInfo);
      await surveyStore.saveDataSheetRecords(false, 0, true).catch(() => {
        message.error("Error while saving record!");
        const values =
          surveyStore.dataToSave?.[0][surveyStore.dataSheet.sheet_name];
        if (values && !surveyStore.currentStepInfo?.multiline_form_flag) {
          form.resetFields();
          const updatedValues = {};
          for (const [key, value] of Object.entries(values)) {
            updatedValues[key] = value;
          }
          Object.keys(updatedValues).forEach((key) => {
            if (key.includes("_actual_value")) {
              updatedValues[key.replace("_actual_value", "")] =
                updatedValues[key];
            } else if (key.includes("_actual_unit_id")) {
              updatedValues[key.replace("_actual_unit_id", "_unit_id")] =
                updatedValues[key];
            }
          });
          form.setFieldsValue(updatedValues);
          surveyStore.changeSurveyData(
            [
              {
                ...surveyStore.dataToSave?.[0],
                [surveyStore.dataSheet.sheet_name]: updatedValues,
              },
            ],
            "dataToSave"
          );
        }
      });
    }
  };

  const handleSubmitSurvey = async () => {
    surveyStore.changeSurveySubmitting(true);
    try {
      await handleSaveForSubmit().then(async () => await completeSurvey());
    } catch (error) {
      message.error("Something went wrong");
    } finally {
      surveyStore.changeSurveySubmitting(false);
    }
  };

  const completeSurvey = async () => {
    if (
      surveyStore.surveySteps?.some(
        (v) =>
          v.status === "requiredEmpty" ||
          v.status === "actionRequired" ||
          v.popconfirms?.filter((popconfirm) => !popconfirm?.reason?.length)
            ?.length ||
          v.validation?.length
      )
    ) {
      surveyStore.surveySteps?.some(
        (v) => v.status === "requiredEmpty" || v.status === "actionRequired"
      )
        ? message.error(
            "Please complete all the required fields and acknowledge all admin notes in order to successfully submit the survey"
          )
        : message.error(
            "Please make sure that all entered data complies with validation rules and variance detection rules"
          );
      return;
    }
    surveyStore.changeSurveySubmitting(true);
    if (state && state.taskDeploymentId) {
      await handleUpdateTaskDeployment("Pending");
    }
    await handleUpdateRecordsStatus();
    setResultMessage({
      status: "success",
      visible: true,
      title: "Form data was saved successfully!",
      subTitle: "Form data was saved successfully!",
    });
    await handleSaveSettings(0);
    surveyStore.changeSurveySubmitting(false);
  };
  const getRecordIndexById = (recordId, stepIndex) => {
    const index = surveyStore?.surveySteps?.[stepIndex]?.submissions?.findIndex(
      (element) => element._id.$oid === recordId
    );
    return index;
  };
  const isStepWithIdntf = () => {
    const targetStep = surveyStore?.surveySteps.find(
      (v) => v?.dataSheet?._id?.$oid === surveyStore?.currentStepInfo?.id
    );

    return !!targetStep?.dataSheet?.metadata_sheet_id?.length;
  };

  const infoPanelContent = (
    <div>
      <div>
        {surveyStore.currentStepInfo?.survey_type === "data_sheets" &&
          (isStepWithIdntf() || surveyStore.currentStepInfo?.instructions) && (
            <Space
              direction="vertical"
              style={
                !panelStates["1"] && {
                  height: "70px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }
              }
            >
              {isStepWithIdntf() && (
                <Col span={24} style={{ padding: "0px" }}>
                  <Title
                    style={{ paddingBottom: "0px" }}
                    className="color-white "
                    level={5}
                  >
                    {surveyStore?.surveyInfo?.identifierData?.idnfDisplayName}
                  </Title>
                  <Tag color={"#A68DFB"} className="tag-rounded">
                    {surveyStore?.surveyInfo?.identifierData?.idnfValue}
                  </Tag>
                </Col>
              )}
              <div
                className="color-white"
                style={{
                  lineHeight: "1.5",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                dangerouslySetInnerHTML={{
                  __html: surveyStore.currentStepInfo?.instructions,
                }}
              ></div>
            </Space>
          )}
        {surveyStore.informationData &&
          surveyStore.currentStepInfo?.survey_type === "standards" && (
            <Space
              direction="vertical"
              style={
                !panelStates["1"] && {
                  height: "60px",
                  overflow: "hidden",
                }
              }
            >
              <p className="color-white">
                {" "}
                {surveyStore.informationData.description}
              </p>
              <div>
                {(surveyStore.informationData?.standardLink || []).map(
                  (val, index) => (
                    <Tooltip title={val.name} key={index}>
                      <Row>
                        <Col span={24}>
                          <a
                            className="padding-0"
                            href={val.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "#7F5FEE" }}
                          >
                            Link to {`"${handleLongString(val.name, 10)}"`}
                            {val.page_num ? `, Page No: ${val.page_num}` : ""}
                          </a>
                        </Col>
                      </Row>
                    </Tooltip>
                  )
                )}
              </div>
            </Space>
          )}
      </div>
    </div>
  );
  const getCollapseButton = (key) => {
    return (
      <StyledButton
        width="100%"
        height="40px"
        padding="5px"
        hoverbgcolor="transparent"
        bgcolor="transparent"
        bordercolor="#B1AFBC"
        type="custom"
        onClick={() => togglePanel(key)}
      >
        {panelStates[key] ? (
          <>
            <CaretUpOutlined /> Collapse Section
          </>
        ) : (
          <>
            {" "}
            <CaretDownOutlined /> {key == 1 ? "Read more" : "Expand Section"}
          </>
        )}
      </StyledButton>
    );
  };

  const informationAndActions = (
    <div>
      <div>
        <Collapse ghost className={styles.customCollapsePanel} activeKey="1">
          <Panel
            style={{
              minHeight: "100px",
              overflow: "hidden",
            }}
            showArrow={false}
            header={
              <Title
                className="color-white "
                style={{
                  marginBottom: "0px",
                }}
                level={3}
              >
                Information
              </Title>
            }
            key="1"
          >
            {" "}
            {infoPanelContent}
          </Panel>
        </Collapse>{" "}
        {getCollapseButton("1")}
      </div>

      <div>
        <Collapse
          ghost
          className={styles.customCollapsePanel}
          activeKey={Object.keys(panelStates).filter((key) => panelStates[key])}
        >
          <Panel
            showArrow={false}
            header={
              <Title className="color-white" level={3}>
                Progress
              </Title>
            }
            key="2"
          >
            <div style={{ width: "100%", marginBottom: "20px" }}>
              <StyledSteps
                current={surveyStore.currentStep}
                onChange={onStepChange}
                direction="vertical"
              >
                {createStepItem()}
              </StyledSteps>
              {(surveyStore.surveySteps?.find(
                (step) =>
                  step?.stepStatus === "skipped" ||
                  step?.popconfirms?.filter(
                    (popconfirm) => !popconfirm?.reason?.length
                  )?.length ||
                  step?.validation?.length
              ) && (
                <div style={{ color: "red", margin: "7px 5px" }}>
                  Please complete all the required fields and acknowledge all
                  admin notes in order to successfully submit the survey
                </div>
              )) ||
                (surveyStore.surveySteps?.find(
                  (step) => step?.status === "actionRequired"
                ) && (
                  <div style={{ color: "#e9d155", marginTop: "7px 5px" }}>
                    Please review all marked fields to successfully submit the
                    survey
                  </div>
                )) ||
                (surveyStore.surveySteps?.find(
                  (step) => step?.stepStatus === "skippedRequiredEmpty"
                ) && (
                  <div style={{ color: "red", marginTop: "7px 5px" }}>
                    Please complete all the required fields to successfully
                    submit the survey
                  </div>
                ))}
            </div>
          </Panel>
        </Collapse>{" "}
        {getCollapseButton("2")}
      </div>
      {surveyStore.surveySteps.some(
        (step) => step?.dataSheet && !!step?.validation?.length
      ) && (
        <div>
          <Collapse
            ghost
            className={styles.customCollapsePanel}
            activeKey={Object.keys(panelStates).filter(
              (key) => panelStates[key]
            )}
          >
            <Panel
              showArrow={false}
              header={
                <Title className="color-white" level={3}>
                  Data validation Issues
                </Title>
              }
              key="3"
            >
              {surveyStore.surveySteps.map((step, index) => {
                return (
                  step?.dataSheet &&
                  !!step?.validation?.length && (
                    <>
                      <Title className="color-white" level={5}>
                        {step.dataSheet.sheet_name}
                      </Title>
                      {step?.validation.map((val) => {
                        const newValidationFields = [];

                        val.fields.forEach(
                          (field) =>
                            !newValidationFields?.find(
                              (v) => v.errorMessage === field.errorMessage
                            ) && newValidationFields.push(field)
                        );

                        return (
                          <>
                            {newValidationFields.map((field, index) => {
                              return (
                                <Button
                                  key={index}
                                  type="link"
                                  style={{
                                    color: "#A68DFB",
                                    padding: "0",
                                  }}
                                  onClick={() =>
                                    handleClickValidation(index, val?.recordId)
                                  }
                                >
                                  {field?.errorMessage} :
                                  {surveyStore.surveyInfo?.survey_order[index]
                                    .multiline_form_flag ? (
                                    <>
                                      Line{" "}
                                      {getRecordIndexById(
                                        val?.recordId,
                                        index
                                      ) + 1}{" "}
                                    </>
                                  ) : (
                                    <>
                                      Submission{" "}
                                      {surveyStore.surveySteps?.[index]
                                        ?.submissions.length -
                                        getRecordIndexById(
                                          val?.recordId,
                                          index
                                        )}
                                    </>
                                  )}
                                </Button>
                              );
                            })}
                          </>
                        );
                      })}
                    </>
                  )
                );
              })}
            </Panel>
          </Collapse>{" "}
          {getCollapseButton("3")}
        </div>
      )}
    </div>
  );

  const rightPartContent = (
    <>
      {!surveyStore.currentStepInfo?.multiline_form_flag ? (
        <StyledTabs activeKey={drawer} onChange={(key) => setDrawer(key)}>
          <StyledTabs.TabPane tab="Information & Actions" key="info">
            {informationAndActions}
          </StyledTabs.TabPane>
          {
            <StyledTabs.TabPane tab="Submissions" key="submissions">
              {surveyStore.currentStepInfo?.survey_type === "data_sheets" &&
                surveyStore.dataSheetRecords?.length > 0 &&
                surveyStore.currentStepInfo.id ===
                  surveyStore.dataSheetRecords?.[0]?.sheet_id.$oid &&
                !surveyStore.currentStepInfo?.multiline_form_flag && (
                  <>
                    {" "}
                    <Row justify="space-between" align="middle">
                      {" "}
                      <Title className="color-white" level={3}>
                        Submissions
                      </Title>{" "}
                      <StyledButton
                        style={{ whiteSpace: "normal" }}
                        width="40%"
                        padding="5px"
                        hoverbgcolor="transparent"
                        bgcolor="transparent"
                        bordercolor="#fff"
                        type="custom"
                        onClick={() => {
                          setLatestRecord(null);
                          setSelectedHistoryVersion(null);
                        }}
                      >
                        <PlusOutlined /> New Submission
                      </StyledButton>
                    </Row>
                    {surveyStore.dataSheetRecords?.map((item, index) => {
                      const createDate = item?.last_modified?.$date
                        ? moment(item?.last_modified.$date).format(
                            "Y-M-D H:m:s"
                          )
                        : null;

                      const idntfTag =
                        item[item?.sheet_name][
                          surveyStore?.currentStepData?.dataSheet?.sheet_schema?.find(
                            (v) =>
                              v.metadata_entity_name ==
                              surveyStore.surveyInfo.identifierData
                                ?.idnfEntityName
                          )?.entity_name
                        ];

                      const firstItem =
                        item[item?.sheet_name][
                          surveyStore.currentStepInfo?.display_fields[0]
                        ];

                      const firstValue =
                        typeof firstItem === "object" &&
                        firstItem !== null &&
                        firstItem.hasOwnProperty("$date")
                          ? moment(firstItem.$date).format("YYYY-MM-DD")
                          : item[item?.sheet_name][
                              surveyStore.currentStepInfo?.display_fields[0]
                            ] ||
                            item[item?.sheet_name][
                              surveyStore.currentStepInfo?.display_fields[0] +
                                "_actual_value"
                            ] ||
                            "";

                      const getTagInfo = () => {
                        switch (item.review_status) {
                          case "DENIED":
                            return { text: "Denied", color: "#EC5E5E" };
                          case "APPROVED":
                            return { text: "Approved", color: "#5BA85A" };
                          case "IN_PROGRESS":
                            return { text: "In Progress", color: " #569CE6" };
                          case "PENDING":
                            return { text: "Pending Review", color: "#E69B56" };
                          default:
                            return;
                        }
                      };
                      const getWarningType = () => {
                        let warning = [];
                        if (
                          surveyStore.requiredSchemaFields?.length &&
                          (!surveyStore.requiredSchemaFields?.every((v) =>
                            Object.keys(item[item.sheet_name]).find(
                              (key) => key === v
                            )
                          ) ||
                            Object.entries(item[item.sheet_name]).find(
                              ([key, value]) => {
                                return (
                                  !!surveyStore.requiredSchemaFields?.includes(
                                    key
                                  ) &&
                                  !value &&
                                  value !== 0
                                );
                              }
                            ))
                        ) {
                          warning.push(
                            "Please complete all the required fields to successfully submit the survey"
                          );
                        }
                        if (
                          item.field_notes?.find(
                            (note) =>
                              note.isAddedByAdmin && !note.isAcknowledged
                          ) ||
                          item.statusReasons?.find(
                            (v) =>
                              v.review_status === "DENIED" && !v.isAcknowledged
                          )
                        ) {
                          warning.push(
                            "Please acknowledge all admin notes in order to successfully submit the survey"
                          );
                        }
                        if (
                          surveyStore.currentStepData.popconfirms?.filter(
                            (popconfirm) =>
                              popconfirm?.recordId === item._id.$oid &&
                              !popconfirm?.reason?.length
                          )?.length
                        ) {
                          warning.push(
                            "Please make sure that all entered data complies with variance detection rules"
                          );
                        }
                        if (
                          surveyStore.currentStepData.validation?.some(
                            (v) => v.recordId === item._id.$oid
                          )
                        ) {
                          warning.push(
                            "Please make sure that all entered data complies with validation rules "
                          );
                        }

                        return warning;
                      };

                      return (
                        <CardsWrap
                          key={item._id.$oid}
                          style={
                            selectedHistoryVersion == item._id.$oid
                              ? {
                                  position: "relative",
                                  margin: "20px 5px",
                                  backgroundColor: "#3C3454",
                                  border: "1px solid #A68DFB",
                                  borderRadius: "4px",
                                  padding: "15px",
                                }
                              : {
                                  position: "relative",
                                  margin: "20px 5px",
                                  backgroundColor: "#3C3454",
                                  borderRadius: "4px",
                                  padding: "15px",
                                }
                          }
                        >
                          <Col span={24} className="color-white">
                            {`Submission ${
                              surveyStore.dataSheetRecords.length - index
                            }: ${firstValue || ""} - ${createDate}`}
                          </Col>
                          {!!item.review_status?.length && (
                            <Col span={24} style={{ margin: "7px 0px" }}>
                              {" "}
                              {!!idntfTag?.length && (
                                <Tag color={"#A68DFB"} className="tag-rounded">
                                  {idntfTag}
                                </Tag>
                              )}
                              <Tag
                                color={getTagInfo()?.color}
                                className="tag-rounded"
                              >
                                {getTagInfo()?.text}
                              </Tag>
                            </Col>
                          )}

                          {!!getWarningType() && (
                            <Row
                              justify="start"
                              style={{ color: "#e9d155", margin: "5px 0px" }}
                            >
                              {getWarningType().map((v) => {
                                return (
                                  <Col key={v}>
                                    {" "}
                                    <WarningOutlined
                                      style={{
                                        color: "#e9d155",
                                        marginRight: "5px",
                                      }}
                                    />
                                    {v}
                                  </Col>
                                );
                              })}
                            </Row>
                          )}
                          <Row style={{ width: "100%" }}>
                            {" "}
                            <StyledButton
                              width="50%"
                              height="35px"
                              margin="10px 0px"
                              padding="3px"
                              hoverbgcolor="transparent"
                              bgcolor="transparent"
                              bordercolor="#fff"
                              type="custom"
                              onClick={() =>
                                onChangeHistoryVersion(item._id.$oid, idntfTag)
                              }
                            >
                              View Submission
                            </StyledButton>
                          </Row>
                        </CardsWrap>
                      );
                    })}
                  </>
                )}
            </StyledTabs.TabPane>
          }
        </StyledTabs>
      ) : (
        <> {informationAndActions}</>
      )}
    </>
  );

  const handleNotification = (isStepper, step) => {
    const key = "exitNotification";
    let isConfirm = isStepper ? false : true;
    const btn = (
      <Space>
        <StyledButton
          onClick={() => {
            isConfirm = false;
            notification.destroy();
          }}
        >
          Cancel
        </StyledButton>
        <StyledButton
          onClick={() => {
            if (isStepper) isConfirm = true;
            notification.close(key);
          }}
        >
          Dismiss and skip
        </StyledButton>
      </Space>
    );
    notification.open({
      message: (
        <Col span={24}>
          <b>Warning:</b>{" "}
          {!isStepper && "Are you sure you want to leave the survey?"} Your data
          may not be saved.
        </Col>
      ),
      key,
      type: "warning",
      placement: "top",
      closeIcon: btn,
      className: styles.exitNotification,
      duration: 5,
      onClose: async () => {
        if (isConfirm) {
          if (isStepper) {
            checkSkippedStep();
            await handleSaveSettings(step);
          } else navigate("/management/collect-data");
        }
      },
    });
  };

  // checks if there is any data to save. checks if there is any field value changes.
  const checkDataToSave = () => {
    switch (surveyStore.currentStepInfo?.survey_type) {
      case "data_sheets":
        return surveyStore.currentStepInfo?.multiline_form_flag
          ? !!surveyStore.dataToSave?.length
          : !!surveyStore.dataToSave?.length &&
              (surveyStore.dataToSave[0]?.sheet_type === "edited" ||
                surveyStore.dataToSave[0]?.sheet_type === "new") &&
              Object.keys(surveyStore.dataToSave?.[0])?.length > 1;
      case "standards":
        return !_.isEqual(surveyStore.standardData, surveyStore.dataToSave);
      default:
        return false;
    }
  };

  const handleAutosaveSurvey = async (isStepper, step) => {
    const isDataToSave = checkDataToSave();
    if (isDataToSave) {
      if (surveyStore.currentStepInfo?.survey_type === "standards") {
        message.info("Autosaving your current progress...");
        surveyStore
          .updateStandardData(isStepper, step)
          .then(async () => {
            isStepper
              ? await handleSaveSettings(step)
              : navigate("/management/collect-data");
          })
          .catch(() => {
            handleNotification(isStepper, step);
          });
      } else if (surveyStore.currentStepInfo?.survey_type === "data_sheets") {
        message.info("Autosaving your current progress...");
        surveyStore
          .saveDataSheetRecords(isStepper, step)
          .then(async () => {
            if (state?.taskDeploymentId) {
              await handleUpdateTaskDeployment("In Progress");
            }
            isStepper
              ? await handleSaveSettings(step)
              : navigate("/management/collect-data");
          })
          .catch(() => {
            handleNotification(isStepper, step);
            const values =
              surveyStore.dataToSave?.[0][surveyStore.dataSheet.sheet_name];
            if (values && !surveyStore.currentStepInfo?.multiline_form_flag) {
              form.resetFields();
              const updatedValues = {};
              for (const [key, value] of Object.entries(values)) {
                updatedValues[key] = value;
              }
              Object.keys(updatedValues).forEach((key) => {
                if (key.includes("_actual_value")) {
                  updatedValues[key.replace("_actual_value", "")] =
                    updatedValues[key];
                } else if (key.includes("_actual_unit_id")) {
                  updatedValues[key.replace("_actual_unit_id", "_unit_id")] =
                    updatedValues[key];
                }
              });
              form.setFieldsValue(updatedValues);
              surveyStore.changeSurveyData(
                [
                  {
                    ...surveyStore.dataToSave?.[0],
                    [surveyStore.dataSheet.sheet_name]: updatedValues,
                  },
                ],
                "dataToSave"
              );
            }
          });
      }
    } else {
      if (isStepper) {
        checkSkippedStep();
        surveyStore.changeCurrentStep(step);
        await handleSaveSettings(step);
      } else navigate("/management/collect-data");
    }
  };

  useEffect(() => {
    surveyStore.isAutosave && handleAutosaveSurvey(false);
  }, [surveyStore.isAutosave]);

  const handleClickValidation = async (stepIndex, recordId) => {
    surveyStore.changeCurrentStep(stepIndex);
    await handleSaveSettings(stepIndex);
    setValidationIssuesId(recordId);
    const targetSubmission = surveyStore.dataSheetRecords.find(
      (v) => v?._id?.$oid === recordId
    );
    setLatestRecord(targetSubmission);
    setSelectedHistoryVersion(recordId);
  };
  return (
    <>
      <SubmitConfirmationModal
        visible={isSubmitModal}
        onClose={() => setSubmitModal(false)}
        onExit={() => handleAutosaveSurvey(false)}
        onComplete={handleSubmitSurvey}
      />
      {resultMessage ? (
        <FormWrap width="500px">
          <Row justify="space-between">
            <Col lg={24} md={24} sm={24} xs={24}>
              <div style={{ textAlign: "center", justifyContent: "center" }}>
                <CheckCircleOutlined style={{ fontSize: "40px" }} />
                <Title className="color-white" level={4}>
                  {resultMessage?.title}
                </Title>
                <p>
                  <span> {resultMessage?.subTitle}</span>
                </p>
                <Form.Item>
                  <Row justify="center">
                    <StyledButton
                      type="custom"
                      key={1}
                      onClick={() => navigate("/management/collect-data")}
                    >
                      Go to All Surveys
                    </StyledButton>
                  </Row>
                </Form.Item>
              </div>
            </Col>
          </Row>
        </FormWrap>
      ) : (
        <FormWrap width="100%" style={{ padding: 0 }}>
          <FormWrapper>
            {showImportDataModal && (
              <ImportedDataModal
                isSuccess
                visible={showImportDataModal}
                onClose={() => {
                  setShowImportDataModal(false);
                  const newState = {
                    ...(state?.taskDeploymentId && {
                      taskDeploymentId: state?.taskDeploymentId,
                    }),
                    ...(state?.metadataRecordId && {
                      metadataRecordId: state?.metadataRecordId,
                    }),
                  };
                  navigate(
                    `/management/collect-data/${
                      surveyStore.surveyInfo?.id
                    }/${encodeUrlName(
                      surveyStore.surveyInfo?.name.replace("/", " ")
                    )}/view-survey`,
                    { state: newState }
                  );
                }}
              />
            )}
            <Spin
              spinning={
                surveyStore.surveyLoading || surveyStore.surveySubmitting
              }
            >
              <Col span={24}>
                {surveyStore.surveySteps?.length > 0 && (
                  <Row>
                    <Col
                      span={size?.width >= 900 ? 17 : 24}
                      style={{ padding: "35px 40px" }}
                      className={`preview-left-part ${styles.leftPart}`}
                    >
                      <Drawer
                        className={`drawerCustom ${styles.drawer}`}
                        onClose={() => setShowInformationDrawer(false)}
                        visible={showInformationDrawer}
                        placement="right"
                      >
                        <Col>
                          {rightPartContent}
                          <Row
                            justify="space-between"
                            style={{ flexFlow: "nowrap" }}
                          >
                            <Col>
                              <StyledButton
                                className="margin-5"
                                type="custom"
                                onClick={() => setShowInformationDrawer(false)}
                              >
                                <ArrowLeftOutlined /> <span>Back</span>
                              </StyledButton>
                            </Col>
                          </Row>
                        </Col>
                      </Drawer>
                      <div className="user-flow-rightside-wrap">
                        {surveyStore.currentStepInfo && (
                          <>
                            {surveyStore.currentStepInfo?.survey_type ===
                              "data_sheets" &&
                              (surveyStore.currentStepInfo
                                ?.multiline_form_flag ? (
                                <Row justify="center">
                                  <Col span={24}>
                                    <DataSheetMultiLineForm
                                      validationIssuesId={validationIssuesId}
                                      onClickPrevious={onClickPrevious}
                                      onClickNext={onClickNext}
                                      setResultMessage={setResultMessage}
                                      completeSurvey={completeSurvey}
                                      openInfoDrawer={() =>
                                        setShowInformationDrawer(true)
                                      }
                                      handleUpdateTaskDeployment={
                                        handleUpdateTaskDeployment
                                      }
                                    />
                                  </Col>
                                </Row>
                              ) : (
                                <Row justify="center">
                                  <Col xs={24} sm={24} md={14}>
                                    <DataSheetSingleForm
                                      form={form}
                                      latestRecord={latestRecord}
                                      setResultMessage={setResultMessage}
                                      setSelectedHistoryVersion={
                                        setSelectedHistoryVersion
                                      }
                                      onClickPrevious={onClickPrevious}
                                      onClickNext={onClickNext}
                                      completeSurvey={completeSurvey}
                                      openDrawer={() =>
                                        setShowInformationDrawer(true)
                                      }
                                      handleUpdateTaskDeployment={
                                        handleUpdateTaskDeployment
                                      }
                                    />
                                  </Col>
                                </Row>
                              ))}

                            {surveyStore.currentStepInfo?.survey_type ===
                              "standards" && (
                              <Row justify="center">
                                <Col xs={24} sm={24} md={14}>
                                  <StandardsForm
                                    onClickPrevious={onClickPrevious}
                                    onClickNext={onClickNext}
                                    setResultMessage={setResultMessage}
                                    completeSurvey={completeSurvey}
                                    openDrawer={() =>
                                      setShowInformationDrawer(true)
                                    }
                                  />
                                </Col>
                              </Row>
                            )}
                            {surveyStore.currentStepInfo?.survey_type ===
                              "welcome_page" && (
                              <Row justify="center">
                                <Col xs={24} sm={24} md={14}>
                                  <WelcomePageForm
                                    onClickPrevious={onClickPrevious}
                                    onClickNext={onClickNext}
                                    completeSurvey={completeSurvey}
                                    openDrawer={() =>
                                      setShowInformationDrawer(true)
                                    }
                                  />
                                </Col>
                              </Row>
                            )}
                          </>
                        )}
                      </div>
                    </Col>

                    <Col
                      span={size?.width >= 900 ? 7 : 0}
                      className={`preview-right-part ${styles.rightPart}`}
                      style={{
                        padding: "15px 20px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      {rightPartContent}
                      <div>
                        <StyledButton
                          className="margin-5"
                          type="custom"
                          hoverbgcolor="transparent"
                          bgcolor="transparent"
                          bordercolor="transparent"
                          color="white"
                          onClick={() => handleAutosaveSurvey(false)}
                        >
                          <span>Exit</span>
                        </StyledButton>
                        <StyledButton
                          type="custom"
                          htmlType="submit"
                          onClick={() => setSubmitModal(true)}
                        >
                          Submit Survey
                        </StyledButton>
                        <StyledButton
                          className="margin-5"
                          type="custom"
                          hoverbgcolor="transparent"
                          bgcolor="transparent"
                          bordercolor="transparent"
                          color="white"
                          onClick={() => handleAutosaveSurvey(false)}
                        >
                          <span>Save and Exit</span>
                        </StyledButton>
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
            </Spin>
          </FormWrapper>
        </FormWrap>
      )}
    </>
  );
});

export default SurveyView;
