import React, { useState, useEffect, useCallback, useContext } from "react";
  useCallback
import { Col, message, Row, Input, Typography, Select, Switch, Dropdown, Menu, Checkbox, Spin, Radio } from "antd";
import { API, Auth } from "aws-amplify";
import * as queries from "../../../../../graphql/queries";

import {useUserSettings} from "../../../../../hooks/useUserSettings";
import { AuthContext } from '../../../../../contexts/AuthContext';

import OverviewDataTable from "./Components/OverviewDataTable";

import Styled from 'styled-components';

import {
  FiltersContainer,
  FiltersWrap,
  PageWrap,
  FilterButtonsWrap,
  StyledButton,
  FormWrapper,
  CommonSelect,
  TableCommonMenu,
} from "../../../../../shared/commonStyles";

import {
  TableCustomMenu
} from "./styles"

import XLSX from "xlsx";

import {
  MenuOutlined,
  PlusSquareOutlined,
  MinusSquareOutlined,
  ExportOutlined,
  DownOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  CalendarOutlined,
  DatabaseOutlined,
  InsertRowRightOutlined,
} from '@ant-design/icons';

import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";

import { getCompanyInfo } from "../../../../../services/companyInfo";


import styles from "./../../Overview.module.scss";

import pivot_table_styles from "./PivotTable.module.scss";

import { round } from "lodash";
import { bellNumbersDependencies, count } from "mathjs";

import { doConversion } from "../../../../../services/dataSheet";
import { CommonAxisSettingsConstantLineStyle } from "devextreme-react/chart";

const { Text } = Typography;
const { Option } = Select;

const AutoExpandCustomSwitch = Styled(Switch)`
  &.ant-switch-checked {
    background-color: #A67DFB !important;
  }
`;

const flex_container_space_between = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  flexWrap: 'nowrap'
};


const DragHandle = sortableHandle(() => (
  <span style={{ cursor: 'grab' }}>
    <MenuOutlined />
  </span>
));

// this is the original code without sub menu
const SortableItem_old = sortableElement(({level, manageLevelsState, item_index, index, onToggleLevel, menuItemStyle, handle_level_move_up_click, handle_level_move_down_click}) => 
  <div>
    <Menu.Item key={level.entity_name} eventKey={level.entity_name} style={{ width: '100%', height: 'auto' }}>
        <div style={menuItemStyle}>
          <Checkbox
            checked={manageLevelsState.length > 0 && manageLevelsState[item_index]?.is_active}
            onChange={(e) => onToggleLevel(item_index, manageLevelsState, e)}
          >
            <span style={{ color: 'white' }}>{level.display_name}</span>
          </Checkbox>
          <span style={{ color: 'white', display:'flex', alignItems: 'center'}}>
            <span style={{ paddingRight: '5px'}}>
              {item_index+1}
            </span>
            <CaretUpOutlined
              onClick={() => handle_level_move_up_click(item_index)}
              style={{ fontSize: '14px', cursor: 'pointer' }}
            />
            <CaretDownOutlined
              onClick={() => handle_level_move_down_click(item_index)}
              style={{ fontSize: '14px', cursor: 'pointer' }}
            />
            <DragHandle />
          </span>
        </div>
    </Menu.Item>    
  </div>
);

// this is with submenu
const SortableItem = sortableElement(({level, manageLevelsState, item_index, index, onToggleLevel, menuItemStyle, handle_level_move_up_click, handle_level_move_down_click, onToggleSubMenu}) => 
  <div>
    <Menu.SubMenu key={level.entity_name} eventKey={level.entity_name} 
      title={
        <>
          <div style={menuItemStyle}>
          <Checkbox
            checked={manageLevelsState.length > 0 && manageLevelsState[item_index]?.is_active}
            onChange={(e) => onToggleLevel(item_index, manageLevelsState, e)}
          >
            <span style={{ color: 'white' }}>{level.display_name}</span>
          </Checkbox>
          <span style={{ color: 'white', display:'flex', alignItems: 'center'}}>
            <span style={{ paddingRight: '5px'}}>
              {item_index+1}
            </span>
            <CaretUpOutlined
              onClick={() => handle_level_move_up_click(item_index)}
              style={{ fontSize: '14px', cursor: 'pointer' }}
            />
            <CaretDownOutlined
              onClick={() => handle_level_move_down_click(item_index)}
              style={{ fontSize: '14px', cursor: 'pointer' }}
            />
            <DragHandle />            
          </span>
        </div>
        </>
      }
    >
      {level.sub_menu && level.sub_menu.map((i, submenu_index)=>{
        return <Menu.Item key={`${level.entity_name}_submenu_${submenu_index}`} style={menuItemStyle}>
          <Checkbox
            checked={manageLevelsState[item_index].sub_menu.length > 0 && manageLevelsState[item_index].sub_menu[submenu_index]?.is_active}
            onChange={(e) => onToggleSubMenu(item_index, manageLevelsState, submenu_index, e)}
          >
            <span style={{ color: 'white' }}>{i.itemName}</span>
          </Checkbox>  
        </Menu.Item>
      })}

    </Menu.SubMenu>
  </div>
);

// Sortable List Container Component (wraps the entire Menu)
const SortableMenu = sortableContainer(({ levels, onToggleLevel, menuItemStyle, handle_reset_manage_levels, auto_expand_levels_toggle, auto_expand_levels, handle_level_move_down_click, handle_level_move_up_click, onToggleSubMenu}) => (
  <div className={pivot_table_styles.menu_overrides}>
    <TableCustomMenu style={{ borderRight: '#524d64 1px solid' }}>
      <Menu.Item key="auto_expand_levels" style={{ height: '22px', marginBottom: '10px', marginTop: '10px'}}>
        <div style={flex_container_space_between}>
          <div style={{ width: '100px', display: 'flex', justifyContent: 'flex-start', flexWrap: 'nowrap' }}>
            <AutoExpandCustomSwitch 
              onChange={auto_expand_levels_toggle}  // Pass the checked value directly
              checked={auto_expand_levels}  // Bind the state to control the Switch
            />
            <span style={{ color: 'white', paddingLeft: '10px', lineHeight: '22px' }}>Auto Expand Levels</span>
          </div>
          <div style={{ lineHeight: '22px', height: '22px'}} onClick={handle_reset_manage_levels}>Reset</div>
        </div>
      </Menu.Item>
    {levels.map((level, index) => (
      <SortableItem
        key={level.entity_name || index} // React key for each item
        level={level}
        manageLevelsState={levels} // This index is required by react-sortable-hoc
        index={index} // This index is required by react-sortable-hoc
        item_index={index} // item index
        onToggleLevel={onToggleLevel}
        menuItemStyle={menuItemStyle}
        handle_level_move_down_click={handle_level_move_down_click}
        handle_level_move_up_click={handle_level_move_up_click}

        onToggleSubMenu={onToggleSubMenu}
      />
    ))}
    </TableCustomMenu>
  </div>
));

const BreakdownPivotTable = () => {
  const [pivotDataList, setPivotDataList] = useState([]);
  const [loadingTableData, setLoadingTableData] = useState(true);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [categories, setCategories] = useState([]);
  const [manageLevels, setManageLevels] = useState([]);
  const [origManageLevels, setOrigManageLevels] = useState([]);
  const [isManageLevelsModalVisible, setManageLevelsModalVisible] = useState(false);
  const [isManageColumnsModalVisible, setManageColumnsModalVisible] = useState(false);
  const [isManageYearsDropdownVisible, setManageYearsDropdownVisible] = useState(false);
  const [autoExpandLevels, setAutoExpandLevels] = useState(false);
  const [conversionFactor, setConversionFactor] = useState(1);
  const [companyDetails, setCompanyDetails] = useState(false);
  const [currentGroup, setCurrentGroup] = useState("");

  const [consumptionAmountIndicatorType, setConsumptionAmountIndicatorType] = useState("location-based");

  const {auth} = useContext(AuthContext);
  const {createUserSettings,updateUserSettings,getUserSettings} = useUserSettings();

  const onClickHandleManageYearsDropDown = () => {
    setManageYearsDropdownVisible(!isManageYearsDropdownVisible);
  };

  // Function to handle input focus and toggle the modal
  const onClickManageLevels = () => {
    setManageLevelsModalVisible(!isManageLevelsModalVisible);
  };

  const onClickHandleManageColumnsPopUpMenu = () => {
    setManageColumnsModalVisible(!isManageColumnsModalVisible);
  };

  const [manageLevelsState, setManageLevelsState] = useState([]);
  // stores original data returned from api
  const [pivotTableData, setPivotTableData] = useState([]);

  const [filter, setFilter] = useState({
    title: "",
    category: "",
  });
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

  const [columnVisibility, setColumnVisibility] = useState({
    level: true,
    row_labels: true,
    sum_of_consumption: true,
    sum_of_total_location: true,
    scope_1_location: false,
    scope_2_location: false,
    scope_3_location: false,
    sum_of_total_market: true,
    scope_1_market: false,
    scope_2_market: false,
    scope_3_market: false,
    sum_of_total_uncategorized: true,
    scope_1_uncategorized: false,
    scope_2_uncategorized: false,
    scope_3_uncategorized: false,
  });

  const handleLevelVisibility = (checked, event) => {
    console.log('handle level visibility');
    setColumnVisibility(prevVisibility => ({
      ...prevVisibility,
      ['level']: !prevVisibility['level'], // Toggle the current valu
    }));
    
  };


  // has list of items that are filtered out
  let arr_filtered_out_level_items = [];

  // Get the current year
  const currentYear = new Date().getFullYear();


  // Generate an array of the past 7 years
  const pastYears = Array.from({ length: 7 }, (_, i) => currentYear - i);

  const tempManageYears = {};
  pastYears.forEach(year => {
    tempManageYears[year] = true;
  });

  const [manageYearsVisibility, setManageYearsVisibility] = useState(tempManageYears);

  const handleManageYearsVisibility = (event, year) => {
    console.log('handle manage Years visibility visibility');
    setManageYearsVisibility(prevManageYearsVisibility => ({
      ...prevManageYearsVisibility,
      [year]: !prevManageYearsVisibility[year], // Toggle the current value
    }));
  };

const isEmpty = (obj) => {
  return Object.entries(obj).length === 0;
};


///save user settings for the managelevels, columns, and year (basically all the settings from the 3 dropdowns)
// rule might be too general and update way too much
useEffect(()=>{
  let pivot_table_user_settings = {};
  if (currentGroup && !isEmpty(manageLevelsState) && !isEmpty(manageYearsVisibility) && !isEmpty(columnVisibility)) {
    pivot_table_user_settings['manageYearsVisibility'] = manageYearsVisibility;
    pivot_table_user_settings['manageLevelsState'] = manageLevelsState;
    pivot_table_user_settings['columnVisibility'] = columnVisibility;
    pivot_table_user_settings['consumptionAmountIndicatorType'] = consumptionAmountIndicatorType;
    onSettingsChanged("pivot-table-user-settings-" + currentGroup, pivot_table_user_settings);
  }
},[manageYearsVisibility, manageLevelsState, columnVisibility, consumptionAmountIndicatorType])



const onSettingsChanged = async (settingsType, settingsValue) => {
  try {
    const username = auth['auth']['accessToken']['payload']['username'];
    
    let payload = {
      owner: username,
      settingName: settingsType,
      settingValue: JSON.stringify(settingsValue)
    };

    let settingsExists = await getUserSettings(payload);

    console.log('---settings exists?---', settingsExists, payload);

    if(settingsExists){
      
      console.log('---updating Settings---');
      await updateUserSettings(payload);

    } else {

      console.log('---Adding Settings---');
      await createUserSettings(payload);

    }
    
  } catch (error) {
    console.log("Somethng went wrong!");
  }
};

//////////////////////////////////////

//////////////////////////////////////



  const menuItemStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    flexWrap: 'nowrap'
  };

  let round_to_decimal_places = 2;


  const generateExportReport = () => {
    try {

      const arr_data = [];
      const wb = XLSX.utils.book_new();
      pivotDataList.forEach(item => {
          // Add the value of the specified field to the Set
          delete item['current_combinations'];
          delete item['row_labels'];
          delete item['is_expanded'];
          delete item['entity_name'];
          arr_data.push(item);
          //item.current_combinations.foreach(combinat => {
          //});
          //item.row_labels = 
      });
      const ws = XLSX.utils.json_to_sheet(arr_data);

      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "pivot-table-breakdown.xlsx");
    } catch (error) {
      console.log("Error while generating Excel Report!", error);
    }
  };


  function getUniqueValues(data, field) {

    if (!Array.isArray(data)) {
        return [];
    }

    // Create a Set to store unique values
    const uniqueValues = new Set();

    // Loop through the data array
    data.forEach(item => {
        // Add the value of the specified field to the Set
        uniqueValues.add(item[field]);
    });

    // Convert the Set back to an array, sort it in ascending order, and return it
    return Array.from(uniqueValues).sort((a, b) => (a > b ? 1 : -1));
  }

  function filterDataByCriteria(data, criteria) {
    // Filter the data array based on the criteria
    return data.filter(item => {
        // Check if all key-value pairs in the criteria match the current item
        return Object.keys(criteria).every(key => item[key] === criteria[key]);
    });
  }







// Helper function to generate combinations of array elements
  const getCombinations = (array) => {
    const results = [[]];
    for (const value of array) {
      const copy = [...results]; // See note below.
      for (const prefix of copy) {
        results.push(prefix.concat(value));
      }
    }
    return results.slice(1); // Remove the empty set
  };


  const handle_reset_manage_levels = () => {
    const updatedLevels = [...origManageLevels].map(level => ({
      ...level,
      is_active: true,
    }));

    arr_filtered_out_level_items = [];

    setManageLevelsState(updatedLevels); // Update state with new values
    process_pivot_table_root(conversionFactor,pivotTableData, updatedLevels);
    setAutoExpandLevels(true);


    let pivot_table_user_settings = {};
    if (currentGroup && !isEmpty(updatedLevels) && !isEmpty(manageYearsVisibility) && !isEmpty(columnVisibility)) {
      pivot_table_user_settings['manageYearsVisibility'] = manageYearsVisibility;
      pivot_table_user_settings['manageLevelsState'] = updatedLevels;
      pivot_table_user_settings['columnVisibility'] = columnVisibility;
      pivot_table_user_settings['consumptionAmountIndicatorType'] = "l";
      onSettingsChanged("pivot-table-user-settings-" + currentGroup, pivot_table_user_settings);
    }

    setConsumptionAmountIndicatorType("l");
  };

  const handle_level_move_up_click = (current_index) => {
    const updatedLevels = [...manageLevelsState];
  
    if (current_index > 0) {
      // Swap the current item with the one above it
      [updatedLevels[current_index], updatedLevels[current_index - 1]] = 
      [updatedLevels[current_index - 1], updatedLevels[current_index]];

      [updatedLevels[current_index].level, updatedLevels[current_index - 1].level] = 
      [updatedLevels[current_index - 1].level, updatedLevels[current_index].level];
      
      setManageLevelsState(updatedLevels); // Update state with new values
      let newLevels = [...updatedLevels];
      newLevels = newLevels.filter(level => level.is_active !== false);
      process_pivot_table_root(conversionFactor,pivotTableData, newLevels);
      console.log(`Moved item ${current_index} up`);
    } else {
      console.log(`Cannot move item ${current_index} up: it's already at the top`);
    }
  };

  const handle_level_move_down_click = (current_index) => {
    const updatedLevels = [...manageLevelsState];
    if (current_index < updatedLevels.length - 1) {
      // Swap the current item with the one below it
      [updatedLevels[current_index], updatedLevels[current_index + 1]] = 
      [updatedLevels[current_index + 1], updatedLevels[current_index]];

      [updatedLevels[current_index].level, updatedLevels[current_index + 1].level] = 
      [updatedLevels[current_index + 1].level, updatedLevels[current_index].level];
      
      setManageLevelsState(updatedLevels); // Update state with new values
      // Loop through newLevels and unset (filter out) items where is_active is false
      let newLevels = [...updatedLevels];
      newLevels = newLevels.filter(level => level.is_active !== false);
      process_pivot_table_root(conversionFactor,pivotTableData, newLevels);
      console.log(`Moved item ${current_index} down`);
    } else {
      console.log(`Cannot move item ${current_index} down: it's already at the bottom`);
    }
  };

  const auto_expand_levels_toggle = (checked) => {
    setAutoExpandLevels(checked);
  };

  const onToggleLevel = (index, manageLevelsState, event) => {
    console.log('toggle level manageLevelsState')
    console.log(manageLevelsState)
    console.log('index is ' + index)
    //console.log(option)
    if(manageLevelsState && manageLevelsState[index]) {
      const updatedLevels = [...manageLevelsState];
      if(updatedLevels && updatedLevels[index]) {
        updatedLevels[index].is_active = event.target.checked;
        setManageLevelsState(updatedLevels); // Update state with new values

        let newLevels = [...updatedLevels];

        // Loop through newLevels and unset (filter out) items where is_active is false
        newLevels = newLevels.filter(level => level.is_active !== false);

        process_pivot_table_root(conversionFactor, pivotTableData, newLevels);
      }
    } else {
        console.log('manage levels state is empty')
    }


  };

  //method for submenu action for enable or disable is_active toggle
  const onToggleSubMenu = (levelIndex, manageLevelsState, subMenuIndex, event)=>{
    console.log('---submenu log---', levelIndex, manageLevelsState, subMenuIndex, event.target.checked);
    console.log('---submenu status--', manageLevelsState[levelIndex].sub_menu[subMenuIndex].itemName, event.target.checked)

    //update the submenu item checkbox status
    let updatedState = [...manageLevelsState];
    updatedState[levelIndex].sub_menu[subMenuIndex].is_active = event.target.checked;
    setManageLevelsState(updatedState)

    /*
    let arr_temp_filtered_out_level_items = [];

    let updated_state = [...manageLevelsState];
    updated_state[levelIndex].sub_menu[subMenuIndex].is_active = event.target.checked;
    for (let j = 0; j < updated_state.length; j++) {
      let level = updated_state[j];

      let temp_level = level;
      let temp_sub_menu_items = [];
      
      // Loop through each sub_menu in the current level
      for (let k = 0; k < level.sub_menu.length; k++) {
        let subMenuItem = level.sub_menu[k];
        // Check if the itemName matches some condition (e.g., a specific uniqueValue)
        // and ensure the item is active
        if (subMenuItem.is_active === false) {
          temp_sub_menu_items.push(subMenuItem);
        }
      }
      if (temp_sub_menu_items.length > 0) {
        temp_level.sub_menu = temp_sub_menu_items;
        arr_temp_filtered_out_level_items.push(temp_level);

      }
    }
    //arr_filtered_out_level_items = [...arr_temp_filtered_out_level_items];
    arr_filtered_out_level_items = arr_temp_filtered_out_level_items;
    console.log('filtered out level items');
    console.log(arr_filtered_out_level_items);
    */

    let newLevels = [...updatedState];
    newLevels = newLevels.filter(level => level.is_active !== false);


    process_pivot_table_root(conversionFactor,pivotTableData, newLevels);

  }

  const getConversionFactor = async (emissions_unit) => {
    const factor = await doConversion(1, "kg", emissions_unit);
    setConversionFactor(factor);
    return factor;
  };

  const onChangeLevel = (option) => {
    console.log(option)
    /*
    setInitialValues((oldState) => ({
      ...oldState,
      is_carbon: option.target.checked,
    }));
    */
  };



  //const handle_pivot_table_row_click = (n, pivotDataList, setPivotDataList) => {
  //const handle_pivot_table_row_click = useCallback((n) => {
  //const handle_pivot_table_row_click = (n, pivotDataList, setPivotDataList) => {
  const handle_pivot_table_row_click = (record) => {
    console.log('pivot table row click')
    let n = record.id;
    //console.log('n is ' + n)
    if (!record.is_expanded) {
      handle_pivot_table_row_expand(record);
    } else {
      handle_pivot_table_row_collapse(n, record);
    }
    /*
    console.log(record);
    console.log(pivotDataList);
    console.log(pivotDataList);
    const newPivotDataList = [...pivotDataList];
    console.log(newPivotDataList[n])
    newPivotDataList.push(newPivotDataList[n]);
    setPivotDataList(newPivotDataList);
    */
  };


  //function handle_pivot_table_row_collapse(n, current_level, pivotDataList, setPivotDataList) {
  function handle_pivot_table_row_collapse(n, record) {
      let current_level = record.level;
      // Create a copy of the pivotDataList to avoid direct mutation
      let updatedPivotDataList = [...pivotDataList];

      // Start at index n+1
      let i = n + 1;

      // Loop through the list starting from index n+1
      while (i < updatedPivotDataList.length) {
          const row = updatedPivotDataList[i];

          // If row.level is greater than current_level, delete it
          if (row.level > current_level) {
              updatedPivotDataList.splice(i, 1); // Remove the item at index i
          } else if (row.level === current_level) {
              // Stop if we encounter a row with the same level
              break;
          } else {
              // If the level is lower, just move to the next item
              i++;
          }
      }

      // Set is_expanded to false for the row at index n
      updatedPivotDataList[n].is_expanded = false;
      updatedPivotDataList[n].row_labels = generate_row_label(updatedPivotDataList[n], null, true);

      // Reindex the array so that all ids are sequential
      const reindexedPivotDataList = updatedPivotDataList.map((item, index) => ({
          ...item,
          id: index, // Sequentially reindex ids
      }));

      // Update the state with the new pivotDataList
      setPivotDataList(reindexedPivotDataList);
  }

  // Function to handle sorting
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const updatedLevels = [...manageLevelsState];
    
    // Remove the item at oldIndex and store it
    const [movedItem] = updatedLevels.splice(oldIndex, 1);
    
    // Insert the moved item at newIndex
    updatedLevels.splice(newIndex, 0, movedItem);
    
    // After the move, adjust the 'level' field based on the new order
    updatedLevels.forEach((levelItem, index) => {
      levelItem.level = index; // Set 'level' field to match the new index
    });

    // Update the state with reordered items
    setManageLevelsState(updatedLevels);

    // Filter out inactive levels
    let newLevels = [...updatedLevels];
    newLevels = newLevels.filter(level => level.is_active !== false);

    // Process the pivot table with updated levels
    process_pivot_table_root(conversionFactor, pivotTableData, newLevels);
  };

  const getHelperDimensions = ({ node }) => {
    const rect = node.getBoundingClientRect();
    return {
      width: rect.width,
      height: rect.height,
    };
  };

  const generate_row_label = (record, manage_levels, is_collapse = false) => {
    // if is collapse = true then manage_levels could be null and we don't need it or use it here
    // just assumep if generating row label for a record that is being collapsed its always PlusSquaredOutlined
    //console.log('passing into generate_row_labels')
    //console.log(record);
    const level_padding_left = (record.level-1)*20;
    const is_expanded = record.is_expanded;
    return (
      <>
        <span
          style={{ 
            cursor: 'pointer', 
            color: '#ffffff', 
            paddingLeft: `${level_padding_left}px` // Assuming you want to add 'px' as the unit
          }}
          >
            { !is_collapse && (record.level < count(manage_levels)) && is_expanded &&
              <MinusSquareOutlined style={{ color: '#a68dfb', paddingRight: '5px' }} />
            } 
            {(is_collapse || ((record.level < count(manage_levels)) && !is_expanded)) &&
              <PlusSquareOutlined style={{ color: '#a68dfb', paddingRight: '5px' }} />
            } 
            {record.column_display_name}
        </span>
      </>
    );
  }

  // has logic to add up based on savedstate settings
  const do_consumption_emission_column_logic_calulations = ( filteredResults) => {
    let totalConsumptionAmount = 0;
    let sum_of_total_all_scopes_location = 0;
    let scope_1_emission_amount_location = 0;
    let scope_2_emission_amount_location = 0;
    let scope_3_emission_amount_location = 0;
    let sum_of_total_all_scopes_market = 0;
    let scope_1_emission_amount_market = 0;
    let scope_2_emission_amount_market = 0;
    let scope_3_emission_amount_market = 0;
    let sum_of_total_all_scopes_uncategorized = 0;
    let scope_1_emission_amount_uncategorized = 0;
    let scope_2_emission_amount_uncategorized = 0;
    let scope_3_emission_amount_uncategorized = 0;

    // Loop through the filtered results to sum up total_consumption_amount
    filteredResults.forEach(item => {
      if (item.indicatorType !== undefined && item.indicatorType === "l") {
        sum_of_total_all_scopes_location += item.total_emission;
        if (item.scope === "scope_3") {
          scope_3_emission_amount_location += item.total_emission;
        } else if (item.scope === "scope_2") {
          scope_2_emission_amount_location += item.total_emission;

        } else {
          // default to scope 1
          scope_1_emission_amount_location += item.total_emission;
        }
      } else if (item.indicatorType !== undefined && item.indicatorType === "m") {
        sum_of_total_all_scopes_market += item.total_emission;
        if (item.scope === "scope_3") {
          scope_3_emission_amount_market += item.total_emission;
        } else if (item.scope === "scope_2") {
          scope_2_emission_amount_market += item.total_emission;

        } else {
          // default to scope 1
          scope_1_emission_amount_market += item.total_emission;
        }
      } else {
        sum_of_total_all_scopes_uncategorized += item.total_emission;
        if (item.scope === "scope_3") {
          scope_3_emission_amount_uncategorized += item.total_emission;
        } else if (item.scope === "scope_2") {
          scope_2_emission_amount_uncategorized += item.total_emission;

        } else {
          // default to scope 1
          scope_1_emission_amount_uncategorized += item.total_emission;
        }


      }
      if (
        (consumptionAmountIndicatorType == "location-based" && item.indicatorType == "l")
        || (consumptionAmountIndicatorType == "market-based" && item.indicatorType == "m")
        || (consumptionAmountIndicatorType == "uncategorized" && (item.indicatorType !== "m" && item.indicatorType !== "l"))
      ) {
        totalConsumptionAmount += item.total_consumption_amount;
      }
    });

    const current_numbers = {};

    current_numbers.totalConsumptionAmount = totalConsumptionAmount;
    current_numbers.sum_of_total_all_scopes_location = sum_of_total_all_scopes_location;
    current_numbers.scope_1_emission_amount_location = scope_1_emission_amount_location;
    current_numbers.scope_2_emission_amount_location = scope_2_emission_amount_location;
    current_numbers.scope_3_emission_amount_location = scope_3_emission_amount_location;
    current_numbers.sum_of_total_all_scopes_market = sum_of_total_all_scopes_market;
    current_numbers.scope_1_emission_amount_market = scope_1_emission_amount_market;
    current_numbers.scope_2_emission_amount_market = scope_2_emission_amount_market;
    current_numbers.scope_3_emission_amount_market = scope_3_emission_amount_market;
    current_numbers.sum_of_total_all_scopes_uncategorized = sum_of_total_all_scopes_uncategorized;
    current_numbers.scope_1_emission_amount_uncategorized = scope_1_emission_amount_uncategorized;
    current_numbers.scope_2_emission_amount_uncategorized = scope_2_emission_amount_uncategorized;
    current_numbers.scope_3_emission_amount_uncategorized = scope_3_emission_amount_uncategorized;
    return current_numbers;
  }

  // subtracts year contributions for unwanted years
  // take the same criteria and subtract with the criteria of the same combination + the unwated year
  const subtract_contributions_for_deselected_years = (current_numbers, pivot_table_data, manage_levels, current_level, temp_criteria) => {
    let criteria = {...temp_criteria}
    /*
    console.log('inside subtract contributions')
    console.log('doing for crteria')
    console.log(criteria)
    */
    const year_level = manage_levels.find(item => item.is_year_field === true);


    if (year_level) {
      /*
      console.log('year_level is ' + year_level.level)
      console.log('current level is ' + current_level.level)
      console.log('current level obj');
      console.log(current_level)
      */
      if (current_level.level >= year_level.level) {
        // do not do any subtract since we already have a different year it doesn't make sense to subtract
        return current_numbers;
      }
    } else {
      // we don't want to subtract for year if year level is not enabled
      return current_numbers;
    }

    Object.entries(manageYearsVisibility).forEach(([key, value]) => {
      let filteredResults = [];

      if (value === false) {

        criteria['year'] = Number(key);  // Add the deselected year to the criteria
        //console.log('doing filtered search')
        //console.log(pivot_table_data)
        filteredResults = filterDataByCriteria(pivot_table_data, criteria);  // Filter the data based on criteria

        // Loop through the filtered results to sum up total_consumption_amount
        const obj_temp_amounts = do_consumption_emission_column_logic_calulations(filteredResults);
        if (obj_temp_amounts.sum_of_total_all_scopes_location > 0) {
          console.log('found subtract for year filter')
          console.log('check deselected field')
          console.log('key: ' + key + ' : value ' + value)
          console.log('current level is: ' + current_level.level + ' and year level is ' + year_level.level);
          console.log('subtracting from location emission: ' + obj_temp_amounts.sum_of_total_all_scopes_location)
          console.log(criteria)
          console.log('results:')
          console.log(filteredResults);
        }

        // subtract based on contribution
        current_numbers.totalConsumptionAmount -= obj_temp_amounts.totalConsumptionAmount;
        current_numbers.sum_of_total_all_scopes_location -= obj_temp_amounts.sum_of_total_all_scopes_location;
        current_numbers.scope_1_emission_amount_location -= obj_temp_amounts.scope_1_emission_amount_location;
        current_numbers.scope_2_emission_amount_location -= obj_temp_amounts.scope_2_emission_amount_location;
        current_numbers.scope_3_emission_amount_location -= obj_temp_amounts.scope_3_emission_amount_location;
        current_numbers.sum_of_total_all_scopes_market -= obj_temp_amounts.sum_of_total_all_scopes_market;
        current_numbers.scope_1_emission_amount_market -= obj_temp_amounts.scope_1_emission_amount_market;
        current_numbers.scope_2_emission_amount_market -= obj_temp_amounts.scope_2_emission_amount_market;
        current_numbers.scope_3_emission_amount_market -= obj_temp_amounts.scope_3_emission_amount_market;
        current_numbers.sum_of_total_all_scopes_uncategorized -= obj_temp_amounts.sum_of_total_all_scopes_uncategorized;
        current_numbers.scope_1_emission_amount_uncategorized -= obj_temp_amounts.scope_1_emission_amount_uncategorized;
        current_numbers.scope_2_emission_amount_uncategorized -= obj_temp_amounts.scope_2_emission_amount_uncategorized;
        current_numbers.scope_3_emission_amount_uncategorized -= obj_temp_amounts.scope_3_emission_amount_uncategorized;
      }
    });
    return current_numbers;
  }

  // takes 2 array of object and checks if any results in one is in the second
  const filterUniqueResultsDeepComplex = (filteredResults, alreadyFilteredResults) => {
    // Helper function to check if two objects are deeply equal
    const isEqual = (obj1, obj2) => {
      return Object.keys(obj1).every(key => obj1[key] === obj2[key]);
    };

    // Filter out items from filteredResults that are already in alreadyFilteredResults
    return filteredResults.filter(filteredItem => {
      return !alreadyFilteredResults.some(alreadyFilteredItem => 
        isEqual(filteredItem, alreadyFilteredItem)
      );
    });
  };


  // subtracts year contributions for unwanted years
  // take the same criteria and subtract with the criteria of the same combination + the unwated year
  const subtract_contributions_for_deselected_years_v2 = (already_filtered_results, current_numbers, pivot_table_data, manage_levels, current_level, temp_criteria) => {
    let criteria = {...temp_criteria}
    /*
    console.log('inside subtract contributions')
    console.log('doing for crteria')
    console.log(criteria)
    */
    const year_level = manage_levels.find(item => item.is_year_field === true);


    if (year_level) {
      /*
      console.log('year_level is ' + year_level.level)
      console.log('current level is ' + current_level.level)
      console.log('current level obj');
      console.log(current_level)
      */
      if (current_level.level >= year_level.level) {
        // do not do any subtract since we already have a different year it doesn't make sense to subtract
        return current_numbers;
      }
    } else {
      // we don't want to subtract for year if year level is not enabled
      return current_numbers;
    }

    Object.entries(manageYearsVisibility).forEach(([key, value]) => {
      let filteredResults = [];

      if (value === false) {

        criteria['year'] = Number(key);  // Add the deselected year to the criteria
        //console.log('doing filtered search')
        //console.log(pivot_table_data)
        const filteredResults = filterDataByCriteria(pivot_table_data, criteria);  // Filter the data based on criteria

        const newResults = filterUniqueResultsDeepComplex(filteredResults, already_filtered_results);

        // Loop through the filtered results to sum up total_consumption_amount
        const obj_temp_amounts = do_consumption_emission_column_logic_calulations(newResults);
        if (obj_temp_amounts.sum_of_total_all_scopes_location > 0) {
          console.log('found subtract for year filter')
          console.log('check deselected field')
          console.log('key: ' + key + ' : value ' + value)
          console.log('current level is: ' + current_level.level + ' and year level is ' + year_level.level);
          console.log('subtracting from location emission: ' + obj_temp_amounts.sum_of_total_all_scopes_location)
          console.log(criteria)
          console.log('results:')
          console.log(filteredResults);
        }

        // subtract based on contribution
        current_numbers.totalConsumptionAmount -= obj_temp_amounts.totalConsumptionAmount;
        current_numbers.sum_of_total_all_scopes_location -= obj_temp_amounts.sum_of_total_all_scopes_location;
        current_numbers.scope_1_emission_amount_location -= obj_temp_amounts.scope_1_emission_amount_location;
        current_numbers.scope_2_emission_amount_location -= obj_temp_amounts.scope_2_emission_amount_location;
        current_numbers.scope_3_emission_amount_location -= obj_temp_amounts.scope_3_emission_amount_location;
        current_numbers.sum_of_total_all_scopes_market -= obj_temp_amounts.sum_of_total_all_scopes_market;
        current_numbers.scope_1_emission_amount_market -= obj_temp_amounts.scope_1_emission_amount_market;
        current_numbers.scope_2_emission_amount_market -= obj_temp_amounts.scope_2_emission_amount_market;
        current_numbers.scope_3_emission_amount_market -= obj_temp_amounts.scope_3_emission_amount_market;
        current_numbers.sum_of_total_all_scopes_uncategorized -= obj_temp_amounts.sum_of_total_all_scopes_uncategorized;
        current_numbers.scope_1_emission_amount_uncategorized -= obj_temp_amounts.scope_1_emission_amount_uncategorized;
        current_numbers.scope_2_emission_amount_uncategorized -= obj_temp_amounts.scope_2_emission_amount_uncategorized;
        current_numbers.scope_3_emission_amount_uncategorized -= obj_temp_amounts.scope_3_emission_amount_uncategorized;
      }
    });
    return current_numbers;
  }

  // subtracts contributions for unwanted items from manage levels
  // function will do a criteria search for this item based on entity name and filtered out criteria
  // and add from current criteria and subtract all numbers from that
  // call this funciton only on filtered out items that have levels that are higher than current level we are examining
  const subtract_contributions_for_filtered_out_item = (alreadyFilteredResults, entity_name, filtered_out_criteria, current_numbers, pivot_table_data, manage_levels, current_level, temp_criteria) => {
    let criteria = {...temp_criteria}
    let filteredResults = [];

    console.log('entity nam eis ')
    console.log(entity_name)

    //console.log('doing filtered search')
    criteria[entity_name] = filtered_out_criteria;  // Add the filtered out criteria
    //console.log(pivot_table_data)
    console.log('filtered out item criteria')
    console.log(criteria)
    filteredResults = filterDataByCriteria(pivot_table_data, criteria);  // Filter the data based on criteria
    console.log('results:')
    console.log(filteredResults);
    // filter out results just in case we don't want to be subtracing the same amounts
    const newResults = filterUniqueResultsDeepComplex(filteredResults, alreadyFilteredResults);

    let obj_temp_amounts = do_consumption_emission_column_logic_calulations(newResults);

    // subtract based on contribution
    current_numbers.totalConsumptionAmount -= obj_temp_amounts.totalConsumptionAmount;
    current_numbers.sum_of_total_all_scopes_location -= obj_temp_amounts.sum_of_total_all_scopes_location;
    current_numbers.scope_1_emission_amount_location -= obj_temp_amounts.scope_1_emission_amount_location;
    current_numbers.scope_2_emission_amount_location -= obj_temp_amounts.scope_2_emission_amount_location;
    current_numbers.scope_3_emission_amount_location -= obj_temp_amounts.scope_3_emission_amount_location;
    current_numbers.sum_of_total_all_scopes_market -= obj_temp_amounts.sum_of_total_all_scopes_market;
    current_numbers.scope_1_emission_amount_market -= obj_temp_amounts.scope_1_emission_amount_market;
    current_numbers.scope_2_emission_amount_market -= obj_temp_amounts.scope_2_emission_amount_market;
    current_numbers.scope_3_emission_amount_market -= obj_temp_amounts.scope_3_emission_amount_market;
    current_numbers.sum_of_total_all_scopes_uncategorized -= obj_temp_amounts.sum_of_total_all_scopes_uncategorized;
    current_numbers.scope_1_emission_amount_uncategorized -= obj_temp_amounts.scope_1_emission_amount_uncategorized;
    current_numbers.scope_2_emission_amount_uncategorized -= obj_temp_amounts.scope_2_emission_amount_uncategorized;
    current_numbers.scope_3_emission_amount_uncategorized -= obj_temp_amounts.scope_3_emission_amount_uncategorized;


    return {current_numbers, newResults };
  }

  // subtracts year contributions for unwanted items from manage levels
  const manage_and_subtract_level_items_that_are_filtered_out = (current_numbers, pivot_table_data, manage_levels, current_level, temp_criteria) => {
    let criteria = {...temp_criteria}
    //console.log('doing for crteria')
    //console.log(criteria)
    // neeed to loop through all items that have been filtered out and subtract contribution of below
    
    let tempAlreadyFilteredResults = [];
    //if (arr_filtered_out_level_items.length > 0) {
    //  for (let j = 0; j < arr_filtered_out_level_items.length; j++) {
    if (manage_levels.length) {
      for (let j = 0; j < manage_levels.length; j++) {
        let level = manage_levels[j];

        if (current_level.level >= level.level) {
          // no need to subtract if the current level is greater or equal than the looped through manage_levels
          continue;
        }
        const entity_name = level.entity_name;
        
        // Loop through each sub_menu in the current level
        if (level && level.sub_menu && level.sub_menu.length) {
          for (let k = 0; k < level.sub_menu.length; k++) {
            let subMenuItem = level.sub_menu[k];
            const filtered_out_criteria = subMenuItem.itemName;

            // Check if the itemName matches some condition (e.g., a specific uniqueValue)
            // and ensure the item is active
            if (subMenuItem.is_active === false) {
              // Perform your logic here for the active subMenuItem
              let obj_temp_amounts = {};
              const obj = subtract_contributions_for_filtered_out_item(tempAlreadyFilteredResults, entity_name, filtered_out_criteria, current_numbers, pivot_table_data, manage_levels, current_level, criteria);
              obj_temp_amounts = obj.current_numbers;

              const newResults = obj.newResults;
              //const newResults = filterUniqueResultsDeepComplex(obj.filteredResults, tempAlreadyFilteredResults);

              tempAlreadyFilteredResults = tempAlreadyFilteredResults.concat(newResults);
              
              if (obj_temp_amounts.sum_of_total_all_scopes_location > 0) {
                console.log('inside subtract contributions for filtered items')
                console.log('current level is: ' + current_level.level + ' and looped level for filter is ' + level.level);
                console.log('subtracting from location emission: ' + obj_temp_amounts.sum_of_total_all_scopes_location)
                console.log(`filited out item found: ${subMenuItem.itemName}`);
                console.log('entity name is')
                console.log(entity_name)
                console.log('doing for crteria')
                console.log(criteria)

              }
              
              current_numbers.totalConsumptionAmount = obj_temp_amounts.totalConsumptionAmount;
              current_numbers.sum_of_total_all_scopes_location = obj_temp_amounts.sum_of_total_all_scopes_location;
              current_numbers.scope_1_emission_amount_location = obj_temp_amounts.scope_1_emission_amount_location;
              current_numbers.scope_2_emission_amount_location = obj_temp_amounts.scope_2_emission_amount_location;
              current_numbers.scope_3_emission_amount_location = obj_temp_amounts.scope_3_emission_amount_location;
              current_numbers.sum_of_total_all_scopes_market = obj_temp_amounts.sum_of_total_all_scopes_market;
              current_numbers.scope_1_emission_amount_market = obj_temp_amounts.scope_1_emission_amount_market;
              current_numbers.scope_2_emission_amount_market = obj_temp_amounts.scope_2_emission_amount_market;
              current_numbers.scope_3_emission_amount_market = obj_temp_amounts.scope_3_emission_amount_market;
              current_numbers.sum_of_total_all_scopes_uncategorized = obj_temp_amounts.sum_of_total_all_scopes_uncategorized;
              current_numbers.scope_1_emission_amount_uncategorized = obj_temp_amounts.scope_1_emission_amount_uncategorized;
              current_numbers.scope_2_emission_amount_uncategorized = obj_temp_amounts.scope_2_emission_amount_uncategorized;
              current_numbers.scope_3_emission_amount_uncategorized = obj_temp_amounts.scope_3_emission_amount_uncategorized;
            }
          }
        }
      }
    }

    const obj_temp_amounts = subtract_contributions_for_deselected_years_v2(tempAlreadyFilteredResults, current_numbers, pivot_table_data, manage_levels, current_level, criteria);

    // subtract based on year filter
    current_numbers.totalConsumptionAmount = obj_temp_amounts.totalConsumptionAmount;
    current_numbers.sum_of_total_all_scopes_location = obj_temp_amounts.sum_of_total_all_scopes_location;
    current_numbers.scope_1_emission_amount_location = obj_temp_amounts.scope_1_emission_amount_location;
    current_numbers.scope_2_emission_amount_location = obj_temp_amounts.scope_2_emission_amount_location;
    current_numbers.scope_3_emission_amount_location = obj_temp_amounts.scope_3_emission_amount_location;
    current_numbers.sum_of_total_all_scopes_market = obj_temp_amounts.sum_of_total_all_scopes_market;
    current_numbers.scope_1_emission_amount_market = obj_temp_amounts.scope_1_emission_amount_market;
    current_numbers.scope_2_emission_amount_market = obj_temp_amounts.scope_2_emission_amount_market;
    current_numbers.scope_3_emission_amount_market = obj_temp_amounts.scope_3_emission_amount_market;
    current_numbers.sum_of_total_all_scopes_uncategorized = obj_temp_amounts.sum_of_total_all_scopes_uncategorized;
    current_numbers.scope_1_emission_amount_uncategorized = obj_temp_amounts.scope_1_emission_amount_uncategorized;
    current_numbers.scope_2_emission_amount_uncategorized = obj_temp_amounts.scope_2_emission_amount_uncategorized;
    current_numbers.scope_3_emission_amount_uncategorized = obj_temp_amounts.scope_3_emission_amount_uncategorized;

    return current_numbers;
  }

  const pivot_table_row_expand_recursive = (factor, record, new_level_index = -1, passed_pivot_table_data = null, passed_manage_levels = null) => {
    console.log('calling recursive handle pivot table row expand');
    let column_display_name = '';

    let manage_levels = [];
    if (passed_manage_levels !== null) {
      manage_levels = passed_manage_levels;
    } else {
      manage_levels = manageLevelsState;
    }

    /*
    console.log('manageLevelsState')
    console.log(manageLevelsState);
    console.log('manage_levels')
    console.log(manage_levels);
    */
    let current_level = null;
    if (new_level_index != -1) {
      current_level = manage_levels[new_level_index];

    } else {
      current_level = manage_levels[record.level];
    }

    const newPivotDataList = [];
    let pivot_table_data = [];
    if (passed_pivot_table_data !== null) {
      pivot_table_data = passed_pivot_table_data;
    } else {
      pivot_table_data = pivotTableData;
    }

    const year_level = manage_levels.find(item => item.is_year_field === true);

    if (current_level) {
      console.log('inside current level');
      // we have root level get unique fields
      let entity_name = "";
      if (current_level.isIdentifier) {
        entity_name = "group";
      } else {
        entity_name = current_level.entity_name;
      }

      // we probably can make this more efficient by only grabbing values with parent combination
      let arr_unique_values = getUniqueValues(pivot_table_data, entity_name);
      if (current_level.is_year_field) {
        // need to actually subtract numbers using the years that have been deselected from the all the parent combinations
        arr_unique_values = arr_unique_values.filter(uniqueValue => manageYearsVisibility[uniqueValue]);
      } else {
        // loop through current level and filter out anything that has been filtered out
        // if not year level
        // filter out anything that is false
        arr_unique_values = arr_unique_values.filter(uniqueValue => {
          // Loop through manage_levels.sub_menu to find the matching item
          if (current_level.sub_menu && current_level.sub_menu.length > 0) {
            const matchedItem = current_level.sub_menu.find(subMenuItem => subMenuItem.itemName === uniqueValue);
            // If a matching item is found, return true only if it is active
            return matchedItem && matchedItem.is_active !== false;

          }
          return true;
          
        });
      }
      //console.log('inside recursive expand. found unique values for: ' + entity_name)
      //console.log(arr_unique_values)


      let n = 0;
      if (record.level > 1) {
        n = record.id+1;
      }

      // Loop through arr_root_unique_values
      arr_unique_values.forEach(uniqueValue => {
        // Prepare the criteria object
        const criteria = {};
        // need to add all parent combinations

        // Loop through current_combinations and add each to criteria
        for (const key in record.current_combinations) {
            if (record.current_combinations.hasOwnProperty(key)) {
                criteria[key] = record.current_combinations[key];
            }
        }
        console.log('record')
        console.log(record)
        /*
        console.log('current combinations for record ')
        console.log(record.current_combinations)
        */
        criteria[entity_name] = uniqueValue;

        column_display_name = uniqueValue;

        /*
        console.log('added combination new criteria is')
        console.log(criteria)
        */
        console.log('new criteria is ' + uniqueValue)

        // Call filterDataByCriteria with the criteria
        const filteredResults = filterDataByCriteria(pivot_table_data, criteria);
        if (entity_name == "scope") {
          if (uniqueValue == "scope_1") {
            column_display_name = "Scope 1";
          } else if (uniqueValue == "scope_2") {
            column_display_name = "Scope 2";
          } else if (uniqueValue == "scope_3") {
            column_display_name = "Scope 3";
          }
        }

        // Initialize the total_consumption_amount
        let totalConsumptionAmount = 0;
        let sum_of_total_all_scopes_location = 0;
        let scope_1_emission_amount_location = 0;
        let scope_2_emission_amount_location = 0;
        let scope_3_emission_amount_location = 0;
        let sum_of_total_all_scopes_market = 0;
        let scope_1_emission_amount_market = 0;
        let scope_2_emission_amount_market = 0;
        let scope_3_emission_amount_market = 0;
        let sum_of_total_all_scopes_uncategorized = 0;
        let scope_1_emission_amount_uncategorized = 0;
        let scope_2_emission_amount_uncategorized = 0;
        let scope_3_emission_amount_uncategorized = 0;

        // Loop through the filtered results to sum up total_consumption_amount
        filteredResults.forEach(item => {
            if (item.indicatorType !== undefined && item.indicatorType === "l") {
              sum_of_total_all_scopes_location += item.total_emission;
              if (item.scope === "scope_3") {
                scope_3_emission_amount_location += item.total_emission;
              } else if (item.scope === "scope_2") {
                scope_2_emission_amount_location += item.total_emission;

              } else {
                // default to scope 1
                scope_1_emission_amount_location += item.total_emission;
              }
            } else if (item.indicatorType !== undefined && item.indicatorType === "m") {
              sum_of_total_all_scopes_market += item.total_emission;
              if (item.scope === "scope_3") {
                scope_3_emission_amount_market += item.total_emission;
              } else if (item.scope === "scope_2") {
                scope_2_emission_amount_market += item.total_emission;

              } else {
                // default to scope 1
                scope_1_emission_amount_market += item.total_emission;
              }
            } else {
              sum_of_total_all_scopes_uncategorized += item.total_emission;
              if (item.scope === "scope_3") {
                scope_3_emission_amount_uncategorized += item.total_emission;
              } else if (item.scope === "scope_2") {
                scope_2_emission_amount_uncategorized += item.total_emission;

              } else {
                // default to scope 1
                scope_1_emission_amount_uncategorized += item.total_emission;
              }

            }
            if (
              (consumptionAmountIndicatorType == "location-based" && item.indicatorType == "l")
              || (consumptionAmountIndicatorType == "market-based" && item.indicatorType == "m")
              || (consumptionAmountIndicatorType == "uncategorized" && (item.indicatorType !== "m" && item.indicatorType !== "l"))
            ) {
              totalConsumptionAmount += item.total_consumption_amount;
            }
        });

        let current_numbers = {};

        current_numbers.totalConsumptionAmount = totalConsumptionAmount;
        current_numbers.sum_of_total_all_scopes_location = sum_of_total_all_scopes_location;
        current_numbers.scope_1_emission_amount_location = scope_1_emission_amount_location;
        current_numbers.scope_2_emission_amount_location = scope_2_emission_amount_location;
        current_numbers.scope_3_emission_amount_location = scope_3_emission_amount_location;
        current_numbers.sum_of_total_all_scopes_market = sum_of_total_all_scopes_market;
        current_numbers.scope_1_emission_amount_market = scope_1_emission_amount_market;
        current_numbers.scope_2_emission_amount_market = scope_2_emission_amount_market;
        current_numbers.scope_3_emission_amount_market = scope_3_emission_amount_market;
        current_numbers.sum_of_total_all_scopes_uncategorized = sum_of_total_all_scopes_uncategorized;
        current_numbers.scope_1_emission_amount_uncategorized = scope_1_emission_amount_uncategorized;
        current_numbers.scope_2_emission_amount_uncategorized = scope_2_emission_amount_uncategorized;
        current_numbers.scope_3_emission_amount_uncategorized = scope_3_emission_amount_uncategorized;

        current_numbers = manage_and_subtract_level_items_that_are_filtered_out(current_numbers, pivot_table_data, manage_levels, current_level, criteria);

        totalConsumptionAmount = current_numbers.totalConsumptionAmount;
        sum_of_total_all_scopes_location = current_numbers.sum_of_total_all_scopes_location;
        scope_1_emission_amount_location = current_numbers.scope_1_emission_amount_location;
        scope_2_emission_amount_location = current_numbers.scope_2_emission_amount_location;
        scope_3_emission_amount_location = current_numbers.scope_3_emission_amount_location;
        sum_of_total_all_scopes_market = current_numbers.sum_of_total_all_scopes_market;
        scope_1_emission_amount_market = current_numbers.scope_1_emission_amount_market;
        scope_2_emission_amount_market = current_numbers.scope_2_emission_amount_market;
        scope_3_emission_amount_market = current_numbers.scope_3_emission_amount_market;
        sum_of_total_all_scopes_uncategorized = current_numbers.sum_of_total_all_scopes_uncategorized;
        scope_1_emission_amount_uncategorized = current_numbers.scope_1_emission_amount_uncategorized;
        scope_2_emission_amount_uncategorized = current_numbers.scope_2_emission_amount_uncategorized;
        scope_3_emission_amount_uncategorized = current_numbers.scope_3_emission_amount_uncategorized;

        /*
        // apply for year
        // if year level is enabled and current_level is not year level then do subtracting
        if (year_level && !current_level.is_year_field) {

          current_numbers.totalConsumptionAmount = totalConsumptionAmount;
          current_numbers.sum_of_total_all_scopes_location = sum_of_total_all_scopes_location;
          current_numbers.scope_1_emission_amount_location = scope_1_emission_amount_location;
          current_numbers.scope_2_emission_amount_location = scope_2_emission_amount_location;
          current_numbers.scope_3_emission_amount_location = scope_3_emission_amount_location;
          current_numbers.sum_of_total_all_scopes_market = sum_of_total_all_scopes_market;
          current_numbers.scope_1_emission_amount_market = scope_1_emission_amount_market;
          current_numbers.scope_2_emission_amount_market = scope_2_emission_amount_market;
          current_numbers.scope_3_emission_amount_market = scope_3_emission_amount_market;
          current_numbers.sum_of_total_all_scopes_uncategorized = sum_of_total_all_scopes_uncategorized;
          current_numbers.scope_1_emission_amount_uncategorized = scope_1_emission_amount_uncategorized;
          current_numbers.scope_2_emission_amount_uncategorized = scope_2_emission_amount_uncategorized;
          current_numbers.scope_3_emission_amount_uncategorized = scope_3_emission_amount_uncategorized;

          current_numbers = subtract_contributions_for_deselected_years(current_numbers, pivot_table_data, manage_levels, current_level, criteria);

          totalConsumptionAmount = current_numbers.totalConsumptionAmount;
          sum_of_total_all_scopes_location = current_numbers.sum_of_total_all_scopes_location;
          scope_1_emission_amount_location = current_numbers.scope_1_emission_amount_location;
          scope_2_emission_amount_location = current_numbers.scope_2_emission_amount_location;
          scope_3_emission_amount_location = current_numbers.scope_3_emission_amount_location;
          sum_of_total_all_scopes_market = current_numbers.sum_of_total_all_scopes_market;
          scope_1_emission_amount_market = current_numbers.scope_1_emission_amount_market;
          scope_2_emission_amount_market = current_numbers.scope_2_emission_amount_market;
          scope_3_emission_amount_market = current_numbers.scope_3_emission_amount_market;
          sum_of_total_all_scopes_uncategorized = current_numbers.sum_of_total_all_scopes_uncategorized;
          scope_1_emission_amount_uncategorized = current_numbers.scope_1_emission_amount_uncategorized;
          scope_2_emission_amount_uncategorized = current_numbers.scope_2_emission_amount_uncategorized;
          scope_3_emission_amount_uncategorized = current_numbers.scope_3_emission_amount_uncategorized;
        }
        */

        const pivot_table_row = {
          id: n,
          level: record.level+1,
          column_display_name: column_display_name,
          current_combinations: criteria,
          row_labels: "",
          sum_of_total_all_scopes_location: (factor*sum_of_total_all_scopes_location).toFixed(round_to_decimal_places),
          scope_1_emission_amount_location: (factor*scope_1_emission_amount_location).toFixed(round_to_decimal_places),
          scope_2_emission_amount_location: (factor*scope_2_emission_amount_location).toFixed(round_to_decimal_places),
          scope_3_emission_amount_location: (factor*scope_3_emission_amount_location).toFixed(round_to_decimal_places),
          sum_of_total_all_scopes_market: (factor*sum_of_total_all_scopes_market).toFixed(round_to_decimal_places),
          scope_1_emission_amount_market: (factor*scope_1_emission_amount_market).toFixed(round_to_decimal_places),
          scope_2_emission_amount_market: (factor*scope_2_emission_amount_market).toFixed(round_to_decimal_places),
          scope_3_emission_amount_market: (factor*scope_3_emission_amount_market).toFixed(round_to_decimal_places),
          sum_of_total_all_scopes_uncategorized: (factor*sum_of_total_all_scopes_uncategorized).toFixed(round_to_decimal_places),
          scope_1_emission_amount_uncategorized: (factor*scope_1_emission_amount_uncategorized).toFixed(round_to_decimal_places),
          scope_2_emission_amount_uncategorized: (factor*scope_2_emission_amount_uncategorized).toFixed(round_to_decimal_places),
          scope_3_emission_amount_uncategorized: (factor*scope_3_emission_amount_uncategorized).toFixed(round_to_decimal_places),
          sum_of_consumption: (totalConsumptionAmount).toFixed(round_to_decimal_places),
          entity_name: entity_name,
          is_expanded: false,
        }
        pivot_table_row.row_labels = generate_row_label(pivot_table_row, manage_levels);

        // if all 0 then don't add
        if (
          sum_of_total_all_scopes_location == 0 &&
          scope_1_emission_amount_location == 0 &&
          scope_2_emission_amount_location == 0 &&
          scope_3_emission_amount_location == 0 &&
          sum_of_total_all_scopes_market == 0 &&
          scope_1_emission_amount_market == 0 &&
          scope_2_emission_amount_market == 0 &&
          scope_3_emission_amount_market == 0 &&
          sum_of_total_all_scopes_uncategorized == 0 &&
          scope_1_emission_amount_uncategorized == 0 &&
          scope_2_emission_amount_uncategorized == 0 &&
          scope_3_emission_amount_uncategorized == 0 &&
          totalConsumptionAmount == 0
        ) {
        } else {
          // Push the new row into the newPivotDataList array
          newPivotDataList.push(pivot_table_row);
        }
        n++;
      });
    }

    const children = [];
    const merged_rows = [];
    // loop through newpivotdatalist these will be all the children
    newPivotDataList.forEach((pivot_table_row) => {
      const child_rows = (pivot_table_row_expand_recursive(factor, pivot_table_row, pivot_table_row.level, pivot_table_data, manage_levels));
      pivot_table_row.is_expanded = true;
      pivot_table_row.row_labels = generate_row_label(pivot_table_row, manage_levels);
      merged_rows.push(pivot_table_row);
      child_rows.forEach((child_row) => {
        child_row.is_expanded = true;
        merged_rows.push(child_row);
      });
    });



    // then once grabbed datalist for each child then merge them all together and then return that
    //return newPivotDataList;
    return merged_rows;
  }

  // expands a row in a pivot table
  const handle_pivot_table_row_expand = (record) => {


    // make sure passed levels are in consistent state to what is displayed hide/show
    const updatedLevels = [...manageLevelsState];
    const newLevels = updatedLevels.filter(level => level.is_active !== false);

    const newPivotDataList = pivot_table_row_expand_recursive(conversionFactor, record, -1, pivotTableData, newLevels);

    const insertIndex = record.id;
    //newPivotDataList[record.id].is_expanded = true;

    if (insertIndex !== -1) {
      // Insert the newPivotDataList after the found index
      const updatedPivotDataList = [
        ...pivotDataList.slice(0, insertIndex + 1),
        ...newPivotDataList,
        ...pivotDataList.slice(insertIndex + 1),
      ];

      updatedPivotDataList[record.id].is_expanded = true;
      const level_padding_left = (record.level-1)*20;
      updatedPivotDataList[record.id].row_labels = (
        <>
          <span
            style={{ 
              cursor: 'pointer', 
              color: '#ffffff', 
              paddingLeft: `${level_padding_left}px` // Assuming you want to add 'px' as the unit
            }}
            >
              <MinusSquareOutlined style={{ color: '#a68dfb' }} /> {record.column_display_name}
          </span>
        </>
      );

      // Reindex all ids sequentially
      const reindexedPivotDataList = updatedPivotDataList.map((item, index) => ({
        ...item,
        id: index, // Reindex id to be sequential starting from 0
      }));
      //console.log('reindex data')
      //console.log(reindexedPivotDataList)

      // Update the state with the new reindexed list
      setPivotDataList(reindexedPivotDataList);
    }
  }


  const load_data = async () => {
    // get current group
    let current_group = "";
    try {
      const data = await Auth.currentSession();
      current_group = data["accessToken"]["payload"]["cognito:groups"]?.filter(
        (element) => element.includes("org:")
      )[0];
      setCurrentGroup(current_group);
    } catch (error) {
      current_group = "";
      setCurrentGroup(current_group);
      console.log("Somethng went wrong with getting current group");
      console.error("Something went wrong with getting current_group", error.message);
    }
    
    const companyInfo = await getCompanyInfo();
    const obj = JSON.parse(companyInfo.data);
    setCompanyDetails(obj);
    const factor = await getConversionFactor(obj.emissions_unit);
    console.log('factor is ')
    console.log(factor)
    const default_metadata_sheet_id = obj.default_metadata_sheet_id;

    console.log('company info');
    console.log(companyInfo)
    // need to grab emissionsUnit
    console.log('obj');
    console.log(obj)
    round_to_decimal_places = obj.decimal_places_number;


    let manage_levels = await get_manage_levels(default_metadata_sheet_id);

    manage_levels = manage_levels.map(level => ({
      ...level,        // Spread the rest of the object
      is_active: true  // Set is_active to true
    }));

    const year_level = {
      "display_name": "Reporting Year",
      "entity_name": "year",
      "level": 1,
      "is_active": true,
      "is_year_field": true,
      /*
      "sub_menu": [
        {"itemName":"MONTH"},
        {"itemName":"WEEK"},
        {"itemName":"YEAR"},
      ]
      */
    }

    manage_levels.unshift(year_level);

    const scope_level = {
      "display_name": "Scope",
      "entity_name": "scope",
      "level": 1,
      "is_active": true,
      "is_scope_field": true,
      /*
      "sub_menu": [
        {"itemName":"AMER"},
        {"itemName":"APAC"},
      ]
      */
    }

    manage_levels.push(scope_level);

    const custom_name_level = {
      "display_name": "Custom Name",
      "entity_name": "custom_name",
      "level": 1,
      "is_active": true,
      /*
      "sub_menu": [
        {"itemName":"HELLO"},
        {"itemName":"WORLD"},
      ]
        */
    }

    manage_levels.push(custom_name_level);

    // add year to manage levels
    manage_levels.forEach((level, index) => {
        level.level = index + 1; // Update the level property with index + 1
    });
    console.log('manage levels');
    console.log(manage_levels)


    setOrigManageLevels(manage_levels);
    const settingsType = "pivot-table-user-settings-" + current_group;

    let is_have_user_settings = false;
    let pivot_table_user_settings = {};
    try {
      const username = auth['auth']['accessToken']['payload']['username'];
      
      let payload = {
        owner: username,
        settingName: settingsType,
      };
      pivot_table_user_settings = await getUserSettings(payload);
      if (pivot_table_user_settings && pivot_table_user_settings['settingValue']) {
        pivot_table_user_settings = JSON.parse(pivot_table_user_settings['settingValue'])
        if (isEmpty(pivot_table_user_settings['manageLevelsState']) || isEmpty(pivot_table_user_settings['manageYearsVisibility']) || isEmpty(pivot_table_user_settings['columnVisibility'])) {
          pivot_table_user_settings = null;
        }
      }
    } catch (error) {
      pivot_table_user_settings = null;
      console.log("Somethng went wrong with loading pivot table user settings!");
      console.error("Something went wrong with loading pivot table user settings:", error.message);
    }

    if (pivot_table_user_settings) {
      console.log('loading data for pivot_table_user_settings')
      console.log(pivot_table_user_settings)
      if (!isEmpty(pivot_table_user_settings['manageLevelsState']) && !isEmpty(pivot_table_user_settings['manageYearsVisibility']) && !isEmpty(pivot_table_user_settings['columnVisibility'])) {
        console.log('found proper user settings and loading')
        is_have_user_settings = true;
        setManageLevelsState(pivot_table_user_settings.manageLevelsState);
        setManageYearsVisibility(pivot_table_user_settings.manageYearsVisibility);
        setColumnVisibility(pivot_table_user_settings.columnVisibility);
        if (pivot_table_user_settings['consumptionAmountIndicatorType']) {
          setConsumptionAmountIndicatorType(pivot_table_user_settings['consumptionAmountIndicatorType']);
        }
      }
    }
    if (!is_have_user_settings) {
      setManageLevelsState(manage_levels);
    }


    const pivot_table_data = await get_pivot_table_data(default_metadata_sheet_id);

    setPivotTableData(pivot_table_data)

    manage_levels.forEach((level, index) => {
      manage_levels[index].sub_menu = [];

      if (!manage_levels[index].is_year_field) {
        const arr_unique_values = getUniqueValues(pivot_table_data, manage_levels[index].entity_name);
        // Map over arr_unique_values to generate an array of objects
        const obj = arr_unique_values.map((value, i) => {
            return { 
              itemName: value,
              is_active: true
            }; // Create an object with itemName
        });
        //console.log('submenu');
        //console.log(obj);

      // Pass the array of objects to manage_levels.sub_menu
        manage_levels[index].sub_menu = obj;
        // Do something with each level
        //console.log(level); // Example action: log the level
      }
    });

    // update our user settings if we have new or deleted levels or sub_menu items or years
    if (is_have_user_settings) {
      manage_levels.forEach((level, index) => {
        if (!manage_levels[index].is_year_field) {
          // If user settings exist, check for new or deleted levels
          // Fetch the manageLevelsState from the user settings
          const userLevelsState = pivot_table_user_settings['manageLevelsState'];

          // Check for new levels to be added to the user settings
          const currentLevelInUserSettings = userLevelsState.find(
            (userLevel) => userLevel.entity_name === level.entity_name
          );

          // If the level is not in user settings, add it
          if (!currentLevelInUserSettings) {
            userLevelsState.splice(index, 0, level);
          }

          // Update existing levels in user settings
          userLevelsState.forEach((userLevel, userIndex) => {
            const levelExistsInManageLevels = manage_levels.some(
              (level) => level.entity_name === userLevel.entity_name
            );

            // If the level exists in manage_levels, update the sub_menu
            if (levelExistsInManageLevels) {
            } else {
              // If the level doesn't exist in manage_levels, remove it from user settings
              userLevelsState.splice(userIndex, 1);
            }
          });
        }
      });
      // update the sub menus into our usersettings
      manage_levels.forEach((level, index) => {
        if (!manage_levels[index].is_year_field) {
          // Fetch the manageLevelsState from the user settings
          const userLevelsState = pivot_table_user_settings['manageLevelsState'];

          // Check if the level exists in user settings
          const currentLevelInUserSettings = userLevelsState.find(
            (userLevel) => userLevel.entity_name === level.entity_name
          );

          // Now check for changes in the `sub_menu` values
          if (currentLevelInUserSettings) {
            const arr_unique_values = getUniqueValues(pivot_table_data, manage_levels[index].entity_name);

            // Create the updated sub_menu object from unique values
            const updatedSubMenu = arr_unique_values.map((value) => {
              return {
                itemName: value,
                is_active: true,
              };
            });

            // Add new values to sub_menu
            updatedSubMenu.forEach((newItem) => {
              
              // Ensure sub_menu exists and is an array
              if (!Array.isArray(currentLevelInUserSettings.sub_menu)) {
                currentLevelInUserSettings.sub_menu = []; // Initialize it if it doesn't exist
              }

              const existingItem = currentLevelInUserSettings.sub_menu.find(
                (subItem) => subItem.itemName === newItem.itemName
              );

              // If new item doesn't exist in the current sub_menu, add it
              if (!existingItem) {
                currentLevelInUserSettings.sub_menu.push(newItem);
              }
            });

            // Remove deleted values from sub_menu
            currentLevelInUserSettings.sub_menu = currentLevelInUserSettings.sub_menu.filter((subItem) => {
              return updatedSubMenu.some((newItem) => newItem.itemName === subItem.itemName);
            });
          }
        }
      });
      // Fetch the manageYearsVisibility from the user settings
      const userYearsVisibility = pivot_table_user_settings['manageYearsVisibility'];

      // Loop through manageYearsVisibility to add new years to user settings
      Object.keys(manageYearsVisibility).forEach((year) => {
        if (!(year in userYearsVisibility)) {
          // If the year doesn't exist in user settings, add it
          userYearsVisibility[year] = manageYearsVisibility[year];
        }
      });

      // Loop through user settings manageYearsVisibility to remove years that no longer exist
      Object.keys(userYearsVisibility).forEach((year) => {
        if (!(year in manageYearsVisibility)) {
          // If the year doesn't exist in manageYearsVisibility, remove it from user settings
          delete userYearsVisibility[year];
        }
      });
      onSettingsChanged("pivot-table-user-settings-" + current_group, pivot_table_user_settings);
    }

    setOrigManageLevels(manage_levels);
    if (!is_have_user_settings) {
      setManageLevelsState(manage_levels);
    }


    //console.log('pivot table data');
    //console.log(pivot_table_data)
    if (is_have_user_settings) {
      manage_levels = pivot_table_user_settings['manageLevelsState']
    }
    process_pivot_table_root(factor, pivot_table_data, manage_levels);

    setLoadingTableData(false);

  }; // end load data

  function process_pivot_table_root(factor, param_pivot_table_data = null, param_manage_levels = null) {

    let pivot_table_data = null;
    if(param_pivot_table_data) {
      pivot_table_data = param_pivot_table_data;
    } else {
      pivot_table_data = pivotTableData;
    }

    let manage_levels = null;
    if(param_manage_levels) {
      manage_levels = param_manage_levels;
    } else {
      manage_levels = ManageLevelsState;
    }

    const year_level = manage_levels.find(item => item.is_year_field === true);
    const root_level = manage_levels[0];

    const newPivotDataList = [];

    let column_display_name = "";
    if (root_level) {
      // we have root level get unique fields
      let entity_name = "";
      if (root_level.isIdentifier) {
        entity_name = "group";
      } else {
        entity_name = root_level.entity_name;
      }

      let arr_root_unique_values = getUniqueValues(pivot_table_data, entity_name);
      // should probably rename this to is_year_level
      if (root_level.is_year_field) {
        arr_root_unique_values = arr_root_unique_values.filter(uniqueValue => manageYearsVisibility[uniqueValue]);
      } else {
        // loop through current level and filter out anything that has been filtered out
        // if not year level
        // filter out anything that
        arr_root_unique_values = arr_root_unique_values.filter(uniqueValue => {
          // Loop through manage_levels.sub_menu to find the matching item
          if (root_level.sub_menu && root_level.sub_menu.length > 0) {
            const matchedItem = root_level.sub_menu.find(subMenuItem => subMenuItem.itemName === uniqueValue);
            // If a matching item is found, return true only if it is active
            return matchedItem && matchedItem.is_active !== false;

          }
          return true;
        });
      }


      console.log('root unique values')
      console.log(arr_root_unique_values);

      let n = 0;
      // Loop through arr_root_unique_values
      arr_root_unique_values.forEach(uniqueValue => {
        // Prepare the criteria object
        const criteria = {};
        criteria[entity_name] = uniqueValue;
        column_display_name = uniqueValue;

        console.log('criteria for root is')
        console.log(criteria)

        // Call filterDataByCriteria with the criteria
        const filteredResults = filterDataByCriteria(pivot_table_data, criteria);
        if (entity_name == "scope") {
          if (uniqueValue == "scope_1") {
            column_display_name = "Scope 1";
          } else if (uniqueValue == "scope_2") {
            column_display_name = "Scope 2";
          } else if (uniqueValue == "scope_3") {
            column_display_name = "Scope 3";
          }
        }

        // Initialize the total_consumption_amount
        let totalConsumptionAmount = 0;
        let sum_of_total_all_scopes_location = 0;
        let scope_1_emission_amount_location = 0;
        let scope_2_emission_amount_location = 0;
        let scope_3_emission_amount_location = 0;
        let sum_of_total_all_scopes_market = 0;
        let scope_1_emission_amount_market = 0;
        let scope_2_emission_amount_market = 0;
        let scope_3_emission_amount_market = 0;
        let sum_of_total_all_scopes_uncategorized = 0;
        let scope_1_emission_amount_uncategorized = 0;
        let scope_2_emission_amount_uncategorized = 0;
        let scope_3_emission_amount_uncategorized = 0;

        // Loop through the filtered results to sum up total_consumption_amount
        filteredResults.forEach(item => {
            if (item.indicatorType !== undefined && item.indicatorType === "l") {
              sum_of_total_all_scopes_location += item.total_emission;
              if (item.scope === "scope_3") {
                scope_3_emission_amount_location += item.total_emission;
              } else if (item.scope === "scope_2") {
                scope_2_emission_amount_location += item.total_emission;

              } else {
                // default to scope 1
                scope_1_emission_amount_location += item.total_emission;
              }
            } else if (item.indicatorType !== undefined && item.indicatorType === "m") {
              sum_of_total_all_scopes_market += item.total_emission;
              if (item.scope === "scope_3") {
                scope_3_emission_amount_market += item.total_emission;
              } else if (item.scope === "scope_2") {
                scope_2_emission_amount_market += item.total_emission;

              } else {
                // default to scope 1
                scope_1_emission_amount_market += item.total_emission;
              }
            } else {
              sum_of_total_all_scopes_uncategorized += item.total_emission;
              if (item.scope === "scope_3") {
                scope_3_emission_amount_uncategorized += item.total_emission;
              } else if (item.scope === "scope_2") {
                scope_2_emission_amount_uncategorized += item.total_emission;

              } else {
                // default to scope 1
                scope_1_emission_amount_uncategorized += item.total_emission;
              }

            }
            if (
              (consumptionAmountIndicatorType == "location-based" && item.indicatorType == "l")
              || (consumptionAmountIndicatorType == "market-based" && item.indicatorType == "m")
              || (consumptionAmountIndicatorType == "uncategorized" && (item.indicatorType !== "m" && item.indicatorType !== "l"))
            ) {
              totalConsumptionAmount += item.total_consumption_amount;
            }
        });

        let current_numbers = {};

        current_numbers.totalConsumptionAmount = totalConsumptionAmount;
        current_numbers.sum_of_total_all_scopes_location = sum_of_total_all_scopes_location;
        current_numbers.scope_1_emission_amount_location = scope_1_emission_amount_location;
        current_numbers.scope_2_emission_amount_location = scope_2_emission_amount_location;
        current_numbers.scope_3_emission_amount_location = scope_3_emission_amount_location;
        current_numbers.sum_of_total_all_scopes_market = sum_of_total_all_scopes_market;
        current_numbers.scope_1_emission_amount_market = scope_1_emission_amount_market;
        current_numbers.scope_2_emission_amount_market = scope_2_emission_amount_market;
        current_numbers.scope_3_emission_amount_market = scope_3_emission_amount_market;
        current_numbers.sum_of_total_all_scopes_uncategorized = sum_of_total_all_scopes_uncategorized;
        current_numbers.scope_1_emission_amount_uncategorized = scope_1_emission_amount_uncategorized;
        current_numbers.scope_2_emission_amount_uncategorized = scope_2_emission_amount_uncategorized;
        current_numbers.scope_3_emission_amount_uncategorized = scope_3_emission_amount_uncategorized;

        current_numbers = manage_and_subtract_level_items_that_are_filtered_out(current_numbers, pivot_table_data, manage_levels, root_level, criteria);

        totalConsumptionAmount = current_numbers.totalConsumptionAmount;
        sum_of_total_all_scopes_location = current_numbers.sum_of_total_all_scopes_location;
        scope_1_emission_amount_location = current_numbers.scope_1_emission_amount_location;
        scope_2_emission_amount_location = current_numbers.scope_2_emission_amount_location;
        scope_3_emission_amount_location = current_numbers.scope_3_emission_amount_location;
        sum_of_total_all_scopes_market = current_numbers.sum_of_total_all_scopes_market;
        scope_1_emission_amount_market = current_numbers.scope_1_emission_amount_market;
        scope_2_emission_amount_market = current_numbers.scope_2_emission_amount_market;
        scope_3_emission_amount_market = current_numbers.scope_3_emission_amount_market;
        sum_of_total_all_scopes_uncategorized = current_numbers.sum_of_total_all_scopes_uncategorized;
        scope_1_emission_amount_uncategorized = current_numbers.scope_1_emission_amount_uncategorized;
        scope_2_emission_amount_uncategorized = current_numbers.scope_2_emission_amount_uncategorized;
        scope_3_emission_amount_uncategorized = current_numbers.scope_3_emission_amount_uncategorized;


        /*
        // if not in year field only do if have year level is enabled and our current level is not year level
        if (year_level && !root_level.is_year_field) {
          console.log('root level is not a year level')
          console.log('criteria for root is')
          console.log(criteria)

          current_numbers.totalConsumptionAmount = totalConsumptionAmount;
          current_numbers.sum_of_total_all_scopes_location = sum_of_total_all_scopes_location;
          current_numbers.scope_1_emission_amount_location = scope_1_emission_amount_location;
          current_numbers.scope_2_emission_amount_location = scope_2_emission_amount_location;
          current_numbers.scope_3_emission_amount_location = scope_3_emission_amount_location;
          current_numbers.sum_of_total_all_scopes_market = sum_of_total_all_scopes_market;
          current_numbers.scope_1_emission_amount_market = scope_1_emission_amount_market;
          current_numbers.scope_2_emission_amount_market = scope_2_emission_amount_market;
          current_numbers.scope_3_emission_amount_market = scope_3_emission_amount_market;
          current_numbers.sum_of_total_all_scopes_uncategorized = sum_of_total_all_scopes_uncategorized;
          current_numbers.scope_1_emission_amount_uncategorized = scope_1_emission_amount_uncategorized;
          current_numbers.scope_2_emission_amount_uncategorized = scope_2_emission_amount_uncategorized;
          current_numbers.scope_3_emission_amount_uncategorized = scope_3_emission_amount_uncategorized;

          current_numbers = subtract_contributions_for_deselected_years(current_numbers, pivot_table_data, manage_levels, root_level, criteria);

          totalConsumptionAmount = current_numbers.totalConsumptionAmount;
          sum_of_total_all_scopes_location = current_numbers.sum_of_total_all_scopes_location;
          scope_1_emission_amount_location = current_numbers.scope_1_emission_amount_location;
          scope_2_emission_amount_location = current_numbers.scope_2_emission_amount_location;
          scope_3_emission_amount_location = current_numbers.scope_3_emission_amount_location;
          sum_of_total_all_scopes_market = current_numbers.sum_of_total_all_scopes_market;
          scope_1_emission_amount_market = current_numbers.scope_1_emission_amount_market;
          scope_2_emission_amount_market = current_numbers.scope_2_emission_amount_market;
          scope_3_emission_amount_market = current_numbers.scope_3_emission_amount_market;
          sum_of_total_all_scopes_uncategorized = current_numbers.sum_of_total_all_scopes_uncategorized;
          scope_1_emission_amount_uncategorized = current_numbers.scope_1_emission_amount_uncategorized;
          scope_2_emission_amount_uncategorized = current_numbers.scope_2_emission_amount_uncategorized;
          scope_3_emission_amount_uncategorized = current_numbers.scope_3_emission_amount_uncategorized;
        }
        */

        const pivot_table_row = {
          id: n,
          level: 1,
          column_display_name: column_display_name,
          current_combinations: { [entity_name]: uniqueValue  },
          row_labels: "",
          sum_of_total_all_scopes_location: (factor*sum_of_total_all_scopes_location).toFixed(round_to_decimal_places),
          scope_1_emission_amount_location: (factor*scope_1_emission_amount_location).toFixed(round_to_decimal_places),
          scope_2_emission_amount_location: (factor*scope_2_emission_amount_location).toFixed(round_to_decimal_places),
          scope_3_emission_amount_location: (factor*scope_3_emission_amount_location).toFixed(round_to_decimal_places),
          sum_of_total_all_scopes_market: (factor*sum_of_total_all_scopes_market).toFixed(round_to_decimal_places),
          scope_1_emission_amount_market: (factor*scope_1_emission_amount_market).toFixed(round_to_decimal_places),
          scope_2_emission_amount_market: (factor*scope_2_emission_amount_market).toFixed(round_to_decimal_places),
          scope_3_emission_amount_market: (factor*scope_3_emission_amount_market).toFixed(round_to_decimal_places),
          sum_of_total_all_scopes_uncategorized: (factor*sum_of_total_all_scopes_uncategorized).toFixed(round_to_decimal_places),
          scope_1_emission_amount_uncategorized: (factor*scope_1_emission_amount_uncategorized).toFixed(round_to_decimal_places),
          scope_2_emission_amount_uncategorized: (factor*scope_2_emission_amount_uncategorized).toFixed(round_to_decimal_places),
          scope_3_emission_amount_uncategorized: (factor*scope_3_emission_amount_uncategorized).toFixed(round_to_decimal_places),
          sum_of_consumption: (totalConsumptionAmount).toFixed(round_to_decimal_places),
          is_expanded: false,
          entity_name: entity_name,
        }
        // Push the new row into the newPivotDataList array
        newPivotDataList.push(pivot_table_row);
        n++;
      }); // for each root level
    }

    // start of code to recursively call all rows and auto expand all rows
    const merged_rows = [];

    newPivotDataList.forEach((pivot_table_row) => {
      console.log('auto expanding item')
      console.log(pivot_table_row)
      let child_rows = [];
      pivot_table_row.is_expanded = false;
      if (autoExpandLevels) {
        pivot_table_row.is_expanded = true;
        child_rows = (pivot_table_row_expand_recursive(factor, pivot_table_row, 1, pivot_table_data, manage_levels));
      }
      pivot_table_row.row_labels = generate_row_label(pivot_table_row, manage_levels);
      console.log('found ' + count(child_rows) + ' child rows');
      merged_rows.push(pivot_table_row);
      child_rows.forEach((child_row) => {
        merged_rows.push(child_row);
      });
    });

    const updatedPivotDataList = merged_rows;
    
    const reindexedPivotDataList = updatedPivotDataList.map((item, index) => ({
      ...item,
      id: index, // Reindex id to be sequential starting from 0
    }));
    //console.log('reindex data root level')
    //console.log(reindexedPivotDataList)


    // Update the state with the new reindexed list
    setPivotDataList(reindexedPivotDataList);
    // end of code to recursively call all rows and auto expand all rows

  }


  // get all the entities
  const get_manage_levels = async (default_metadata_sheet_id) => {
    let payload = {};
    payload.sheet_id = default_metadata_sheet_id;

    const response = await API.graphql({
      query: queries["datalake"],
      variables: {
        request_type: "get_default_schema_entities",
        request_payload: JSON.stringify(payload),
      },
    });
    console.log('this is manage level response')
    console.log(response)
    return JSON.parse(response.data.datalake);
  };

  // get pivot table data
  const get_pivot_table_data = async (default_metadata_sheet_id) => {
    let payload = {};
    payload.sheet_id = default_metadata_sheet_id;
    //payload.sheet_id = sheet_id;

    const response = await API.graphql({
      query: queries["datalake"],
      variables: {
        request_type: "get_pivot_table_data",
        request_payload: JSON.stringify(payload),
      },
    });

    // if datalake null
    if (!response.data.datalake) {
      return [];
    }
    //console.log('this is get pivot table data')
    //console.log(response)
    const parsedData = JSON.parse(response.data.datalake);
    // Check if parsedData is not null or undefined
    if (parsedData) {
      console.log('parsedData is not null');

      // Check if the pipeline field exists and is not null
      if (parsedData.pipeline) {
        console.log('pipeline exists');
        
        // Check if the results field exists and is not null
        if (parsedData.results) {
          console.log('results exist');
          return parsedData.results;
        }
      } else {
        console.log('pipeline does not exist');

        // Since parsedData is already checked as not null, you can directly return it
        return parsedData;
      }
    }

    return [];


    //return JSON.parse(response.data.datalake);
  };

  // not used need to delete
  const getNewsList = async (payload) => {
    const response = await API.graphql({
      query: queries["datalake"],
      variables: {
        request_type: "get_news_content",
        request_payload: JSON.stringify(payload),
      },
    });
    console.log('this is response')
    //console.log(response["data"]["datalake"])
    const breakdown_data = JSON.parse(response["data"]["datalake"]);
    console.log(breakdown_data);
    if (breakdown_data && breakdown_data.data && Array.isArray(breakdown_data.data)) {
      // Add an additional column with an empty string to each item
      const updatedBreakdownData = breakdown_data.data.map(item => ({
        ...item,
        year: "2024",
        region: "",
        entity_name: "",
        scope_1_consumption_amount: 0,
        scope_2_consumption_amount: 0, // Add the new property with an empty string
        scope_3_consumption_amount: 0, // Add the new property with an empty string
      }));

      // Replace the original data with the updated data
      breakdown_data.data = updatedBreakdownData;
    }
    return breakdown_data;
  };

  const generatePayload = (params) => {
    const { filter, pagination } = params;
    const query = {};
    if (filter?.category) {
      query.category = { $regex: filter.category, $options: "i" };
    }
    if (filter?.title) {
      query.title = { $regex: filter.title, $options: "i" };
    }
    const sortingResult = {};
    sortingResult[`${"published_date"}`] = -1;

    const payload = {
      filter: query,
      skip: (pagination.current - 1) * pagination.pageSize,
      limit: pagination.pageSize,
      sort: sortingResult,
    };
    return payload;
  };


  const handleManageColumnsCheckBoxClick = (e, name) => {
    setColumnVisibility(prevVisibility => ({
      ...prevVisibility,
      [name]: !prevVisibility[name], // Toggle the current valu
      //[name]: !prevVisibility[name], // Toggle the current valu
    }));
    console.log('checkbox click')
  };

  useEffect(() => {
    load_data();

  }, []);
  
  useEffect(() => {
    const updatedLevels = [...manageLevelsState];
    const newLevels = updatedLevels.filter(level => level.is_active !== false);
    process_pivot_table_root(conversionFactor,pivotTableData, newLevels);
  }, [autoExpandLevels, manageYearsVisibility, consumptionAmountIndicatorType]);
  
  /*
  useEffect(() => {
    const updatedLevels = [...manageLevelsState];
    const newLevels = updatedLevels.filter(level => level.is_active !== false);
    process_pivot_table_root(conversionFactor,pivotTableData, newLevels);
  }, [manageLevelsState]);
  */
  

  const handleChangePagination = (pagination) => {
    //const payload = { filter: filter, pagination: pagination };
  };
  const filter_width = "200px";



  const options = [
    { label: 'Option 1', value: 'option1' },
    { label: 'Option 2', value: 'option2' },
    { label: 'Option 3', value: 'option3' },
  ];

  const handleCheckboxChange = (checked, value) => {
    const newSelectedValues = checked
      ? [...selectedValues, value]
      : selectedValues.filter((item) => item !== value);

    setSelectedValues(newSelectedValues);
  };



  const handleSelect = () => {
    // This function intentionally does nothing
  };



  return (
    <PageWrap padding="0px 0px 0px 0px">
      <FiltersContainer>
        <FormWrapper>
              <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap' }}>
                <div  className={`margin-5 ${styles.select}`} style={{ }}>
                  <StyledButton
                    type="custom"
                    //disabled={!taskDeployments?.length}
                    onClick={() => generateExportReport()}
                    style={{ display: "inline", borderRadius: "4px" }}
                  >
                    <ExportOutlined /> <span>Export Report</span>
                  </StyledButton>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap' }}>
                <div  className={`${styles.select}`} style={{ width: '200px', marginTop: '5px', marginBottom: '5px', marginLeft: '5px', marginRight: '5px'}}>
                  <div className={`${pivot_table_styles.inputHover}`} onClick={onClickHandleManageYearsDropDown} >
                    <Input
                      readOnly
                      className={`${pivot_table_styles.selector} ${pivot_table_styles.inputHover}`}
                      value="Years"
                      prefix={<span style={{ paddingRight: '5px' }}><CalendarOutlined /></span>}
                      suffix={<DownOutlined />}
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                  <div style={{position: 'relative'}}>
                    <div
                      style={{ left: '20px', width: '150px' }}
                      className={
                        isManageYearsDropdownVisible ? pivot_table_styles.selectionBox : pivot_table_styles.selectionBoxHidden
                      }
                    >
                      <TableCustomMenu style={{ borderRight: '#524d64 1px solid' }}>
                        {pastYears.map(year => (
                          <Menu.Item key={year}>
                            <Checkbox
                              onClick={(e) => handleManageYearsVisibility(e, year)}
                              checked={manageYearsVisibility?.[year] ? true : false}
                            >
                              <span style={{ color: 'white' }}>{year}</span>
                            </Checkbox>
                          </Menu.Item>
                        ))}
                      </TableCustomMenu>
                    </div>
                  </div>
                </div>
                <div  className={`${styles.select}`} style={{ width: '200px', marginTop: '5px', marginBottom: '5px', marginLeft: '5px', marginRight: '5px'}}>
                  <div className={`${pivot_table_styles.inputHover}`} onClick={onClickManageLevels}>
                    <Input
                      readOnly
                      className={`${pivot_table_styles.selector} ${pivot_table_styles.inputHover}`}
                      value="Manage Levels"
                      prefix={<span style={{ paddingRight: '5px' }}><DatabaseOutlined /></span>}
                      suffix={<DownOutlined />}
                      style={{ cursor: 'pointer' }}
                       
                    />
                  </div>
                  <div style={{position: 'relative'}}>
                    <div
                      style={{ left: '-100px' }}
                      className={
                        isManageLevelsModalVisible ? pivot_table_styles.selectionBox : pivot_table_styles.selectionBoxHidden
                      }
                    >
                      <SortableMenu
                        levels={manageLevelsState} // Pass the current state to the SortableMenu
                        onToggleLevel={onToggleLevel} // Pass the function to toggle the checkbox
                        onSortEnd={onSortEnd} // Handle sorting end event
                        useDragHandle={true} // Allow dragging by the handle (MenuOutlined)
                        pressDelay={200}
                        menuItemStyle={menuItemStyle} // Pass custom styles
                        helperClass={pivot_table_styles.draggingItem}
                        getHelperDimensions={getHelperDimensions} // Ensure correct size of dragged element
                        handle_level_move_down_click={handle_level_move_down_click}
                        handle_level_move_up_click={handle_level_move_up_click}
                        auto_expand_levels_toggle={auto_expand_levels_toggle}
                        auto_expand_levels={autoExpandLevels}
                        handle_reset_manage_levels={handle_reset_manage_levels}

                        onToggleSubMenu = {onToggleSubMenu}
                      />
                    </div>
                  </div>
                </div>
                <div  className={`${styles.select}`} style={{ width: '200px', marginTop: '5px', marginBottom: '5px', marginLeft: '5px', marginRight: '5px'}}>
                  <div className={`${pivot_table_styles.inputHover}`} onClick={onClickHandleManageColumnsPopUpMenu} >
                    <Input
                      readOnly
                      className={`${pivot_table_styles.selector} ${pivot_table_styles.inputHover}`}
                      value="Manage Columns"
                      prefix={<span style={{ paddingRight: '5px' }}><InsertRowRightOutlined /></span>}
                      suffix={<DownOutlined />}
                      style={{ cursor: 'pointer' }}
                      
                    />
                  </div>
                  <div style={{position: 'relative'}}>
                    <div
                      className={
                        isManageColumnsModalVisible ? pivot_table_styles.selectionBox : pivot_table_styles.selectionBoxHidden
                      }
                      style={{ left: '-150px', width: '350px'}}
                    >
                      <div className={pivot_table_styles.menu_overrides}>
                        <TableCustomMenu style={{ borderRight: '#524d64 1px solid' }}>
                          <Menu.Item key="levels_column_is_hidden" style={{ height: '22px', marginBottom: '10px', marginTop: '10px'}}>
                            <div style={{ width: '100px', display: 'flex', justifyContent: 'flex-start', flexWrap: 'nowrap' }}>
                              <AutoExpandCustomSwitch 
                                onChange={handleLevelVisibility}
                                checked={columnVisibility['level']}
                              />
                              <span style={{ color: 'white', paddingLeft: '10px', lineHeight: '22px' }}>Levels Column is Hidden</span>
                            </div>
                          </Menu.Item>
                          <Menu.Item key="row_labels">
                            <div style={menuItemStyle}>
                              <Checkbox
                                onClick={(e) => handleManageColumnsCheckBoxClick(e, 'row_labels')}
                                checked={columnVisibility['row_labels']}
                              >
                                <span style={{ color: 'white' }}>Row Labels
                                </span>
                              </Checkbox>
                              <span style={{ color: 'white' }}>
                                <MenuOutlined />
                              </span>
                            </div>
                          </Menu.Item>
                          <Menu.Item key="sum_of_consumption">
                            <div style={menuItemStyle}>
                              <Checkbox
                                onClick={(e) => handleManageColumnsCheckBoxClick(e, 'sum_of_consumption')}
                                checked={columnVisibility['sum_of_consumption']}
                              >
                                <span style={{ color: 'white' }}>Sum Of Consumption
                                </span>
                              </Checkbox>
                              <span style={{ color: 'white' }}>
                                <MenuOutlined />
                              </span>
                            </div>
                          </Menu.Item>
                          <Menu.Item key="scope_radio">
                            <div style={menuItemStyle}>

                              <Radio.Group onChange={(e)=> 
                                  setConsumptionAmountIndicatorType(e.target.value)
                                } value={consumptionAmountIndicatorType}>
                                <Radio value="location-based">Location</Radio>
                                <Radio value="market-based">Market</Radio>
                                <Radio value="uncategorized">Uncategorized</Radio>
                              </Radio.Group>
                              
                              <span style={{ color: 'white' }}>
                                &nbsp;
                              </span>
                            </div>
                          </Menu.Item>
                          <Menu.Item key="sum_of_total_location">
                            <div style={menuItemStyle}>
                              <Checkbox
                                onClick={(e) => handleManageColumnsCheckBoxClick(e, 'sum_of_total_location')}
                                checked={columnVisibility['sum_of_total_location']}
                              >
                                <span style={{ color: 'white' }}>Sum Of Total (All Scopes) Location
                                </span>
                              </Checkbox>
                              <span style={{ color: 'white' }}>
                                <MenuOutlined />
                              </span>
                            </div>
                          </Menu.Item>
                          <Menu.Item key="scope_1_location">
                            <div style={{ ...menuItemStyle, paddingLeft: '20px' }}>
                              <Checkbox
                                onClick={(e) => handleManageColumnsCheckBoxClick(e, 'scope_1_location')}
                                checked={columnVisibility['scope_1_location']}
                              >
                                <span style={{ color: 'white' }}>Scope 1
                                </span>
                              </Checkbox>
                              <span style={{ color: 'white' }}>
                                &nbsp;
                              </span>
                            </div>
                          </Menu.Item>
                          <Menu.Item key="scope_2_location">
                            <div style={{ ...menuItemStyle, paddingLeft: '20px' }}>
                              <Checkbox
                                onClick={(e) => handleManageColumnsCheckBoxClick(e, 'scope_2_location')}
                                checked={columnVisibility['scope_2_location']}
                              >
                                <span style={{ color: 'white' }}>Scope 2
                                </span>
                              </Checkbox>
                              <span style={{ color: 'white' }}>
                                &nbsp;
                              </span>
                            </div>
                          </Menu.Item>
                          <Menu.Item key="scope_3_location">
                            <div style={{ ...menuItemStyle, paddingLeft: '20px' }}>
                              <Checkbox
                                onClick={(e) => handleManageColumnsCheckBoxClick(e, 'scope_3_location')}
                                checked={columnVisibility['scope_3_location']}
                              >
                                <span style={{ color: 'white' }}>Scope 3
                                </span>
                              </Checkbox>
                              <span style={{ color: 'white' }}>
                                &nbsp;
                              </span>
                            </div>
                          </Menu.Item>
                          <Menu.Item key="sum_of_total_market">
                            <div style={menuItemStyle}>
                              <Checkbox
                                onClick={(e) => handleManageColumnsCheckBoxClick(e, 'sum_of_total_market')}
                                checked={columnVisibility['sum_of_total_market']}
                              >
                                <span style={{ color: 'white' }}>Sum Of Total (All Scopes) Markets
                                </span>
                              </Checkbox>
                              <span style={{ color: 'white' }}>
                                <MenuOutlined />
                              </span>
                            </div>
                          </Menu.Item>
                          <Menu.Item key="scope_1_market">
                            <div style={{ ...menuItemStyle, paddingLeft: '20px' }}>
                              <Checkbox
                                onClick={(e) => handleManageColumnsCheckBoxClick(e, 'scope_1_market')}
                                checked={columnVisibility['scope_1_market']}
                              >
                                <span style={{ color: 'white' }}>Scope 1
                                </span>
                              </Checkbox>
                              <span style={{ color: 'white' }}>
                                &nbsp;
                              </span>
                            </div>
                          </Menu.Item>
                          <Menu.Item key="scope_2_market">
                            <div style={{ ...menuItemStyle, paddingLeft: '20px' }}>
                              <Checkbox
                                onClick={(e) => handleManageColumnsCheckBoxClick(e, 'scope_2_market')}
                                checked={columnVisibility['scope_2_market']}
                              >
                                <span style={{ color: 'white' }}>Scope 2
                                </span>
                              </Checkbox>
                              <span style={{ color: 'white' }}>
                                &nbsp;
                              </span>
                            </div>
                          </Menu.Item>
                          <Menu.Item key="scope_3_market">
                            <div style={{ ...menuItemStyle, paddingLeft: '20px' }}>
                              <Checkbox
                                onClick={(e) => handleManageColumnsCheckBoxClick(e, 'scope_3_market')}
                                checked={columnVisibility['scope_3_market']}
                              >
                                <span style={{ color: 'white' }}>Scope 3
                                </span>
                              </Checkbox>
                              <span style={{ color: 'white' }}>
                                &nbsp;
                              </span>
                            </div>
                          </Menu.Item>
                          <Menu.Item key="sum_of_total_uncategorized">
                            <div style={menuItemStyle}>
                              <Checkbox
                                onClick={(e) => handleManageColumnsCheckBoxClick(e, 'sum_of_total_uncategorized')}
                                checked={columnVisibility['sum_of_total_uncategorized']}
                              >
                                <span style={{ color: 'white' }}>Sum Of Total (All Scopes) Uncategorized
                                </span>
                              </Checkbox>
                              <span style={{ color: 'white' }}>
                                <MenuOutlined />
                              </span>
                            </div>
                          </Menu.Item>
                          <Menu.Item key="scope_1_uncategorized">
                            <div style={{ ...menuItemStyle, paddingLeft: '20px' }}>
                              <Checkbox
                                onClick={(e) => handleManageColumnsCheckBoxClick(e, 'scope_1_uncategorized')}
                                checked={columnVisibility['scope_1_uncategorized']}
                              >
                                <span style={{ color: 'white' }}>Scope 1
                                </span>
                              </Checkbox>
                              <span style={{ color: 'white' }}>
                                &nbsp;
                              </span>
                            </div>
                          </Menu.Item>
                          <Menu.Item key="scope_2_uncategorized">
                            <div style={{ ...menuItemStyle, paddingLeft: '20px' }}>
                              <Checkbox
                                onClick={(e) => handleManageColumnsCheckBoxClick(e, 'scope_2_uncategorized')}
                                checked={columnVisibility['scope_2_uncategorized']}
                              >
                                <span style={{ color: 'white' }}>Scope 2
                                </span>
                              </Checkbox>
                              <span style={{ color: 'white' }}>
                                &nbsp;
                              </span>
                            </div>
                          </Menu.Item>
                          <Menu.Item key="scope_3_uncategorized">
                            <div style={{ ...menuItemStyle, paddingLeft: '20px' }}>
                              <Checkbox
                                onClick={(e) => handleManageColumnsCheckBoxClick(e, 'scope_3_uncategorized')}
                                checked={columnVisibility['scope_3_uncategorized']}
                              >
                                <span style={{ color: 'white' }}>Scope 3
                                </span>
                              </Checkbox>
                              <span style={{ color: 'white' }}>
                                &nbsp;
                              </span>
                            </div>
                          </Menu.Item>
                        </TableCustomMenu>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
        </FormWrapper>
      </FiltersContainer>
      <OverviewDataTable
        loadingTableData={loadingTableData}
        pivotDataList={pivotDataList}
        pagination={pagination}
        columnVisibility={columnVisibility}
        handle_pivot_table_row_click={handle_pivot_table_row_click}
        onChangePagination={handleChangePagination}
        companyDetails={companyDetails}
      />
    </PageWrap>
  );
};

export default BreakdownPivotTable;
