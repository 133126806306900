import React, { useState } from "react";
import {
  FileOutlined,
  EyeOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  MoreOutlined,
  LineChartOutlined,
  FundOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { useBoolean } from "ahooks";
import {
  Tooltip,
  Typography,
  Row,
  Drawer,
  Space,
  Col,
  Dropdown,
  Menu,
  Badge,
  Progress,
} from "antd";
import { purple } from "@ant-design/colors";
import {
  CommonTable,
  StyledButton,
  TableCommonMenu,
} from "../../../../shared/commonStyles";
import { stripHtmlTags } from "../../../../shared/commonFunctions";
import MassUploadDataSheet from "./MassUploadDataSheet";
import { useNavigate } from "react-router";
import { useCheckRolesPermissions } from "../../../../hooks/useCheckRolesPermissions";
import { encodeUrlName } from "../../../../shared/commonFunctions";

const { Title } = Typography;

const SheetSchemaDatatable = ({
  dataSheet,
  onFinishUploadInsertDataFile,
  onClickExportExcel,
  isDisabled,
  showCheckingModal,
  metaDataRecords,
  deletedCount,
  pendingCount,
  deniedCount,
  totalCount,
  exportLoader,
  setExportLoader,
  isPending,
  identifierColumn,
  filters,
}) => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const { checkRolesPermission } = useCheckRolesPermissions();
  const [isAllDescVisible, { toggle: toggleAllDescVisible }] =
    useBoolean(false);
  const columns = [
    {
      title: "Display Name",
      dataIndex: "display_name",
      width: "28%",
      editable: false,
    },
    {
      title: "Entity Name",
      dataIndex: "entity_name",
      width: "27%",
      editable: false,
    },
    {
      title: "Entity Unit",
      dataIndex: "unit_id",
      width: "15%",
      editable: false,
    },
    {
      title: "Data Type",
      dataIndex: "data_type",
      width: "15%",
      editable: false,
      render: (record) => {
        if (record === "number") {
          return "Number";
        } else if (
          record === "string" ||
          record === "text" ||
          record === "rich-text"
        ) {
          return "Text";
        } else if (record === "date") {
          return "Date";
        } else {
          return "";
        }
      },
    },
    {
      title: "Required",
      dataIndex: "required",
      width: "15%",
      editable: false,
      render: (record) => {
        return record ? (
          <Tooltip title={"Yes"}>
            <CheckCircleOutlined style={{ fontSize: 20, color: "#52c41a" }} />
          </Tooltip>
        ) : (
          <Tooltip title={"No"}>
            <CloseCircleOutlined style={{ fontSize: 20 }} />
          </Tooltip>
        );
      },
    },
  ];

  const onClickPanelHeader = (event) => {
    try {
      event.stopPropagation();
    } catch (e) {
      console.log("panel header clicked", e);
    }
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const moreButtons = (
    <TableCommonMenu>
      <Menu.Item key="data-schema">
        <StyledButton type="custom" onClick={showDrawer}>
          View Data Schema
        </StyledButton>
      </Menu.Item>
      <Menu.Item key="deleted">
        <StyledButton
          type="custom"
          onClick={() =>
            navigate(
              `/data/data-sheets/${dataSheet?._id?.$oid}/${encodeUrlName(
                dataSheet.sheet_name.replace("/", " ")
              )}/documents/deleted`
            )
          }
        >
          View Deleted Items
          <Badge
            className="margin-5"
            count={deletedCount}
            showZero
            style={{ backgroundColor: "#A68DFB" }}
          />
        </StyledButton>
      </Menu.Item>
      <Menu.Item key="pending">
        <StyledButton
          type="custom"
          onClick={() =>
            navigate(
              `/data/data-sheets/${dataSheet?._id?.$oid}/${encodeUrlName(
                dataSheet.sheet_name.replace("/", " ")
              )}/documents/pending`
            )
          }
        >
          View Pending Items
          <Badge
            className="margin-5"
            count={pendingCount}
            showZero
            style={{ backgroundColor: "#A68DFB" }}
          />
        </StyledButton>
      </Menu.Item>
      <Menu.Item key="denied">
        <StyledButton
          type="custom"
          onClick={() =>
            navigate(
              `/data/data-sheets/${dataSheet?._id?.$oid}/${encodeUrlName(
                dataSheet.sheet_name.replace("/", " ")
              )}/documents/denied`
            )
          }
        >
          View Denied Items
          <Badge
            className="margin-5"
            count={deniedCount}
            showZero
            style={{ backgroundColor: "#A68DFB" }}
          />
        </StyledButton>
      </Menu.Item>
      {metaDataRecords?.length > 0 && (
        <Menu.Item key="missing">
          <StyledButton type="custom" onClick={showCheckingModal}>
            Check missing records
          </StyledButton>
        </Menu.Item>
      )}
    </TableCommonMenu>
  );

  const exportButtons = (
    <TableCommonMenu>
      <Menu.Item key="report">
        <StyledButton
          type="custom"
          icon={<FileOutlined />}
          onClick={onClickExportExcel}
          disabled={isDisabled}
          loading={exportLoader}
        >
          Export Excel
        </StyledButton>
      </Menu.Item>
      <Menu.Item key="template">
        <MassUploadDataSheet
          dataSheet={dataSheet}
          type="dataSheet"
          onFinishUploadInsertDataFile={onFinishUploadInsertDataFile}
          showUploadExcelButton={false}
          showSampleExcelButton={true}
          identifierColumn={identifierColumn}
          setLoaderButton={setExportLoader}
        />
      </Menu.Item>
    </TableCommonMenu>
  );

  const handleClickEditDataSheet = () => {
    const sheetId = dataSheet?.["_id"]?.["$oid"];
    if (sheetId) {
      navigate(
        `/data/data-sheets/${sheetId}/${encodeUrlName(
          dataSheet?.sheet_name.replace("/", " ")
        )}/update`,
        { state: { type: "detailedPage" } }
      );
    }
  };

  return (
    <>
      <Drawer
        className="drawerCustom"
        title="Data Sheet Schema"
        width={700}
        onClose={onClose}
        visible={visible}
        placement="right"
        footerStyle={{ background: "#362e4b", borderTop: "none" }}
        footer={
          <Space>
            <StyledButton
              className="margin-5"
              type="custom"
              hovercolor="#A68DFB"
              hoverbgcolor="transparent"
              bgcolor="transparent"
              bordercolor="transparent"
              color="#fff"
              onClick={onClose}
            >
              Close
            </StyledButton>
            <StyledButton
              type="custom"
              className="color-white"
              onClick={onClose}
            >
              OK
            </StyledButton>
          </Space>
        }
      >
        <Row
          style={{ background: "#2D273F" }}
          justify="space-between"
          onClick={(event) => onClickPanelHeader(event)}
        >
          <Title className="color-white" level={4}>
            Data Sheet Schema
          </Title>
        </Row>

        <CommonTable
          loading={false}
          rowKey="id"
          dataSource={dataSheet?.sheet_schema}
          columns={columns}
          scroll={{ x: true }}
          pagination={false}
        />
      </Drawer>
      {dataSheet ? (
        <>
          <Row justify="space-between">
            <style jsx="true">
              {`
                .upload-list-inline {
                  display: inline-block;
                }
              `}
            </style>
            <Row style={{ width: "40%", alignItems: "baseline" }}>
              <Title className="color-white" level={4}>
                {dataSheet?.sheet_name}{" "}
              </Title>
              {""}
              <span
                style={{
                  color: " #C1BFCD",
                  fontSize: "16px",
                  marginLeft: "5px",
                }}
              >
                ({totalCount} records)
              </span>
              {""}
              <Progress
                percent={dataSheet.qualityScore?.percent}
                steps={3}
                strokeColor={[purple[2], purple[3], purple[4]]}
                trailColor="#4E4268"
                showInfo={false}
                style={{ margin: "0 10px" }}
              />
              {""}
              <span className="color-white" style={{ fontSize: "16px" }}>
                {dataSheet.qualityScore?.label || "N/A"}
              </span>
            </Row>
            <Col>
              {!isPending && !checkRolesPermission(["role:elevateduser"]) && (
                <StyledButton
                  className="margin-5"
                  type="custom"
                  icon={<EditOutlined />}
                  onClick={handleClickEditDataSheet}
                >
                  Data Sheet Settings
                </StyledButton>
              )}
              <Dropdown overlay={exportButtons} trigger={["click"]}>
                <StyledButton
                  type="custom"
                  className="margin-5"
                  icon={<FileOutlined />}
                  loading={exportLoader}
                >
                  Export
                </StyledButton>
              </Dropdown>
              {dataSheet?.is_standard_data && (
                <StyledButton
                  type="custom"
                  className="margin-5"
                  icon={<FundOutlined />}
                  disabled={isDisabled}
                  onClick={() =>
                    navigate(
                      `/data/data-sheets/${
                        dataSheet?._id?.$oid
                      }/${encodeUrlName(
                        dataSheet.sheet_name.replace("/", " ")
                      )}/documents/generate-report`,
                      {
                        state: {
                          dataLakeMapReport: [],
                          report_period: null,
                          location_id: null,
                          business_unit_id: null,
                          standardSheet: dataSheet,
                          filters,
                        },
                      }
                    )
                  }
                >
                  Generate Report
                </StyledButton>
              )}
              {!isPending && (
                <>
                  <MassUploadDataSheet
                    dataSheet={dataSheet}
                    type="dataSheet"
                    onFinishUploadInsertDataFile={onFinishUploadInsertDataFile}
                    showUploadExcelButton={true}
                    showSampleExcelButton={false}
                  />
                  {!checkRolesPermission(["role:elevateduser"]) && (
                    <Dropdown overlay={moreButtons} trigger={["click"]}>
                      <StyledButton
                        type="custom"
                        className="margin-5"
                        icon={<MoreOutlined />}
                      >
                        More
                      </StyledButton>
                    </Dropdown>
                  )}
                </>
              )}
            </Col>
          </Row>
          <Row
            style={
              isAllDescVisible
                ? { flexDirection: "column", alignItems: "start" }
                : { alignItems: "center" }
            }
          >
            <p
              style={
                isAllDescVisible
                  ? {
                      width: "50%",
                      fontSize: "16px",
                      marginRight: "0px",
                      color: " #C1BFCD",
                    }
                  : {
                      width: "50%",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      color: " #C1BFCD",
                      fontSize: "16px",
                      margin: "0px 5px 0px 0px",
                    }
              }
            >
              {isAllDescVisible ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: dataSheet.description,
                  }}
                ></div>
              ) : (
                stripHtmlTags(dataSheet.description)
              )}
            </p>{" "}
            {stripHtmlTags(dataSheet.description).length > 100 && (
              <StyledButton
                type="custom"
                onClick={() => toggleAllDescVisible()}
                hoverbgcolor="transparent"
                hovercolor="transparent"
                bgcolor="transparent"
                bordercolor="transparent"
                color="#A68DFB"
                height="10px"
                padding="0px"
                margin="0px"
              >
                {isAllDescVisible ? "Show less" : "Show more"}
              </StyledButton>
            )}
          </Row>
        </>
      ) : null}
    </>
  );
};

export default SheetSchemaDatatable;
