import { Badge, Dropdown, Menu, Space, message } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  FileTextOutlined,
  KeyOutlined,
  MoreOutlined,
  RollbackOutlined,
  UnorderedListOutlined,
  UserOutlined,
  BellOutlined, // Bell icon for the "Remind User" button
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { StyledButton, TableCommonMenu } from "../../../../shared/commonStyles";

import { DenyReasonModal } from "../Components/DenyReasonModal";
import { HistoryDataSection } from "../Components/HistoryDataSection";
import { NotesDrawer } from "../Components/NotesDrawer";
import ReasonDrawer from "../Components/ReasonDrawer/ReasonDrawer";
import { RolesDrawer } from "../Components/RolesDrawer";
import { StatusReasonModal } from "../Components/StatusReasonModal";
import { sendUserDenyReminder } from "../../../../services/dataSheet"; // Only importing sendUserDenyReminder
import styles from "./DataSheetDocuments.module.scss";
import { useBoolean } from "ahooks";
import { useCheckRolesPermissions } from "../../../../hooks/useCheckRolesPermissions";

interface ActionButtonsProps {
  record: any;
  columns: any;
  areNotesOn: boolean;
  isHistoryOn: boolean;
  onClickDataSheetFileBtn: (record: any) => void;
  onClickEditRecord: (record: any) => void;
  onClickRecordLockBtn: (record: any) => void;
  onChangeHistoryHandler: (record: DataSheetRecord, historyData: any) => void;
  onClickDeleteRestoreRecord: (
    record: DataSheetRecord,
    isArchived: boolean
  ) => void;
  onSubmit: (note: string) => void;
  loading: boolean;
  onUpdateNotes: (notes: Note[], record: any) => void;
  isDeleted: boolean;
  isPending: boolean;
  isDenied: boolean;
  onClickChangeApprovalStatusRecord: (
    record: any,
    status: RecordStatus,
    reason?: string,
    files?: File[]
  ) => void;
  onSubmitOwners: (
    record: DataSheetRecord,
    users: string[],
    groups: string[]
  ) => void;
  usersList: User[];
  groupsList: UserGroup[];
}

export const ActionButtons = ({
  record,
  columns,
  isHistoryOn,
  areNotesOn,
  onChangeHistoryHandler,
  onClickDataSheetFileBtn,
  onClickEditRecord,
  onClickDeleteRestoreRecord,
  onClickRecordLockBtn,
  onSubmit,
  isDeleted,
  isPending,
  isDenied,
  loading,
  onUpdateNotes,
  onClickChangeApprovalStatusRecord,
  onSubmitOwners,
  usersList,
  groupsList,
}: ActionButtonsProps) => {
  const [
    isNotesDrawerShown,
    { setTrue: showNotesDrawer, setFalse: hideNotesDrawer },
  ] = useBoolean(false);
  const [
    isReasonDrawerShown,
    { setTrue: showReasonDrawer, setFalse: hideReasonDrawer },
  ] = useBoolean(false);
  const [
    isRolesDrawerShown,
    { setTrue: showRolesDrawer, setFalse: hideRolesDrawer },
  ] = useBoolean(false);

  const [badgeCount, setBadgeCount] = useState({
    files: 0,
    owners: 0,
    notes: 0,
  });

  const { checkRolesPermission } = useCheckRolesPermissions();

  const handleAddNote = (note: string) => {
    hideNotesDrawer();
    onSubmit(note);
  };

  const handleSubmitOwners = (users: string[], groups: string[]) => {
    hideNotesDrawer();
    onSubmitOwners(record, users, groups);
  };

  const getFilesCount = async () => {
    const filesList = record?.filesList;
    const filesLength = filesList?.length || 0;
    setBadgeCount((prev) => {
      return { ...prev, files: filesLength };
    });
  };

  const getOwners = () => {
    const ownerUsers = record?.owner_users;
    const ownerGroups = record?.owner_groups || [];
    const ownersLength = ownerUsers?.length + ownerGroups?.length;
    ownersLength &&
      setBadgeCount((prev) => {
        return { ...prev, owners: ownersLength };
      });
  };

  const getNotes = () => {
    const notesLength = record?.notes?.length;
    notesLength &&
      setBadgeCount((prev) => {
        return { ...prev, notes: notesLength };
      });
  };

  const getBadges = () => {
    getFilesCount();
    getOwners();
    getNotes();
  };

  useEffect(() => {
    record?._id?.$oid && getBadges();
  }, [record]);

  const handleRemindUser = async () => {
    try {
      await sendUserDenyReminder(record._id.$oid); // Call to remind user
      message.success("User has been reminded successfully.");
    } catch (error) {
      message.error("Failed to remind the user.");
    }
  };

  const moreButtons = (
    <TableCommonMenu>
      <Menu.Item key="files">
        <StyledButton
          type="default"
          onClick={() => onClickDataSheetFileBtn(record)}
          icon={<FileTextOutlined />}
        >
          <span>Files</span>
          <Badge
            count={badgeCount.files}
            style={{ backgroundColor: "#A68DFB", marginLeft: "10px" }}
          />
        </StyledButton>
      </Menu.Item>
      {isHistoryOn && !isPending && (
        <Menu.Item key="history">
          <HistoryDataSection
            setIsOtherButtonsDisabled={() => {}}
            onChangeHistoryHandler={onChangeHistoryHandler}
            rowData={record}
            buttonText="View history"
            badgeComponent={
              <Badge
                count={record.revision}
                style={{ backgroundColor: "#A68DFB", marginLeft: "10px" }}
              />
            }
          />
        </Menu.Item>
      )}
      {!isPending && (
        <Menu.Item key="roles">
          {!checkRolesPermission(["role:elevateduser", "role:auditor"]) && (
            <StyledButton
              type="default"
              onClick={showRolesDrawer}
              icon={<UserOutlined />}
            >
              <span>Roles & Assignments</span>
              <Badge
                count={badgeCount.owners}
                style={{ backgroundColor: "#A68DFB", marginLeft: "10px" }}
              />
            </StyledButton>
          )}
        </Menu.Item>
      )}
      {areNotesOn && (
        <Menu.Item key="notes">
          <StyledButton
            type="default"
            onClick={showNotesDrawer}
            icon={<UnorderedListOutlined />}
          >
            <span>Notes</span>
            <Badge
              count={badgeCount.notes}
              style={{ backgroundColor: "#A68DFB", marginLeft: "10px" }}
            />
          </StyledButton>
        </Menu.Item>
      )}
      {/* Add Remind User button */}
      {isPending && isDenied && (
        <Menu.Item key="remind-user">
          <StyledButton
            type="default"
            onClick={handleRemindUser}
            icon={<BellOutlined />}
          >
            <span>Remind User</span>
          </StyledButton>
        </Menu.Item>
      )}
      {!isPending && (
        <>
          <Menu.Item key="isLock">
            {!checkRolesPermission(["role:elevateduser", "role:auditor"]) && (
              <StyledButton
                type="default"
                onClick={() => onClickRecordLockBtn(record)}
                icon={<KeyOutlined />}
              >
                <span>{record.isLocked ? "Unlock Record" : "Lock Record"}</span>
              </StyledButton>
            )}
          </Menu.Item>
          {!record.isLocked && (
            <Menu.Item key="delete">
              {!checkRolesPermission(["role:elevateduser", "role:auditor"]) && (
                <StyledButton
                  type="default"
                  onClick={() => onClickDeleteRestoreRecord(record, true)}
                  icon={<DeleteOutlined />}
                  disabled={record.reviewStatus === "DENIED"}
                >
                  <span>Delete</span>
                </StyledButton>
              )}
            </Menu.Item>
          )}
        </>
      )}
    </TableCommonMenu>
  );

  const actionDropdown = (
    <Dropdown overlay={moreButtons} trigger={["click"]}>
      <Badge
        dot={
          !!(
            badgeCount.files ||
            badgeCount.notes ||
            badgeCount.owners > 1 ||
            record.revision > 1
          )
        }
        color="volcano"
        offset={[-5, 5]}
      >
        <StyledButton type="default" icon={<MoreOutlined />}>
          Actions
        </StyledButton>
      </Badge>
    </Dropdown>
  );

  return (
    <>
      <Space direction="horizontal">
        {!isDeleted &&
          !isPending &&
          !isDenied &&
          !checkRolesPermission(["role:auditor"]) && (
            <StyledButton
              className={styles.editIconButton}
              type="default"
              onClick={() => onClickEditRecord(record)}
              icon={<EditOutlined />}
              disabled={record.isLocked}
            >
              <span>Edit</span>
            </StyledButton>
          )}
        {checkRolesPermission(["role:auditor"]) ? (
          <>{!isPending && <>{actionDropdown}</>} </>
        ) : (
          <>{!isDeleted && <>{actionDropdown}</>}</>
        )}

        {/* Show Approve/Deny/Delete buttons for pending records if not auditor */}
        {isPending &&
          (!isDenied || record.reviewStatus === "PENDING") &&
          !checkRolesPermission(["role:auditor"]) && (
            <>
              <StyledButton
                type="default"
                onClick={() =>
                  onClickChangeApprovalStatusRecord(
                    record,
                    RecordStatus.APPROVED
                  )
                }
                icon={<CheckCircleOutlined />}
              >
                Approve
              </StyledButton>
              <DenyReasonModal
                onConfirm={(reason, files) =>
                  onClickChangeApprovalStatusRecord(
                    record,
                    RecordStatus.DENIED,
                    reason,
                    files
                  )
                }
                button={{
                  icon: <CloseCircleOutlined />,
                  text: "Deny",
                  type: "default",
                }}
              />
              <StyledButton
                type="default"
                onClick={() => onClickDeleteRestoreRecord(record, true)}
                icon={<DeleteOutlined />}
              >
                <span>Delete</span>
              </StyledButton>
            </>
          )}

        {/* Show "View Reason" button and "Delete" button for DENIED records when reviewStatus is DENIED */}
        {isDenied &&
          record.reviewStatus === "DENIED" &&
          !checkRolesPermission(["role:auditor"]) && (
            <>
              <StyledButton
                type="default"
                icon={<EyeOutlined />}
                onClick={showReasonDrawer}
              >
                View reason
              </StyledButton>
              {/* Conditionally show Delete button only if isPending is not true */}
              {!isPending && (
                <StyledButton
                  type="default"
                  onClick={() => onClickDeleteRestoreRecord(record, true)}
                  icon={<DeleteOutlined />}
                >
                  <span>Delete</span>
                </StyledButton>
              )}
            </>
          )}

        {isDeleted && (
          <StyledButton
            type="default"
            onClick={() => onClickDeleteRestoreRecord(record, false)}
            icon={<RollbackOutlined />}
            disabled={checkRolesPermission(["role:auditor"])}
          >
            <span>Restore</span>
          </StyledButton>
        )}
      </Space>

      <NotesDrawer
        visible={isNotesDrawerShown}
        data={[
          record?.[record?.sheet_name][columns[0].dataIndex],
          record?.[record?.sheet_name][columns[1].dataIndex],
        ]}
        loading={loading}
        notes={record.notes || []}
        onClose={hideNotesDrawer}
        onSubmit={handleAddNote}
        onUpdateNotes={(value) => onUpdateNotes(value, record)}
      />
      <RolesDrawer
        visible={isRolesDrawerShown}
        data={record?._id?.$oid}
        ownerUsers={record.owner_users}
        ownerGroups={record.owner_groups || []}
        onClose={hideRolesDrawer}
        onSubmit={handleSubmitOwners}
        usersList={usersList}
        groupsList={groupsList}
      />
      <ReasonDrawer
        visible={isReasonDrawerShown}
        data={record?._id?.$oid}
        onClose={hideReasonDrawer}
        reasons={
          record?.statusReasons?.filter(
            (v: any) => v.review_status === RecordStatus.DENIED
          ) || []
        }
      />
    </>
  );
};
