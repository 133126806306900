import React, { useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import App from "./App";
import { API, graphqlOperation } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import { datalake } from "./graphql/queries";
import * as serviceWorker from "./serviceWorker";
import MarketplacePage from "./pages/marketplacePage/MarketplacePage";
import LearningPage from "./pages/learningPage/LearningPage";
import { PrivateRoute } from "./components/PrivateRoute/PrivateRoute";
import GoalsAndProgressPageNew from "./pages/goalsProgressPageNew";
import CreateSdgGoal from "./pages/goalsProgressPageNew/manageGoals/manageSdg";
import DataSheetsPage from "./pages/dataSheetsPage/DataSheetsPage";

import DataSheetDocumentsPage from "./pages/dataSheetsPage/dataSheetDocumentsPage/DataSheetDocumentsPage";
import DashboardChartsPage from "./pages/dashboardChartsPage/DashboardChartsPage";
import AuditStandardsPage from "./pages/auditStandardsPage/AuditStandardsPage";
import StandardsDataTable from "./pages/auditStandardsPage/Components/StandardsDataTable";
import ProductDetailsPage from "./pages/carbonPage/CarbonOffsetsProducts/ProductDetailsPage/ProductDetailsPage";
import ProductOrderPage from "./pages/carbonPage/CarbonOffsetsProducts/ProductOrderPage/ProductOrderPage";
import CarbonOffsetsProducts from "./pages/carbonPage/CarbonOffsetsProducts/CarbonOffsetsProducts";
import CarbonOffsetOrders from "./pages/carbonPage/CarbonOffsetOrders/CarbonOffsetOrders";

import UserAccountPageNew from "./pages/settingsPage/userAccountPageNew";
import UserManagementPage from "./pages/settingsPage/userManagementPage/UserManagementPage";
import UserGroupsPage from "./pages/settingsPage/userGroupsPage/UserGroupsPage";
import Management from "./pages/Management";
import Data from "./pages/Data";
import { ContentSection } from "./shared/commonStyles.js";
import Calculator from "./pages/carbonPage/Calculator/Calculator";
import { Calculations } from "./pages/carbonPage/Calculations/Calculations";
import CalculationStepper from "./pages/carbonPage/CalculationStepper/CalculationStepper";
import { WhatIfOnboarding } from "./pages/carbonPage/WhatIfOnboarding";
import { CustomFactors } from "./pages/carbonPage/CustomFactors/CustomFactors";
import Reporting, {
  ReportingProvider,
  Standard,
  StandardOverview,
  StandardSettings,
} from "./pages/Reporting";
import { ReportingPageProvider } from "./pages/reportingPage/context/ReportingPageContext";
import GenerateReportPage from "./pages/reportingPage/generateReportPage/GenerateReport";
import ProtectPage from "./pages/reportingPage/ProtectPage";
import OverviewWrapper from "./pages/carbonPage/Overview/OverviewWrapper";
import Analytics from "./pages/Analytics";
import Carbon from "./pages/Carbon";
import Audit from "./pages/Audit";
import NewsPage from "./pages/newsPage/NewsPage";

import AuditDataSheetsPage from "./pages/auditDataSheetsPage/AuditDataSheetsPage";
import AuditDataSheetDocuments from "./pages/auditDataSheetsPage/auditDataSheetDocuments/AuditDataSheetDocuments";
import CreateStandardsSurvey from "./pages/createStandardsSurvey";
import CollectData from "./pages/collectData";
import DocumentReview from "./pages/documentReview";
import ManageDocumentReview from "./pages/documentReview/Manage";
import DeploySurvey from "./pages/deploySurvey";
import ConnectUtilitiesPage from "./pages/connectUtilitiesPage";
import CreateDataSheetSurvey from "./pages/createDataSheetSurvey";
import CreateMixedSurvey from "./pages/createMixedSurvey";
import { StandardProgressPage } from "./pages/Reporting/StandardProgressPage/StandardProgressPage";
import ViewSurveyForAdmin from "./pages/viewSurveyForAdmin";
import { CalculationsAudit } from "./pages/carbonPage/CalculationsAudit/CalculationsAudit";
import CalculatorAudit from "./pages/carbonPage/CalculatorAudit/CalculatorAudit";

import UpdateStandard from "./pages/UpdateStandard/UpdateStandard";
import UpdateAuditStandard from "./pages/UpdateAuditStandard";
import { ReductionPlan } from "./pages/carbonPage/ReductionPlan/ReductionPlan";
import { SingleTarget } from "./pages/carbonPage/ReductionPlan/Components/SingleTarget/SingleTarget";
import { EmissionLibrary } from "./pages/carbonPage/EmissionLibrary";
import { EmissionLibraryPage } from "./pages/carbonPage/EmissionLibraryPage";
import AnalyticsManageDashboards from "./pages/analyticsManageChartPage/AnalyticsManageDashboards";
import CreateDataSheet from "./pages/createDataSheet";
import UpdateDataSheet from "./pages/updateDataSheet";
import ImportDataPage from "./pages/importDataPage/ImportDataPage";
import ImportNewData from "./pages/importNewData";
import CustomStandard from "./pages/addCustomStandard";
import EsgStandards from "./pages/EsgStandards";
import ReportBuilder from "./pages/reportBuilder/ReportBuilder";
import ReportViewer from "./pages/reportViewer/index";
import ReportViewerDetail from "./pages/reportViewer/ReportViewerDetail";
import KpiData from "./pages/kpi";
import KpiDetailPage from "./pages/kpi/kpiDetailPage";
import CreateKpi from "./pages/createKpi";
import { useCheckRolesPermissions } from "./hooks/useCheckRolesPermissions";
import { UnitsManagement } from "./pages/Data/UnitsManagement";
//import Ratings from "./pages/Ratings";
import EsgHandbookPage from "./pages/esgHandbookPage/EsgHandbookPage";
import ManageAudits from "./pages/ManageAudits";
import ImprovementRecommendations from "./pages/improvementRecommendations";
import CreateAudit from "./pages/CreateAudit";
import ViewAudit from "./pages/ViewAudit";
import ManageDeletedGoals from "./pages/goalsProgressPageNew/DeletedGoals";
import { UnitsTable } from "./pages/Data/UnitsManagement/Components/UnitsTable";
import Ai from "./pages/Ai";
import { UpdateRecords } from "./pages/carbonPage/Calculator/Components/UpdateRecords/UpdateRecords";
import CreateReport from "./pages/CreateReport";
import { ReportTemplatePage } from "./pages/CreateReport/ReportTemplate/ReportTemplatePage";
import ToolsPage from "./pages/ToolsPage";
import ViewDocument from "./pages/documentReview/userView";
import UpdateDataSheetsRecords from "./pages/dataSheetsPage/dataSheetDocumentsPage/UpdateDataSheetsRecords/UpdateDataSheetsRecords";
import { AddUsers } from "./pages/collectData/Components/AddUsers";
import { SetupDataQualityScore } from "./pages/dataSheetsPage/dataSheetDocumentsPage/SetupDataQualityScore";
import { ManageUnitConversions } from "./pages/Data/UnitsManagement/Components/ManageUnitConversions/ManageUnitConversions";
import { MassImportData } from "./pages/MassImportData/MassImportData";
import { CreateKPIsSurvey } from "./pages/createKPIsSurvey/CreateKPIsSurvey";
import { SustainabilityStandardContainer } from "./pages/Reporting/SustainabilityStandards/Container/SustainabilityStandardContainer";
import { ReleaseNotesPageContainer } from "./pages/settingsPage/releaseNotesPage/Container/ReleaseNotesPage";
import { NotificationsPageContainer } from "./pages/settingsPage/notificationsPage/Container/NotificationsPage";
import { PendingKPIs } from "./pages/kpi/PendingKPIs/PendingKPIs";
import { CreateStandardsDataSheet } from "./pages/createDataSheet/CreateStandardsDataSheet";
import { CreateKPIDataSheet } from "./pages/createDataSheet/CreateKPIDataSheet";

let analyticsCharts: any;

export const routes = [
  {
    path: "/management",
    breadcrumb: "Management",
  },
  {
    path: "/management/goals-and-progress",
    breadcrumb: "Goals & Progress",
  },
  {
    path: "/management/goals-and-progress/deleted",
    breadcrumb: "Deleted Goals",
  },
  {
    path: "/management/releaseNotes",
    breadcrumb: "Release Notes",
  },
  {
    path: "/management/notifications",
    breadcrumb: "Notification Center",
  },
  {
    path: "/management/goals-and-progress/create",
    breadcrumb: "Create SDG",
  },
  {
    path: "/management/goals-and-progress/:sdg_id/update",
    breadcrumb: "Update SDG",
  },
  {
    path: "/management/creata-task",
    breadcrumb: "Create a task",
  },

  {
    path: "/management/news-feed",
    breadcrumb: "News Feed",
  },
  {
    path: "/management/learning-center",
    breadcrumb: "Learning Center",
  },
  {
    path: "/management/documents",
    breadcrumb: "Documents",
  },
  {
    path: "/management/news-feed",
    breadcrumb: "News Feed",
  },
  {
    path: "/management/collect-data",
    breadcrumb: "Collect Data",
  },
  {
    path: "/reporting/document-review",
    breadcrumb: "Document Review",
  },
  {
    path: "/reporting/document-review/view",
    breadcrumb: "Real Time Collaboration",
  },
  {
    path: "/reporting/document-review/create",
    breadcrumb: "Create New Document Review",
  },
  {
    path: "/reporting/document-review/:document_id/:document_name/view",
    breadcrumb: "Documents",
  },
  {
    path: "/reporting/document-review/:document_id/:document_name/update",
    breadcrumb: "Documents",
  },

  {
    path: " /management/collect-quantitative-data/:sheet_id/documents",
    breadcrumb: "Documents",
  },

  {
    path: "/data",
    breadcrumb: "Data",
  },
  {
    path: "/data/data-sheets",
    breadcrumb: "Data Sheets",
  },
  {
    path: "/data/data-sheets/create-audit",
    breadcrumb: "Create Audit",
  },
  {
    path: "/audit/manage/:audit_id/update-audit",
    breadcrumb: "Update Audit",
  },
  {
    path: "/audit/manage/:audit_id/:audit_name/view",
    breadcrumb: "View Audit",
  },
  {
    path: "/data/data-sheets/deleted",
    breadcrumb: "Deleted Data Sheets",
  },
  {
    path: "/reporting",
    breadcrumb: "Reporting",
  },
  {
    path: "/data/data-sheets/:data_sheet_id/:sheet_name/documents",
    breadcrumb: "Documents",
  },
  {
    path: "/data/data-sheets/:data_sheet_id/:sheet_name/documents/update-records",
    breadcrumb: "Update records",
  },
  {
    path: "/data/data-sheets/:data_sheet_id/:sheet_name/documents/deleted",
    breadcrumb: "Deleted Documents",
  },
  {
    path: "/data/data-sheets/:data_sheet_id/:sheet_name/documents/denied",
    breadcrumb: "Denied Documents",
  },
  {
    path: "/data/data-sheets/:data_sheet_id/:sheet_name/documents/pending",
    breadcrumb: "Pending Documents",
  },
  {
    path: "/data/data-sheets/:data_sheet_id/:sheet_name/documents/data-quality-score-setup",
    breadcrumb: "Setup data quality scoring",
  },

  // Import Data
  {
    path: "/data/import-data",
    breadcrumb: "Import Data",
  },
  {
    path: "/data/mass-data-import",
    breadcrumb: "Mass Data Import",
  },
  {
    path: "/data/import-data/:import_name/review",
    breadcrumb: "Review Mass Data Import",
  },
  {
    path: "/data/import-data/:import_name/update",
    breadcrumb: "Update Mass Data Import",
  },
  {
    path: "/data/import-data/import-new-data",
    breadcrumb: "Import New Data",
  },
  {
    path: "/data/import-data/:import_id/:import_name/update",
    breadcrumb: "Update Import Data",
  },
  {
    path: "/data/import-data/:import_id/:import_name/review",
    breadcrumb: "Review Import Data",
  },
  //  Units Management page
  {
    path: "/data/units-management",
    breadcrumb: "Units Management",
  },
  {
    path: "/data/units-management/manage-unit-conversions",
    breadcrumb: "Manage unit conversions",
  },
  {
    path: "/data/units-management/unit",
    breadcrumb: "Unit",
  },
  {
    path: "/reporting/standards-overview",
    breadcrumb: "Standards Overview",
  },
  {
    path: "/reporting/standards-progress",
    breadcrumb: "Standards Progress",
  },

  {
    path: "/reporting/sustainability-standards",
    breadcrumb: "Sustainability Standards",
  },
  {
    path: "/reporting/sustainability-standards/create-audit",
    breadcrumb: "Create Audit",
  },
  {
    path: "/reporting/sustainability-standards/deleted-standards",
    breadcrumb: "Deleted Standards",
  },
  {
    path: "/reporting/sustainability-standards/generate-report",
    breadcrumb: "Generate Report",
  },
  {
    path: "/data/data-sheets/:data_sheet_id/:sheet_name/documents/generate-report",
    breadcrumb: "Generate Report",
  },

  {
    path: "/reporting/data-integrity",
    breadcrumb: "Data Integrity",
  },
  {
    path: "/management/collect-data-sheets-survey",
    breadcrumb: "Collect Data Sheets Survey",
  },
  {
    path: "/reporting/custom-standard/create",
    breadcrumb: "Create Custom Standard",
  },
  {
    path: "/reporting/custom-standard/:custom_standard_id/update",
    breadcrumb: "Update Custom Standard",
  },
  {
    path: "/reporting/saved-reports/:report_id/:report_name/view",
    breadcrumb: "Detail",
  },
  {
    path: "/reporting/saved-reports/:report_id/:report_name/update",
    breadcrumb: "Update",
  },
  {
    path: "/reporting/custom-standard",
    breadcrumb: "Custom Standard",
  },
  {
    path: "/reporting/create-report",
    breadcrumb: "Create Report",
  },
  {
    path: "/reporting/create-report/report-template",
    breadcrumb: "Report Template",
  },
  {
    path: "/reporting/report-builder",
    breadcrumb: "Report Builder",
  },
  {
    path: "/reporting/saved-reports",
    breadcrumb: "Saved Reports",
  },

  {
    path: "/reporting/generate-standards-report",
    breadcrumb: "Generate Standards Report",
  },
  {
    path: "/reporting/portfolio-companies",
    breadcrumb: "Portfolio Companies",
  },
  {
    path: "/carbon",
    breadcrumb: "Carbon",
  },
  {
    path: "/carbon/create-audit",
    breadcrumb: "Create Audit",
  },
  {
    path: "/carbon/overview",
    breadcrumb: "Carbon Overview",
  },
  {
    path: "/carbon/calculations",
    breadcrumb: "Calculations",
  },
  {
    path: "/carbon/new-calculation",
    breadcrumb: "Create new calculation",
  },

  {
    path: "/carbon/calculator",
    breadcrumb: "Carbon Calculator",
  },
  {
    path: "/carbon/calculation-name",
    breadcrumb: "Calculation Name",
  },
  {
    path: "/carbon/calculations/:id/update-records",
    breadcrumb: "Update records",
  },
  {
    path: "/carbon/calculations/:id/deleted-items",
    breadcrumb: "Deleted Items",
  },
  {
    path: "/carbon/automated-calculation",
    breadcrumb: "Automated Calculation",
  },
  {
    path: "/carbon/reduction-plan",
    breadcrumb: "Reduction plan",
  },
  {
    path: "/carbon/reduction-plan/target-name",
    breadcrumb: "Target details",
  },
  {
    path: "/carbon/reduction-plan/onboarding-flow",
    breadcrumb: "Onboarding flow",
  },
  {
    path: "/carbon/carbon-offsets-orders",
    breadcrumb: "Carbon Offsets Orders",
  },
  {
    path: "/carbon/carbon-offsets",
    breadcrumb: "Carbon Offsets",
  },
  {
    path: "/carbon/carbon-offsets/:id/details",
    breadcrumb: "Details",
  },
  {
    path: "/carbon/carbon-offsets/:id/new-order",
    breadcrumb: "New Order",
  },
  {
    path: "/carbon/custom-factors",
    breadcrumb: "Custom Factors",
  },
  {
    path: "/carbon/emission-library",
    breadcrumb: "Factors Library",
  },
  {
    path: "/audit",
    breadcrumb: "Audit",
  },
  {
    path: "/audit/manage",
    breadcrumb: "Manage Audit",
  },
  {
    path: "/audit/manage/deleted",
    breadcrumb: "Deleted Audits",
  },
  {
    path: "/audit/manage-data-sheet-audits",
    breadcrumb: "Manage Data Sheet Audits",
  },

  {
    path: "/audit/audit-standards",
    breadcrumb: "Audit Standards",
  },
  {
    path: "/audit/audit-data-sheets",
    breadcrumb: "Audit Data Sheets",
  },
  {
    path: "/audit/audit-data-sheets/:audit_id/:sheet_name/documents",
    breadcrumb: "Documents",
  },
  {
    path: "/audit/calculations-audit",
    breadcrumb: "Calculations Audit",
  },
  {
    path: "/analytics",
    breadcrumb: "Analytics",
  },
  // Connect Utilities
  {
    path: "/data/connect-utilities",
    breadcrumb: "Connect Utilities",
  },
  // Connect API
  {
    path: "/data/connect-api",
    breadcrumb: "Connect API",
  },
  {
    path: "/management/user-account",
    breadcrumb: "Your Account",
  },
  {
    path: "/management/user-management",
    breadcrumb: "User Management",
  },
  {
    path: "/management/user-groups",
    breadcrumb: "User Groups",
  },
  {
    path: "/analytics/:id",
    breadcrumb: async (match: any) => {
      if (!analyticsCharts) {
        const _analysisCharts: any = await API.graphql(
          graphqlOperation(datalake, {
            request_type: "get_dashboard_charts",
            request_payload: JSON.stringify({
              filter: { section: "Dashboard" },
            }),
          })
        );

        const { data } = JSON.parse(_analysisCharts["data"]["datalake"]);
        analyticsCharts = Array.isArray(data) ? data : [];
      }

      const id = match[":id"];
      const result = analyticsCharts.find(
        (chart: any) => chart?._id?.$oid == id
      );
      return result ? result?.chart_name : "Manage Dashboards";
    },
  },
  {
    path: "/management/collect-data/create-standards-survey",
    breadcrumb: "Create Standards survey",
  },
  {
    path: "/management/collect-data/create-datasheet-survey",
    breadcrumb: "Create Data Sheet Survey",
  },
  {
    path: "/management/collect-data/create-mixed-survey",
    breadcrumb: "Create Mixed Survey",
  },
  {
    path: "/management/collect-data/create-kpi-survey",
    breadcrumb: "Create KPIs Survey",
  },
  {
    path: "/reporting/standards-progress-detail",
    breadcrumb: "Standards Progress",
  },
  {
    path: "/management/collect-data/:survey_id/update-standards-survey",
    breadcrumb: "Update Standards Survey",
  },
  {
    path: "/management/collect-data/:survey_id/update-datasheet-survey",
    breadcrumb: "Update Data Sheet Survey",
  },
  {
    path: "/management/collect-data/:survey_id/update-mixed-survey",
    breadcrumb: "Update Mixed Survey",
  },
  {
    path: "/management/collect-data/:survey_id/update-kpi-survey",
    breadcrumb: "Update KPIs Survey",
  },
  {
    path: "/reporting/settings",
    breadcrumb: "Settings",
  },
  {
    path: "/management/collect-data/:survey_id/:survey_name/view-survey",
    breadcrumb: "Survey",
  },
  {
    path: "/management/collect-data/:survey_id/:survey_name/deploy-survey",
    breadcrumb: "Deploy Survey",
  },
  {
    path: "/management/collect-data/:survey_id/:survey_name/deploy-survey/add-users-manually",
    breadcrumb: "Add users manually",
  },
  {
    path: "/management/collect-data/:survey_id/:survey_name/deploy-survey/import-users",
    breadcrumb: "Import users from file",
  },
  {
    path: "/reporting/sustainability-standards/:standard_id/:standard_name/update",
    breadcrumb: "Update Standards",
  },
  {
    path: "/audit/audit-standards/:audit_id/:audit_name/detail",
    breadcrumb: "Audit Standards Detail",
  },
  {
    path: "/audit/audit-standards/:audit_id/:audit_name/:standard_id/detail",
    breadcrumb: "Update Audit Standard",
  },
  {
    path: "/audit/manage/:audit_id/:standard_id/:standard_name/update",
    breadcrumb: "Update Audit Standards",
  },
  {
    path: "/analytics/manage-dashboards",
    breadcrumb: "Dashboards",
  },
  {
    path: "/data/pending-items",
    breadcrumb: "Pending Items",
  },
  {
    path: "/data/data-sheets/create-regular-data-sheet",
    breadcrumb: "Create a Regular Data Sheet",
  },
  {
    path: "/data/data-sheets/create-standards-data-sheet",
    breadcrumb: "Create a Standards Data Sheet",
  },
  {
    path: "/data/data-sheets/create-kpi-data-sheet",
    breadcrumb: "Create a KPI Data Sheet",
  },
  {
    path: "/data/data-sheets/create-reference-data-sheet",
    breadcrumb: "Create a Reference Data Sheet",
  },
  {
    path: "/data/data-sheets/duplicate",
    breadcrumb: "Duplicate Data Sheet",
  },
  {
    path: "/data/data-sheets/:sheet_id/:sheet_name/update",
    breadcrumb: "Update Data Sheet",
  },
  {
    path: "/data/kpi",
    breadcrumb: "KPIs",
  },
  {
    path: "/data/pending-kpis",
    breadcrumb: "Pending KPIs",
  },
  {
    path: "/data/kpi/:sheet_id/:sheet_name/create",
    breadcrumb: "Create KPI",
  },
  {
    path: "/data/kpi/create",
    breadcrumb: "Create KPI",
  },
  {
    path: "/data/kpi/:kpi_id/:kpi_name/update",
    breadcrumb: "Update KPI",
  },
  {
    path: "data/kpi/:data_sheet_id/:sheet_name/details",
    breadcrumb: "KPIs Details",
  },
  // {
  //   path: "/ratings",
  //   breadcrumb: "Ratings",
  // },
  // {
  //   path: "/ratings/company-ratings",
  //   breadcrumb: "Company Ratings",
  // },
  {
    path: "/tools/esg-handbook",
    breadcrumb: "ESG Handbook",
  },
  {
    path: "/tools/ai",
    breadcrumb: "AI",
  },
  {
    path: "/tools/improvement-recommedations",
    breadcrumb: "Improvement Recommendations",
  },
  {
    path: "/tools",
    breadcrumb: "Tools",
  },
];

export const AllRoutes = () => {
  const { checkRolesPermission } = useCheckRolesPermissions();

  return (
    <Routes>
      <Route key="home" path="/home" element={<Management />} />

      <Route
        path="*"
        key="management-all"
        element={
          checkRolesPermission(["role:user"]) ? (
            <CollectData />
          ) : checkRolesPermission(["role:auditor"]) ? (
            <Audit />
          ) : (
            <Management />
          )
        }
      />
      <Route
        key="management"
        path="/management"
        element={
          checkRolesPermission(["role:user"]) ? (
            <CollectData />
          ) : (
            <PrivateRoute permissionKey={"management-page"}>
              <Management />
            </PrivateRoute>
          )
        }
      />
      <Route
        key="data"
        path="/data"
        element={
          <PrivateRoute permissionKey={"data-page-with-elevateduser"}>
            <Data />
          </PrivateRoute>
        }
      />
      <Route
        path="/management/goals-and-progress"
        key="goals-and-progress"
        element={
          <PrivateRoute permissionKey={"goals-and-progress-page"}>
            <GoalsAndProgressPageNew />
          </PrivateRoute>
        }
      />
      <Route
        path="/management/goals-and-progress/create"
        key="goals-and-progress"
        element={
          <PrivateRoute permissionKey={"goals-and-progress-page"}>
            <CreateSdgGoal />
          </PrivateRoute>
        }
      />
      <Route
        path="/management/goals-and-progress/:sdg_id/update"
        key="goals-and-progress"
        element={
          <PrivateRoute permissionKey={"goals-and-progress-page"}>
            <CreateSdgGoal />
          </PrivateRoute>
        }
      />
      <Route
        path="/management/goals-and-progress/deleted"
        key="goals-and-progress"
        element={
          <PrivateRoute permissionKey={"goals-and-progress-page"}>
            <ManageDeletedGoals />
          </PrivateRoute>
        }
      />

      <Route
        path="/management/learning-center"
        key="learn"
        element={
          <PrivateRoute permissionKey={"learn-page"}>
            <LearningPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/management/news-feed"
        key="news-feed"
        element={
          <PrivateRoute permissionKey={"management-page/news-feed-view"}>
            <NewsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/management/collect-data"
        key="collect-data"
        element={
          <PrivateRoute
            permissionKey={"management-page/flows-view"}
            moduleKey={"DATA_SURVEYS"}
          >
            <CollectData />
          </PrivateRoute>
        }
      />
      <Route
        path="/reporting/document-review"
        key="/reporting/document-review"
        element={
          <PrivateRoute permissionKey={"management-page/flows-view"}>
            <DocumentReview />
          </PrivateRoute>
        }
      />
      <Route
        path="/reporting/document-review/create"
        key="/reporting/document-review/create"
        element={
          <PrivateRoute permissionKey={"management-page/flows-view"}>
            <ManageDocumentReview />
          </PrivateRoute>
        }
      />
      <Route
        path="/reporting/document-review/:document_id/:document_name/view"
        key="/reporting/document-review/:document_id/:document_name/view"
        element={
          <PrivateRoute permissionKey={"management-page/flows-view"}>
            <ViewDocument />
          </PrivateRoute>
        }
      />
      <Route
        path="/reporting/document-review/:document_id/:document_name/update"
        key="/reporting/document-review/:document_id/:document_name/update"
        element={
          <PrivateRoute permissionKey={"management-page/flows-view"}>
            <ManageDocumentReview />
          </PrivateRoute>
        }
      />

      <Route
        path="/management/collect-data/:survey_id/:survey_name/deploy-survey"
        key="deploy-survey"
        element={
          <PrivateRoute
            permissionKey={"management-page/flows-view"}
            moduleKey={"DATA_SURVEYS"}
          >
            <DeploySurvey />
          </PrivateRoute>
        }
      />
      <Route
        path="/management/collect-data/:survey_id/:survey_name/deploy-survey/add-users-manually"
        key="deploy-survey"
        element={
          <PrivateRoute
            permissionKey={"management-page/flows-view"}
            moduleKey={"DATA_SURVEYS"}
          >
            <AddUsers />
          </PrivateRoute>
        }
      />
      <Route
        path="/management/collect-data/:survey_id/:survey_name/deploy-survey/import-users"
        key="deploy-survey"
        element={
          <PrivateRoute
            permissionKey={"management-page/flows-view"}
            moduleKey={"DATA_SURVEYS"}
          >
            <AddUsers />
          </PrivateRoute>
        }
      />
      <Route key="reporting" path="/reporting" element={<Reporting />} />
      <Route
        path="/data/data-sheets"
        key="data-sheets"
        element={
          <PrivateRoute
            permissionKey={"data-sheets-page"}
            moduleKey={"DATA_SHEETS"}
          >
            <DataSheetsPage isDeleted={false} />
          </PrivateRoute>
        }
      />
      <Route
        path="/data/data-sheets/deleted"
        key="data-sheets"
        element={
          <PrivateRoute
            permissionKey={"data-sheets-page"}
            moduleKey={"DATA_SHEETS"}
          >
            <DataSheetsPage isDeleted />
          </PrivateRoute>
        }
      />
      <Route
        path="/data/kpi"
        key="/data/kpi"
        element={
          <PrivateRoute
            permissionKey={"data-sheets-page"}
            moduleKey={"DATA_KPIS"}
          >
            <KpiData />
          </PrivateRoute>
        }
      />
      <Route
        path="/data/pending-kpis"
        key="/data/pending-kpis"
        element={
          <PrivateRoute
            permissionKey={"data-sheets-page"}
            moduleKey={"DATA_KPIS"}
          >
            <PendingKPIs />
          </PrivateRoute>
        }
      />
      <Route
        path="/data/kpi/:data_sheet_id/:sheet_name/details"
        key="/data/kpi/:data_sheet_id/:sheet_name/details"
        element={
          <PrivateRoute permissionKey={"data-sheets-page"}>
            <KpiDetailPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/data/kpi/:sheet_id/:sheet_name/create"
        key="/data/kpi/:sheet_id/:sheet_name/create"
        element={
          <PrivateRoute
            permissionKey={"data-sheets-page"}
            moduleKey={"DATA_SHEETS"}
          >
            <CreateKpi />
          </PrivateRoute>
        }
      />
      <Route
        path="/data/kpi/:kpi_id/:kpi_name/update"
        key="/data/kpi/:kpi_id/:kpi_id/update"
        element={
          <PrivateRoute
            permissionKey={"data-sheets-page"}
            moduleKey={"DATA_SHEETS"}
          >
            <CreateKpi />
          </PrivateRoute>
        }
      />
      <Route
        path="/data/kpi/create"
        key="/data/kpi/create"
        element={
          <PrivateRoute
            permissionKey={"data-sheets-page"}
            moduleKey={"DATA_SHEETS"}
          >
            <CreateKpi />
          </PrivateRoute>
        }
      />
      <Route
        path="/data/data-sheets/create-regular-data-sheet"
        key="/data/data-sheets/create"
        element={
          <PrivateRoute
            permissionKey={"data-sheets-page"}
            moduleKey={"DATA_SHEETS"}
          >
            <CreateDataSheet isReference={false} />
          </PrivateRoute>
        }
      />
      <Route
        path="/data/data-sheets/create-reference-data-sheet"
        key="/data/data-sheets/create"
        element={
          <PrivateRoute
            permissionKey={"data-sheets-page"}
            moduleKey={"DATA_SHEETS"}
          >
            <CreateDataSheet isReference />
          </PrivateRoute>
        }
      />
      <Route
        path="/data/data-sheets/create-standards-data-sheet"
        key="/data/data-sheets/create"
        element={
          <PrivateRoute
            permissionKey={"data-sheets-page"}
            moduleKey={"DATA_SHEETS"}
          >
            <CreateStandardsDataSheet />
          </PrivateRoute>
        }
      />
      <Route
        path="/data/data-sheets/create-kpi-data-sheet"
        key="/data/data-sheets/create"
        element={
          <PrivateRoute
            permissionKey={"data-sheets-page"}
            moduleKey={"DATA_SHEETS"}
          >
            <CreateKPIDataSheet />
          </PrivateRoute>
        }
      />
      <Route
        path="/data/data-sheets/duplicate"
        key="/data/data-sheets/duplicate"
        element={
          <PrivateRoute
            permissionKey={"data-sheets-page"}
            moduleKey={"DATA_SHEETS"}
          >
            <CreateDataSheet isReference={false} />
          </PrivateRoute>
        }
      />
      <Route
        path="/data/data-sheets/create-audit"
        key="/data/data-sheets/create-audit"
        element={
          <PrivateRoute permissionKey={"manage-audits-action"}>
            <CreateAudit />
          </PrivateRoute>
        }
      />
      <Route
        path="/audit/manage/:audit_id/update-audit"
        key="/audit/manage/:audit_id/update-audit"
        element={
          <PrivateRoute permissionKey={"manage-audits-action"}>
            <CreateAudit />
          </PrivateRoute>
        }
      />
      <Route
        path="/audit/manage/:audit_id/:audit_name/view"
        key="/audit/manage/:audit_id/:audit_name/view"
        element={
          <PrivateRoute permissionKey={"manage-audits"}>
            <ViewAudit />
          </PrivateRoute>
        }
      />

      <Route
        path="/data/import-data"
        key="import-data"
        element={
          <PrivateRoute permissionKey={"data-page"} moduleKey={"DATA_SHEETS"}>
            <ImportDataPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/data/mass-data-import"
        key="import-data"
        element={
          <PrivateRoute permissionKey={"data-page"} moduleKey={"DATA_SHEETS"}>
            <MassImportData />
          </PrivateRoute>
        }
      />
      <Route
        path="/data/import-data/:import_name/review"
        key="import-data"
        element={
          <PrivateRoute permissionKey={"data-page"} moduleKey={"DATA_SHEETS"}>
            <MassImportData />
          </PrivateRoute>
        }
      />
      <Route
        path="/data/import-data/:import_name/update"
        key="import-data"
        element={
          <PrivateRoute permissionKey={"data-page"} moduleKey={"DATA_SHEETS"}>
            <MassImportData />
          </PrivateRoute>
        }
      />
      <Route
        path="/data/import-data/import-new-data"
        key="import-new-data"
        element={
          checkRolesPermission(["role:user", "role:elevateduser"]) ? (
            <ImportNewData />
          ) : (
            <PrivateRoute permissionKey={"data-page"} moduleKey={"DATA_SHEETS"}>
              <ImportNewData />
            </PrivateRoute>
          )
        }
      />
      <Route
        path="/data/connect-utilities"
        key="connect-utilities"
        element={
          <PrivateRoute
            permissionKey={"data-page"}
            moduleKey={"CONNECT_UTILITIES"}
          >
            <ConnectUtilitiesPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/data/connect-api"
        key="connect-api"
        element={
          <PrivateRoute permissionKey={"data-page"} moduleKey={"CONNECT_API"}>
            <MarketplacePage />
          </PrivateRoute>
        }
      />
      <Route
        path="/data/import-data/:import_id/:import_name/update"
        key="/data/import-data/:import_id/:import_name/update"
        element={
          <PrivateRoute permissionKey={"data-page"}>
            <ImportNewData />
          </PrivateRoute>
        }
      />
      <Route
        path="/data/import-data/:import_id/:import_name/review"
        key="/data/import-data/:import_id/:import_name/review"
        element={
          <PrivateRoute permissionKey={"data-page"}>
            <ImportNewData />
          </PrivateRoute>
        }
      />
      <Route
        path="/data/data-sheets/:sheet_id/:sheet_name/update"
        key="/data/data-sheets/:sheet_id/:sheet_name/update"
        element={
          <PrivateRoute permissionKey={"data-sheets-page"}>
            <UpdateDataSheet />
          </PrivateRoute>
        }
      />
      {/* Units Management page */}
      <Route
        path="/data/units-management"
        key="/data/units-management"
        element={
          <PrivateRoute
            permissionKey={"units-management"}
            moduleKey={"UNITS_MANAGEMENT"}
          >
            <UnitsManagement />
          </PrivateRoute>
        }
      />
      <Route
        path="/data/units-management/manage-unit-conversions"
        key="/data/units-management/manage-unit-conversions"
        element={
          <PrivateRoute
            permissionKey={"units-management"}
            moduleKey={"UNITS_MANAGEMENT"}
          >
            <ManageUnitConversions />
          </PrivateRoute>
        }
      />
      <Route
        path="/data/units-management/:id"
        key="/data/units-management/unit"
        element={
          <PrivateRoute
            permissionKey={"units-management"}
            moduleKey={"UNITS_MANAGEMENT"}
          >
            <UnitsTable />
          </PrivateRoute>
        }
      />
      <Route
        path="/data/pending-items"
        key="/data/pending-items"
        element={
          <PrivateRoute permissionKey={"data-page"}>
            <DataSheetDocumentsPage
              isDeleted={false}
              isPending={true}
              isDenied={true}
              isGeneral
            />
          </PrivateRoute>
        }
      />
      <Route
        path="/reporting/standards-overview"
        key="standards-overview"
        element={
          <PrivateRoute permissionKey={"reporting-page"}>
            <ReportingProvider>
              <StandardOverview />
            </ReportingProvider>
          </PrivateRoute>
        }
      />
      <Route
        path="/reporting/standards-progress"
        key="standards-progress"
        element={
          <PrivateRoute
            permissionKey={"reporting-page"}
            moduleKey={"REPORTING_STANDARDS"}
          >
            <ReportingProvider>
              <StandardProgressPage />
            </ReportingProvider>
          </PrivateRoute>
        }
      />

      <Route
        path="/reporting/sustainability-standards"
        key="/reporting/sustainability-standards"
        element={
          <PrivateRoute
            permissionKey={"reporting-page"}
            moduleKey={"REPORTING_STANDARDS"}
          >
            {/* <ReportingProvider>
              <ReportingPageProvider> */}
            {/* <Standard isDeleted={false} /> */}
            {/* </ReportingPageProvider>
            </ReportingProvider> */}
            <SustainabilityStandardContainer />
          </PrivateRoute>
        }
      />
      <Route
        path="/reporting/sustainability-standards/create-audit"
        key="/reporting/sustainability-standards/create-audit"
        element={
          <PrivateRoute permissionKey={"manage-audits-action"}>
            <CreateAudit />
          </PrivateRoute>
        }
      />

      <Route
        path="/reporting/sustainability-standards/deleted-standards"
        key="/reporting/sustainability-standards/deleted-standards"
        element={
          <PrivateRoute
            permissionKey={"reporting-page"}
            moduleKey={"REPORTING_STANDARDS"}
          >
            <ReportingProvider>
              <ReportingPageProvider>
                <Standard isDeleted />
              </ReportingPageProvider>
            </ReportingProvider>
          </PrivateRoute>
        }
      />
      <Route
        path="/reporting/sustainability-standards/generate-report"
        key="/reporting/sustainability-standards/generate-report"
        element={
          <PrivateRoute
            permissionKey={"reporting-page"}
            moduleKey={"REPORTING_STANDARDS"}
          >
            <GenerateReportPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/reporting/sustainability-standards/:standard_id/:standard_name/update"
        key="/reporting/sustainability-standards:standard_id/:standard_name/update"
        element={
          <PrivateRoute permissionKey={"reporting-page"}>
            <UpdateStandard />
          </PrivateRoute>
        }
      />

      <Route
        path="/audit/manage/:audit_id/:standard_id/:standard_name/update"
        key="/audit/manage/:audit_id/:standard_id/:standard_name/update"
        element={
          <PrivateRoute permissionKey={"audit-standards-page"}>
            <UpdateAuditStandard />
          </PrivateRoute>
        }
      />
      <Route
        path="/reporting/data-integrity"
        key="data-integrity"
        element={
          <PrivateRoute permissionKey={"reporting-page"}>
            <ProtectPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/reporting/settings"
        key="settings"
        element={
          <PrivateRoute
            permissionKey={"reporting-page"}
            moduleKey={"REPORTING_STANDARDS"}
          >
            <ReportingProvider isFiltersShow={false}>
              <StandardSettings />
            </ReportingProvider>
          </PrivateRoute>
        }
      />
      {/* /reporting/manage-data-collection */}
      <Route
        path="/reporting/custom-standard/create"
        key="custom-data-builder"
        element={
          <PrivateRoute
            permissionKey={"custom-standard"}
            moduleKey={"REPORTING_STANDARDS"}
          >
            <CustomStandard />
          </PrivateRoute>
        }
      />
      <Route
        path="/reporting/custom-standard/:custom_standard_id/update"
        key="custom-data-builder"
        element={
          <PrivateRoute
            permissionKey={"custom-standard"}
            moduleKey={"REPORTING_STANDARDS"}
          >
            <CustomStandard />
          </PrivateRoute>
        }
      />
      <Route
        path="/reporting/custom-standard"
        key="/reporting/custom-standard"
        element={
          <PrivateRoute
            permissionKey={"esg-custom-standard"}
            moduleKey={"REPORTING_STANDARDS"}
          >
            <EsgStandards />
          </PrivateRoute>
        }
      />
      <Route
        path="/reporting/report-builder"
        key="/reporting/report-builder"
        element={
          <PrivateRoute
            permissionKey={"report-builder"}
            moduleKey={"REPORTING_BUILDER"}
          >
            <ReportBuilder />
          </PrivateRoute>
        }
      />
      <Route
        path="/reporting/create-report"
        key="/reporting/create-report"
        element={
          <PrivateRoute
            permissionKey={"saved-reports"}
            moduleKey={"REPORTING_BUILDER"}
          >
            <CreateReport />
          </PrivateRoute>
        }
      />
      <Route
        path="/reporting/create-report/report-template"
        key="/reporting/create-report/report-template"
        element={
          <PrivateRoute
            permissionKey={"saved-reports"}
            moduleKey={"REPORTING_BUILDER"}
          >
            <ReportTemplatePage />
          </PrivateRoute>
        }
      />
      <Route
        path="/reporting/saved-reports"
        key="/reporting/saved-reports"
        element={
          <PrivateRoute
            permissionKey={"saved-reports"}
            moduleKey={"REPORTING_BUILDER"}
          >
            <ReportViewer />
          </PrivateRoute>
        }
      />
      <Route
        path="/reporting/saved-reports/:report_id/:report_name/view"
        key="/reporting/saved-reports/:report_id/:report_name/view"
        element={
          <PrivateRoute
            permissionKey={"saved-reports"}
            moduleKey={"REPORTING_BUILDER"}
          >
            <ReportViewerDetail />
          </PrivateRoute>
        }
      />
      <Route
        path="/reporting/saved-reports/:report_id/:report_name/update"
        key="/reporting/saved-reports/:report_id/:report_name/update"
        element={
          <PrivateRoute
            permissionKey={"saved-reports"}
            moduleKey={"REPORTING_BUILDER"}
          >
            <ReportViewerDetail />
          </PrivateRoute>
        }
      />
      <Route key="analytics" path="/analytics" element={<Analytics />} />
      <Route
        path="/analytics/:id"
        key="dashboard-charts"
        element={
          <PrivateRoute permissionKey={"analytics-charts-page"}>
            <DashboardChartsPage />
          </PrivateRoute>
        }
      />
      <Route
        key="carbon"
        path="/carbon"
        element={
          <PrivateRoute permissionKey={"carbon-page-elevated"}>
            <Carbon />
          </PrivateRoute>
        }
      />
      <Route
        key="carbon-audit"
        path="/carbon/create-audit"
        element={
          <PrivateRoute permissionKey={"manage-audits-action"}>
            <CreateAudit />
          </PrivateRoute>
        }
      />
      <Route
        path="/carbon/overview"
        key="carbon-overview"
        element={
          <PrivateRoute permissionKey={"carbon-page-elevated"}>
            <ContentSection>
              <OverviewWrapper />
            </ContentSection>
          </PrivateRoute>
        }
      />
      <Route
        path="/carbon/calculations"
        key="carbon-calculations"
        element={
          <PrivateRoute
            permissionKey={"carbon-page-elevated"}
            moduleKey={"CARBON_EMISSIONS_CALCULATOR"}
          >
            <ContentSection style={{ color: "rgba(0, 0, 0, 0.85)" }}>
              <Calculations />
            </ContentSection>
          </PrivateRoute>
        }
      />
      <Route
        path="/carbon/calculations/:id"
        key="carbon-calculator"
        element={
          <PrivateRoute permissionKey={"carbon-page-elevated"}>
            <ContentSection
              style={{ color: "rgba(0, 0, 0, 0.85)", padding: "0px" }}
            >
              <Calculator isDeleted={false} />
            </ContentSection>
          </PrivateRoute>
        }
      />
      <Route
        path="/carbon/calculations/:id/update-records"
        key="calculation-update-records"
        element={
          <PrivateRoute permissionKey={"carbon-page"}>
            <ContentSection
              style={{ color: "rgba(0, 0, 0, 0.85)", padding: "0px" }}
            >
              <UpdateRecords />
            </ContentSection>
          </PrivateRoute>
        }
      />
      <Route
        path="/carbon/calculations/:id/deleted-items"
        key="calculation-deleted-items"
        element={
          <PrivateRoute permissionKey={"carbon-page-elevated"}>
            <ContentSection
              style={{ color: "rgba(0, 0, 0, 0.85)", padding: "0px" }}
            >
              <Calculator isDeleted={true} />
            </ContentSection>
          </PrivateRoute>
        }
      />
      <Route
        path="/carbon/new-calculation"
        key="new-calculation"
        element={
          <PrivateRoute permissionKey={"carbon-page"}>
            <CalculationStepper />
          </PrivateRoute>
        }
      />

      <Route
        path="/carbon/reduction-plan"
        key="reduction-plan"
        element={
          <PrivateRoute
            permissionKey={"carbon-page"}
            moduleKey={"CARBON_REDUCTION_CALCULATOR"}
          >
            <ContentSection
              style={{ paddingTop: 0, paddingRight: 0, paddingLeft: "70px" }}
            >
              <ReductionPlan />
            </ContentSection>
          </PrivateRoute>
        }
      />
      <Route
        path="/carbon/reduction-plan/:id"
        key="reduction-target"
        element={
          <PrivateRoute permissionKey={"carbon-page"}>
            <ContentSection style={{ padding: "0px" }}>
              <SingleTarget />
            </ContentSection>
          </PrivateRoute>
        }
      />
      <Route
        path="/carbon/reduction-plan/onboarding-flow"
        key="onboarding-flow"
        element={
          <PrivateRoute
            permissionKey={"carbon-page"}
            moduleKey={"CARBON_REDUCTION_CALCULATOR"}
          >
            <ContentSection
              style={{ paddingTop: 0, paddingRight: 0, paddingLeft: 0 }}
            >
              <WhatIfOnboarding />
            </ContentSection>
          </PrivateRoute>
        }
      />
      <Route
        path="/carbon/carbon-offsets-orders"
        key="carbon-offsets-orders"
        element={
          <PrivateRoute
            permissionKey={"carbon-page"}
            moduleKey={"CARBON_OFFSETS_MARKETPLACE"}
          >
            <CarbonOffsetOrders />
          </PrivateRoute>
        }
      />
      <Route
        path="/carbon/carbon-offsets"
        key="carbon-offsets"
        element={
          <PrivateRoute
            permissionKey={"carbon-page"}
            moduleKey={"CARBON_OFFSETS_MARKETPLACE"}
          >
            <CarbonOffsetsProducts />
          </PrivateRoute>
        }
      />
      <Route
        path="/carbon/custom-factors"
        key="carbon-custom-factors"
        element={
          <PrivateRoute
            permissionKey={"carbon-page"}
            moduleKey={"CARBON_CUSTOM_FACTOR"}
          >
            <ContentSection style={{ color: "rgba(0, 0, 0, 0.85)" }}>
              <CustomFactors />
            </ContentSection>
          </PrivateRoute>
        }
      />
      <Route
        path="/carbon/emission-library"
        key="carbon-emission-library"
        element={
          <PrivateRoute permissionKey={"carbon-page"}>
            <ContentSection style={{ color: "rgba(0, 0, 0, 0.85)" }}>
              <EmissionLibraryPage />
            </ContentSection>
          </PrivateRoute>
        }
      />

      <Route key="audit" path="/audit" element={<Audit />} />
      <Route
        path="/audit/audit-standards"
        key="audit-standards"
        element={
          <PrivateRoute
            permissionKey={"audit-standards-page"}
            moduleKey={"AUDIT_STANDARDS"}
          >
            <AuditStandardsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/audit/audit-standards/:audit_id/:audit_name/detail"
        key="audit-standards"
        element={
          <PrivateRoute
            permissionKey={"audit-standards-page"}
            moduleKey={"AUDIT_STANDARDS"}
          >
            <ViewAudit />
          </PrivateRoute>
        }
      />
      <Route
        path="/audit/audit-standards/:audit_id/:audit_name/:standard_id/detail"
        key="audit-standards"
        element={
          <PrivateRoute
            permissionKey={"audit-standards-page"}
            moduleKey={"AUDIT_STANDARDS"}
          >
            <UpdateAuditStandard />
          </PrivateRoute>
        }
      />

      <Route
        path="/audit/calculations-audit"
        key="audit-calculations-audit"
        element={
          <PrivateRoute
            permissionKey={"audit-page"}
            moduleKey={"AUDIT_EMISSION"}
          >
            <ContentSection style={{ color: "rgba(0, 0, 0, 0.85)" }}>
              <CalculationsAudit />
            </ContentSection>
          </PrivateRoute>
        }
      />
      <Route
        path="/audit/calculations-audit/:name"
        key="audit-calculation-audit"
        element={
          <PrivateRoute permissionKey={"audit-page"}>
            <ContentSection
              style={{ color: "rgba(0, 0, 0, 0.85)", padding: "0px" }}
            >
              <CalculatorAudit />
            </ContentSection>
          </PrivateRoute>
        }
      />
      <Route
        path="/audit/manage"
        key="audit-manage"
        element={
          <PrivateRoute
            permissionKey={"manage-audits"}
            moduleKey={"AUDIT_STANDARDS"}
          >
            <ManageAudits isDeleted={false} />
          </PrivateRoute>
        }
      />
      <Route
        path="/audit/manage/deleted"
        key="audit-manage"
        element={
          <PrivateRoute
            permissionKey={"manage-audits-action"}
            moduleKey={"AUDIT_STANDARDS"}
          >
            <ManageAudits isDeleted />
          </PrivateRoute>
        }
      />
      <Route
        path="/tools/ai"
        key="ai-manage"
        element={
          <PrivateRoute permissionKey={"tools-page"}>
            <Ai />
          </PrivateRoute>
        }
      />
      <Route
        key="tools-page"
        path="/tools"
        element={
          <PrivateRoute permissionKey={"tools-page"}>
            <ToolsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/tools/improvement-recommedations"
        key="/tools/improvement-recommedations"
        element={
          <PrivateRoute permissionKey={"tools-page"}>
            <ImprovementRecommendations />
          </PrivateRoute>
        }
      />

      <Route
        path="/data/data-sheets/:data_sheet_id/:sheet_name/documents"
        key="/data/data-sheets/:data_sheet_id/:sheet_name/documents"
        element={
          <PrivateRoute permissionKey={"data-sheets-page"}>
            <DataSheetDocumentsPage
              isDeleted={false}
              isPending={false}
              isDenied={false}
              isGeneral={false}
            />
          </PrivateRoute>
        }
      />
      <Route
        path="/data/data-sheets/:data_sheet_id/:sheet_name/documents/generate-report"
        key="/data/data-sheets/:data_sheet_id/:sheet_name/documents/generate-report"
        element={
          <PrivateRoute permissionKey={"data-sheets-page"}>
            <GenerateReportPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/data/data-sheets/:data_sheet_id/:sheet_name/documents/deleted"
        key="/data/data-sheets/:data_sheet_id/:sheet_name/documents/deleted"
        element={
          <PrivateRoute permissionKey={"data-sheets-page"}>
            <DataSheetDocumentsPage
              isDeleted
              isPending={false}
              isDenied={false}
              isGeneral={false}
            />
          </PrivateRoute>
        }
      />
      <Route
        path="/data/data-sheets/:data_sheet_id/:sheet_name/documents/update-records"
        key="/data/data-sheets/:data_sheet_id/:sheet_name/documents/update-records"
        element={
          <PrivateRoute permissionKey={"data-sheets-page"}>
            <UpdateDataSheetsRecords />
          </PrivateRoute>
        }
      />
      <Route
        path="/data/data-sheets/:data_sheet_id/:sheet_name/documents/pending"
        key="/data/data-sheets/:data_sheet_id/:sheet_name/documents/pending"
        element={
          <PrivateRoute permissionKey={"data-sheets-page"}>
            <DataSheetDocumentsPage
              isDeleted={false}
              isPending={true}
              isDenied={false}
              isGeneral={false}
            />
          </PrivateRoute>
        }
      />
      <Route
        path="/data/data-sheets/:data_sheet_id/:sheet_name/documents/denied"
        key="/data/data-sheets/:data_sheet_id/:sheet_name/documents/denied"
        element={
          <PrivateRoute permissionKey={"data-sheets-page"}>
            <DataSheetDocumentsPage
              isDeleted={false}
              isPending={false}
              isDenied
              isGeneral={false}
            />
          </PrivateRoute>
        }
      />
      <Route
        path="/data/data-sheets/:data_sheet_id/:sheet_name/documents/data-quality-score-setup"
        key="/data/data-sheets/:data_sheet_id/:sheet_name/documents/data-quality-score-setup"
        element={
          <PrivateRoute permissionKey={"data-sheets-page"}>
            <SetupDataQualityScore />
          </PrivateRoute>
        }
      />
      <Route
        key="/carbon/carbon-offsets/:id/details"
        path="/carbon/carbon-offsets/:id/details"
        element={<ProductDetailsPage />}
      />
      <Route
        key="/carbon/carbon-offsets/:id/new-order"
        path="/carbon/carbon-offsets/:id/new-order"
        element={<ProductOrderPage />}
      />

      <Route
        path="/management/user-account"
        key="/management/user-account"
        element={
          <PrivateRoute permissionKey={"settings-page"}>
            <UserAccountPageNew />
          </PrivateRoute>
        }
      />

      <Route
        path="/management/user-management"
        key="/management/user-management"
        element={
          <PrivateRoute permissionKey={"settings-staff-page"}>
            <UserManagementPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/management/releaseNotes"
        key="/management/releaseNotes"
        element={
          <PrivateRoute permissionKey={"management-page/releaseNotes-page"}>
            <ReleaseNotesPageContainer />
          </PrivateRoute>
        }
      />
      <Route
        path="/management/notifications"
        key="/management/notifications"
        element={
          <PrivateRoute permissionKey={"management-page/notifications-page"}>
            <NotificationsPageContainer />
          </PrivateRoute>
        }
      />
      <Route
        path="/management/user-groups"
        key="/management/user-groups"
        element={
          <PrivateRoute permissionKey={"settings-staff-page"}>
            <UserGroupsPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/audit/audit-data-sheets"
        key="/audit/audit-data-sheets"
        element={
          <PrivateRoute
            permissionKey={"audit-data-sheets"}
            moduleKey={"AUDIT_DATA_SHEETS"}
          >
            <AuditDataSheetsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/audit/audit-data-sheets/:audit_id/:sheet_name/documents"
        key="/audit/audit-data-sheets/:audit_id/:sheet_name/documents"
        element={
          <PrivateRoute permissionKey={"audit-data-sheets"}>
            <ViewAudit />
          </PrivateRoute>
        }
      />
      <Route
        path="/management/collect-data/create-standards-survey"
        key="/management/collect-data/create-standards-survey"
        element={
          <PrivateRoute permissionKey={"reporting-page"}>
            <CreateStandardsSurvey />
          </PrivateRoute>
        }
      />
      <Route
        path="/management/collect-data/:survey_id/update-standards-survey"
        key="/management/collect-data/:survey_id/update-standards-survey"
        element={
          <PrivateRoute permissionKey={"audit-data-sheets"}>
            <CreateStandardsSurvey />
          </PrivateRoute>
        }
      />
      <Route
        path="/management/collect-data/create-datasheet-survey"
        key="/management/collect-data/create-datasheet-survey"
        element={
          <PrivateRoute permissionKey={"reporting-page"}>
            <CreateDataSheetSurvey />
          </PrivateRoute>
        }
      />
      <Route
        path="/management/collect-data/:survey_id/update-datasheet-survey"
        key="/management/collect-data/:survey_id/update-datasheet-survey"
        element={
          <PrivateRoute permissionKey={"audit-data-sheets"}>
            <CreateDataSheetSurvey />
          </PrivateRoute>
        }
      />
      <Route
        path="/management/collect-data/create-mixed-survey"
        key="/management/collect-data/create-mixed-survey"
        element={
          <PrivateRoute permissionKey={"reporting-page"}>
            <CreateMixedSurvey />
          </PrivateRoute>
        }
      />
      <Route
        path="/management/collect-data/create-kpi-survey"
        key="/management/collect-data/create-kpi-survey"
        element={
          <PrivateRoute permissionKey={"reporting-page"}>
            <CreateKPIsSurvey />
          </PrivateRoute>
        }
      />
      <Route
        path="/management/collect-data/:survey_id/update-mixed-survey"
        key="/management/collect-data/:survey_id/update-mixed-survey"
        element={
          <PrivateRoute permissionKey={"audit-data-sheets"}>
            <CreateMixedSurvey />
          </PrivateRoute>
        }
      />
      <Route
        path="/management/collect-data/:survey_id/update-kpi-survey"
        key="/management/collect-data/:survey_id/update-kpi-survey"
        element={
          <PrivateRoute permissionKey={"audit-data-sheets"}>
            <CreateKPIsSurvey />
          </PrivateRoute>
        }
      />
      <Route
        path="/management/collect-data/:survey_id/:survey_name/view-survey"
        key="/management/collect-data/:survey_id/:survey_name/view-survey"
        element={
          <PrivateRoute permissionKey={"audit-data-sheets"}>
            <ViewSurveyForAdmin />
          </PrivateRoute>
        }
      />
      <Route
        path="/analytics/manage-dashboards"
        key="/analytics/manage-dashboards"
        element={
          <PrivateRoute
            permissionKey={"manage-dashboards"}
            moduleKey={"ANALYTICS_DASHBOARDS"}
          >
            <AnalyticsManageDashboards />
          </PrivateRoute>
        }
      />
      {/* <Route
        path="/ratings"
        key="/ratings"
        element={
          <PrivateRoute permissionKey={"ratings-page"}>
            <Ratings />
          </PrivateRoute>
        }
      /> */}
      <Route
        path="/tools/esg-handbook"
        key="/tools/esg-handbook"
        element={
          <PrivateRoute permissionKey={"tools-page"}>
            <EsgHandbookPage />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

ReactDOM.render(
  <Authenticator.Provider>
    <Router>
      <App>
        <AllRoutes />
      </App>
    </Router>
  </Authenticator.Provider>,
  document.getElementById("root")
); // If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();
