import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Checkbox,
  Col,
  Drawer,
  Input,
  Radio,
  Row,
  Spin,
} from "antd";
import {
  CommentOutlined,
  DeleteOutlined,
  EditOutlined,
  FileTextOutlined,
  UserOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Auth } from "aws-amplify";
import moment from "moment";
import { FormWrapper, StyledButton } from "../../../../../shared/commonStyles";
import { fetchProfileImage } from "../../../../../shared/commonFunctions";
import styles from "./NotesDrawer.module.scss";
import { v4 as uuidv4 } from "uuid";
import { useCheckRolesPermissions } from "../../../../../hooks/useCheckRolesPermissions";

interface NotesDrawerProps {
  visible: boolean;
  data: any;
  notes: Note[];
  handleMarkAsEstimatedChange?: () => void;
  loading: boolean;
  onClose: () => void;
  onSubmit: (
    note: string,
    noteType: "comment" | "warning" | "estimate"
  ) => void; // Updated function signature
  onUpdateNotes: (notes: Note[]) => void;
  isFieldNote?: boolean;
  isViewOnly?: boolean;
  incomingCurrentTab?: "comment" | "warning" | "estimate";
}

export const NotesDrawer = ({
  visible,
  data,
  notes,
  handleMarkAsEstimatedChange,
  onClose,
  loading,
  onUpdateNotes,
  onSubmit,
  isFieldNote,
  isViewOnly,
  incomingCurrentTab,
}: NotesDrawerProps) => {
  const [note, setNote] = useState("");
  const [userInfo, setUserInfo] = useState<any>(null);
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const [savedNotes, setSavedNotes] = useState<Note[]>();
  const [currentEditableNote, setCurrentEditableNote] = useState<Note>(
    {} as Note
  );
  const { checkRolesPermission } = useCheckRolesPermissions();
  const [currentTab, setCurrentTab] = useState<
    "comment" | "warning" | "estimate"
  >(incomingCurrentTab || "comment");
  const [markAsEstimated, setMarkAsEstimated] = useState(false);

  useEffect(() => {
    if (userInfo) {
      getProfileImage(userInfo?.sub);
    }
  }, [userInfo]);

  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
    setCurrentTab(incomingCurrentTab || "comment");
  }, [incomingCurrentTab]);

  useEffect(() => {
    const notesWithKeys =
      notes && notes.map((item) => ({ ...item, key: item?.key || uuidv4() }));

    checkRolesPermission(["role:admin"])
      ? setSavedNotes(notesWithKeys)
      : setSavedNotes(notesWithKeys?.filter((v) => !v.isDeleted));

    let markAsEstimatedRecord: Note[] =
      notes?.filter((item) => item.noteType === "estimatedBoolean") || [];

    if (markAsEstimatedRecord.length) {
      setMarkAsEstimated(markAsEstimatedRecord[0].markedAsEstimated || false);
    } else setMarkAsEstimated(false);
  }, [notes]);

  const getUserInfo = () => {
    Auth.currentUserInfo()
      .then((userInfo) => {
        setUserInfo(userInfo["attributes"]);
      })
      .catch((err) => console.error(err));
  };

  const getProfileImage = async (username: string) => {
    try {
      const profile = await fetchProfileImage(username);
      setProfileImage(profile);
    } catch (error) {
      console.error("Error while load header logo", error);
    }
  };

  const firstValue = !!data[0]?.["$date"]
    ? moment.utc(data[0]?.["$date"]).format("YYYY-MM-DD")
    : data[0];

  const secondValue = !!data[1]?.["$date"]
    ? moment.utc(data[1]?.["$date"]).format("YYYY-MM-DD")
    : data[1];

  const handleSubmit = () => {
    note.length > 0 ? onSubmit(note, currentTab) : onClose();
    setNote("");
  };
  const handleClose = () => {
    setNote("");
    onClose();
  };
  const handleEditNote = (v: Note) => {
    if (savedNotes) {
      const newNotes = savedNotes.map((note) => {
        if (note.key == v.key) {
          return {
            ...note,
            text: currentEditableNote.text,
          };
        } else {
          return note;
        }
      });
      onUpdateNotes(newNotes);
    }
    setCurrentEditableNote({} as Note);
  };
  const handleDeleteNote = (v: Note) => {
    if (savedNotes) {
      const newNotes = savedNotes.map((note) => {
        if (note.key == v.key) {
          return {
            ...note,
            isDeleted: true,
          };
        } else {
          return note;
        }
      });

      onUpdateNotes(newNotes);
    }
    setCurrentEditableNote({} as Note);
  };

  return (
    <Drawer
      width={400}
      className={styles.drawer}
      placement="right"
      visible={visible}
      onClose={handleClose}
      title={firstValue}
    >
      <FormWrapper>
        <Spin spinning={loading} style={styles.spin}>
          <div className={styles.drawerContent}>
            {isFieldNote && (
              <Radio.Group
                value={currentTab}
                onChange={(e) => {
                  setCurrentTab(e.target.value);
                }}
                className={styles.radio}
              >
                <Radio.Button value={"estimate"}>
                  <span>
                    <FileTextOutlined /> Estimated
                  </span>
                </Radio.Button>
                <Radio.Button value={"comment"}>
                  <span>
                    <CommentOutlined /> Comment
                  </span>
                </Radio.Button>
                <Radio.Button value={"warning"}>
                  <span className={"warning"}>
                    <WarningOutlined className={styles.iconOrange} />
                    Warning
                  </span>
                </Radio.Button>
              </Radio.Group>
            )}

            {currentTab === "estimate" && (
              <div className={styles.markAsEstimatedContainer}>
                <Checkbox
                  checked={markAsEstimated}
                  onClick={() => {
                    if (handleMarkAsEstimatedChange)
                      handleMarkAsEstimatedChange();

                    setMarkAsEstimated(!markAsEstimated);
                  }}
                >
                  Mark as estimated
                </Checkbox>
              </div>
            )}

            <div className={styles.drawerList}>
              {savedNotes
                ?.filter((note) => !isFieldNote || note.noteType === currentTab)
                .sort(
                  (a, b) =>
                    new Date(b.date).getTime() - new Date(a.date).getTime()
                )
                .map((note) => (
                  <div className={styles.drawerListItem} key={note.key}>
                    <div className={styles.commentContainer}>
                      <div
                        className={styles.left}
                        style={{ paddingTop: note.isDeleted ? 0 : "7px" }}
                      >
                        <Avatar
                          src={profileImage}
                          icon={<UserOutlined style={{ color: "#030303" }} />}
                        />
                      </div>

                      <div className={styles.right}>
                        <div className={styles["name-buttons"]}>
                          <div className={styles.name}>
                            <b>{note.user}</b>
                          </div>

                          <div className={styles.buttons}>
                            {userInfo?.name === note?.user &&
                              !note.isDeleted &&
                              !isViewOnly && (
                                <Col span={8} className={styles.btnIconsWrap}>
                                  <Button
                                    className={styles.btnIcon}
                                    onClick={() => {
                                      handleDeleteNote(note);
                                    }}
                                  >
                                    <DeleteOutlined />
                                  </Button>
                                  <Button
                                    className={styles.btnIcon}
                                    onClick={() => setCurrentEditableNote(note)}
                                  >
                                    <EditOutlined />
                                  </Button>
                                </Col>
                              )}
                          </div>
                        </div>

                        <div
                          className={`${styles.comment} ${
                            note.isDeleted ? styles.strikethrough : ""
                          }`}
                        >
                          {note.text}
                          {note.isDeleted && "deleted note"}
                        </div>
                        <div className={styles.date}>
                          <p>{note.date?.slice(0, 11)}</p>
                        </div>

                        {currentEditableNote?.key === note.key && (
                          <div className={styles.editInputContainer}>
                            <Input.TextArea
                              placeholder="Enter notes"
                              value={currentEditableNote.text}
                              onChange={(e) =>
                                setCurrentEditableNote((prev) => ({
                                  ...prev,
                                  text: e.target.value,
                                }))
                              }
                            />

                            <div className={styles.buttons}>
                              <StyledButton
                                onClick={() => handleEditNote(note)}
                                className={styles.saveButton}
                              >
                                Save
                              </StyledButton>

                              <StyledButton
                                onClick={() =>
                                  setCurrentEditableNote({} as Note)
                                }
                                className={styles.cancelButton}
                              >
                                Cancel
                              </StyledButton>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}

              {/* {!isFieldNote && !isViewOnly && ( */}
            </div>

            {!isViewOnly && (
              <div className={styles.drawerListItemWrapper}>
                <Input.TextArea
                  showCount
                  value={note}
                  maxLength={100}
                  onChange={(e) => setNote(e.target.value)}
                  placeholder={`Add ${
                    currentTab === "estimate" ? "note" : currentTab
                  }`}
                  className={styles.drawerListItemTextArea}
                />
              </div>
            )}
            <div className={styles.drawerFooter}>
              {!isViewOnly && (
                <StyledButton
                  onClick={handleSubmit}
                  disabled={currentEditableNote?.key?.length}
                  className={styles.saveButton}
                >
                  {`Add ${currentTab === "estimate" ? "note" : currentTab}`}{" "}
                </StyledButton>
              )}

              <StyledButton
                onClick={handleClose}
                className={styles.cancelButton}
              >
                Close
              </StyledButton>
            </div>
          </div>
        </Spin>
      </FormWrapper>
    </Drawer>
  );
};
