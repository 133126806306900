import React from "react";
import { useNavigate } from "react-router-dom";
import { CheckCircleOutlined } from "@ant-design/icons";
import { encodeUrlName } from "../../../shared/commonFunctions";
import "./Success.scss"; // Import the SCSS file

interface SuccessProps {
  sheetId: string;
  sheetName: string;
}

const Success = ({ sheetId, sheetName }: SuccessProps) => {
  const navigate = useNavigate();
  return (
    <div className="success-container">
      <div className="success-content">
        <CheckCircleOutlined className="success-icon" />
        <h4 className="success-title">Data Sheet was created successfully!</h4>
        <p>
          Data Sheet was created successfully! It is already available in the
          Data Sheets section.
        </p>
        <div className="success-buttons">
          <button
            className="success-button"
            onClick={() =>
              navigate(
                `/data/data-sheets/${sheetId}/${encodeUrlName(
                  sheetName.replace("/", " ")
                )}/documents`
              )
            }
          >
            Open Data Sheet
          </button>
          <button
            className="success-button"
            onClick={() => navigate("/data/data-sheets")}
          >
            Go to All Data Sheets
          </button>
        </div>
      </div>
    </div>
  );
};

export default Success;
